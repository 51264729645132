
.breadcrumbs {
    display: flex;
    width: 95%;
    max-width: 1820px;
    height: 30px;
    align-items: center;
    margin: 5px auto;
    @include fs-sml-thin-text;
    gap: 2px;
}

.breadcrumb {
    &:not(.current)::after {
        content: '/';
        display: inline-block;
        margin-left: 2px;
    }

    &.current {
        font-weight: $regular;
    }
}