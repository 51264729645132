#copyright {
    margin-top: clamp(20px, fs-vw(60, 1920), 60px);
    border-top: 1px solid $primaryColor;
}

// Exceptions for the footer marge top

.body_login:not(:has(.dynasearchwrapper)),
.satellite_store_details,
.category {

    #copyright {
        margin-top: 0;
    }
}

.reinsurance {
    display: flex;
    justify-content: space-between;
    width: 98%;
    max-width: 1016px;
    padding: 40px 0;
    margin: 0 auto;

    .bgSprite {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        height: 100%;
        width: fit-content;
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        color: $primaryColor;

        span {
            font-size: 13px;
            font-weight: 100;
            line-height: normal;
        }

        &.return::before,
        &.sav::before {
            width: 32px;
            height: 27px;
        }

        &.distributors::before {
            width: 40px;
            height: 27px;
        }

        &.securedPayment::before {
            width: 27px;
            height: 27px;
        }
    }
}

.footer_container {
    display: flex;
    justify-content: center;
    height: 100%;
    background-color: $tertiaryColor;
    border-top: 1px solid $primaryColor;

    .wrapperNewsletter {
        position: relative;
        width: fs-vw(650, 1920);
        min-width: 360px;
        border-left: 1px solid $primaryColor;
        padding: 50px 0 fs-vw(84, 1920) fs-vw(120, 1920);

        @media screen and(min-width: 1921px) {
            // set fixed width variable for large screen responsivness footer purpose
            width: 650px;
        }

        &:has(.w-input-valid) {
            .w-btn-wrapper {
                opacity: 0;
            }
        }

        .title {
            display: flex;
            flex-direction: column;
            @include fs-std-thin-subtitle;

            span {
                @include fs-big-bold-title;
                text-transform: uppercase;
                margin: -6px 0 11px;
            }
        }

        .wrapperSocials {
            display: flex;
            justify-content: space-between;
            width: 100%;
            max-width: 110px;
        }

        .legalMentions {
            position: absolute;
            bottom: 15px;
            display: flex;
            align-items: center;
            height: 25px;
            @include fs-std-thin-subtitle;

        }

        .footer_logo {
            display: flex;
            align-items: center;
            margin-left: 4px;
        }

        .w-input-valid {
            @include fs-std-medium-text;
            top: 140%;
            left: unset;
            margin: 0;
            right: 0;
            text-align: end;
            background-color: $tertiaryColor;
            z-index: 2;
        }
    }

    .wrapperSEO {
        justify-content: flex-end;
        width: fs-vw(1150, 1920);
        padding: 50px fs-vw(79, 1920) 45px fs-vw(80, 1920);
        box-sizing: border-box;

        @media screen and(min-width: 1921px) {
            // set fixed width variable for large screen responsivness footer purpose
            width: 1150px;
        }

        section {
            display: flex;
            max-width: 751px;
            margin-left: auto;
        }

        .satellites {
            display: flex;
            width: fit-content;
            @include fs-std-thin-subtitle;

            .navlist {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                height: fit-content;

                .title {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 34px;
                    @include fs-std-bold-title;
                }

                li {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 25px;

                    a {
                        width: fit-content;
                    }
                }
            }

            li:hover {
                font-weight: $bold;
            }
        }
    }
}

// specific for tablet and under 1024px

@media screen and (max-width: 1024px) {
    .footer_container .wrapperSEO {
        padding: 50px 2.5% 45px;
        
        .satellites {
            flex-wrap: wrap;
            justify-content: space-between;
            .navlist {
                width: 45%;
    
                li {
                    height: fit-content;
                    margin-bottom: 5px;
                }
            } 
        } 
    }
}