/***** GLOBAL FORMS STYLE *****/
//general form style + app override

.w-btn-wrapper {
    height: 40px;
}

.w-input.w-dropdown .w-value {
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
    display: flex;
    align-items: center;
    height: 100%;
    border: none;
    @include fs-std-thin-label;
    color: $primaryColor;
    padding: 0;
    outline: none;
}

select,
.w-dropdown {
    &::after {
        border: none;
        width: 12px;
        height: 12px;
        @include maskIconPath('../svg/spritesheet.svg');
        @include maskIconCoord(1, $initialColSize, $initialRowSize);
        background-color: $primaryColor;
    }

    &.w-has-error {
        border: 1px solid #ff6d6d;
    }

    &.w-has-error,
    &.w-focused {
        box-shadow: none;
    }
}

select,
select.w-input-element:not([type="radio"]):not([type="checkbox"]) {
    background-color: $secondaryColor;
    border-radius: 0;
    box-shadow: none;
}

#iconErrclientTel {
    display: none;
}

.w-input.w-tel-input {

    .w-input-element,
    .w-input-label {
        left: 56px;
    }

    .selected-flag {
        justify-content: space-between;
        width: 41px;
        padding: 0;
        background-color: $secondaryColor;
        border: none;

        &::before {
            width: 24px;
            height: 16px;
        }

        &::after {
            border: none;
            width: 8px;
            height: 8px;
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(25, $initialColSize, $initialRowSize);
            background-color: $greyLabel;
        }
    }
}

#clientTel {
    width: 100%;
    padding-left: 56px;
    margin-top: 1px;
    box-sizing: border-box;
}

.w-input.w-tel-input .w-input-element:not([type="radio"]):not([type="checkbox"]),
.w-tel-input.w-nonempty:not(.no-label) .w-input-element:not([type="radio"]):not([type="checkbox"]) {
    padding-left: 56px;
    width: calc(100% - 56px);
}

select,
.w-input-element:not([type="radio"]):not([type="checkbox"]),
input[type="email"].w-input-element,
input[type="password"].w-input-element,
input[type="text"].w-input-element,
.w-input.w-dropdown,
textarea.w-input-element {
    height: 30px;
    padding: 0;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid $greyLabel;
    outline: none;

    &.inputErr {
        border-color: $redError;
    }
}

.w-date-input .w-input-element:not([type="radio"]):not([type="checkbox"]),
.w-date-input input[type="email"].w-input-element,
.w-date-input input[type="password"].w-input-element,
.w-date-input input[type="text"].w-input-element,
.w-date-input textarea.w-input-element,
.w-nonempty:not(.no-label) .w-input-element:not([type="radio"]):not([type="checkbox"]),
.w-nonempty:not(.no-label) input[type="email"].w-input-element,
.w-nonempty:not(.no-label) input[type="password"].w-input-element,
.w-nonempty:not(.no-label) input[type="text"].w-input-element,
.w-nonempty:not(.no-label) textarea.w-input-element {
    @include fs-std-thin-label;
    color: $primaryColor;
    padding: 0;
}

.w-date-input .w-input-label,
.w-nonempty .w-input-label {
    transform: translateY(-130%);
}

.w-input-label {
    @include fs-std-regular-subtitle;
    color: $greyLabel;
    left: 0;
}

.w-input:not(.w-dropdown) .w-input-element:not([type=radio]):not([type=checkbox]):focus,
.w-input:not(.w-dropdown) input[type=email].w-input-element:focus,
.w-input:not(.w-dropdown) input[type=password].w-input-element:focus,
.w-input:not(.w-dropdown) input[type=text].w-input-element:focus,
.w-input:not(.w-dropdown) textarea.w-input-element:focus {
    box-shadow: unset;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid $greyLabel;
}

.w-has-error:not(.w-dropdown) .w-input-element:not([type="radio"]):not([type="checkbox"]),
.w-has-error:not(.w-dropdown) input[type="email"].w-input-element,
.w-has-error:not(.w-dropdown) input[type="password"].w-input-element,
.w-has-error:not(.w-dropdown) input[type="text"].w-input-element,
.w-has-error:not(.w-dropdown) textarea.w-input-element {
    box-shadow: unset;
}

.w-form-line.w-submit,
.w-contact-form .file-input-row .w-newsletter-form .w-form-line.w-submit label,
.w-newsletter-form .w-form-line.w-submit,
.w-newsletter-form .w-form-line.w-submit .w-contact-form .file-input-row label {
    width: 217px;
    height: 40px;
}

.w-newsletter-form .w-input-element:not([type="radio"]):not([type="checkbox"]),
.w-newsletter-form input[type="email"].w-input-element {
    padding: 0;
    border-radius: 0;
}

.w-newsletter-form {
    width: 330px;

    .w-btn-wrapper {
        height: fit-content;
        width: fit-content;

        .w-input-element:not([type="radio"]):not([type="checkbox"]),
        input[type="email"].w-input-element {
            padding: 0;
            border-radius: 0;
        }
    }

    .w-form-line {
        margin-bottom: 46px;
    }

    .w-form-line.w-submit .w-submit-button {
        width: fit-content;
        height: fit-content;
        color: $primaryColor;
        background-color: unset;
        border: unset;
        text-transform: unset;

        @include secondaryButton(right, 4);
    }
}

.w-newsletter-form .w-form-line.w-submit label,
.w-newsletter-form .w-form-line.w-submit {
    width: fit-content;
    height: fit-content;

}

.w-newsletter-form .w-form-line.w-submit {
    top: calc(100% + 16px);
}

.elem_global,
.w-form-line,
.w-input-group {
    margin-bottom: 20px;
}

.cart {

    .elem_global,
    .w-form-line,
    .w-input-group {
        margin-bottom: 13px;
    }

    .w-input-note {
        top: 80%;
    }
}

.w-form-line.notice {
    font-family: $primaryFont;
    font-weight: 400;
    font-size: .75rem;
    line-height: 1.5;
    color: $primaryColor;
    margin: .33333em 0 .66667em;
    padding-top: 10px;

    span {
        opacity: 1;
    }

    &:has(.legal-note) {
        flex-direction: column;
        align-items: flex-start;

        .legal-note {
            font-family: $primaryFont;
            font-weight: 100;
            font-size: 11px;
            line-height: 15.51px;
            color: $primaryColor;
            margin-bottom: 10px;

            a {
                text-decoration: underline;
            }
        }
    }

    &.legal-note {
        padding-top: 0;
        margin-top: 0;
        margin-bottom: 29px;

        span {
            font-family: $primaryFont;
            font-weight: 100;
            font-size: 11px;
            line-height: 15.51px;
            color: $primaryColor;

            a {
                text-decoration: underline;
            }
        }
    }
}

.last-is-hidden+.w-form-line {
    display: none;
}

.elem_global:has(.w-has-error) .w-form-line.notice span {
    opacity: 1;
}

.mail-pass {
    flex-wrap: wrap;
    gap: 1rem;

    .w-input-container {
        width: calc(50% - .5rem);
        margin: 0;

        &.pass-1,
        &.pass-2 {
            margin-bottom: 10px;
            order: 1;
        }
    }
}

.w-visibility-toggle {
    border: none;
}

.wrapper_adresses_title {
    display: none;
}

.addrAutoComplete,
.addrFullDisplay {
    legend {
        display: none;
    }
}

#iconValidadresseLiv {
    display: none;
}

.w-form-line .noaddr {
    right: 0;
    top: -3px;
    transform: unset;
    font-size: $fsSmlSubTitle;

}