$countryCodes: (
    1: 'AC', 2: 'AD', 3: 'AE', 4: 'AF', 5: 'AG', 6: 'AI', 7: 'AL', 8: 'AM', 9: 'AO', 10: 'AQ', 11: 'AR', 12: 'AS',
    13: 'AT', 14: 'AU', 15: 'AW', 16: 'AX', 17: 'AZ', 18: 'BA', 19: 'BB', 20: 'BD', 21: 'BE', 22: 'BF', 23: 'BG',
    24: 'BH', 25: 'BI', 26: 'BJ', 27: 'BL', 28: 'BM', 29: 'BN', 30: 'BO', 31: 'BQ', 32: 'BR', 33: 'BS', 34: 'BT',
    35: 'BV', 36: 'BW', 37: 'BY', 38: 'BZ', 39: 'CA', 40: 'CC', 41: 'CD', 42: 'CF', 43: 'CG', 44: 'CH', 45: 'CI',
    46: 'CK', 47: 'CL', 48: 'CM', 49: 'CN', 50: 'CO', 51: 'CP', 52: 'CR', 53: 'CU', 54: 'CV', 55: 'CW', 56: 'CX',
    57: 'CY', 58: 'CZ', 59: 'DE', 60: 'DG', 61: 'DJ', 62: 'DK', 63: 'DM', 64: 'DO', 65: 'DZ', 66: 'EA', 67: 'EC',
    68: 'EE', 69: 'EG', 70: 'EH', 71: 'ER', 72: 'ES', 73: 'ET', 74: 'EU', 75: 'FI', 76: 'FJ', 77: 'FK', 78: 'FM',
    79: 'FO', 80: 'FR', 81: 'GA', 82: 'GB', 83: 'GD', 84: 'GE', 85: 'GF', 86: 'GG', 87: 'GH', 88: 'GI', 89: 'GL',
    90: 'GM', 91: 'GN', 92: 'GP', 93: 'GQ', 94: 'GR', 95: 'GS', 96: 'GT', 97: 'GU', 98: 'GW', 99: 'GY', 100: 'HK',
    101: 'HM', 102: 'HN', 103: 'HR', 104: 'HT', 105: 'HU', 106: 'IC', 107: 'ID', 108: 'IE', 109: 'IL', 110: 'IM',
    111: 'IN', 112: 'IO', 113: 'IQ', 114: 'IR', 115: 'IS', 116: 'IT', 117: 'JE', 118: 'JM', 119: 'JO', 120: 'JP',
    121: 'KE', 122: 'KG', 123: 'KH', 124: 'KI', 125: 'KM', 126: 'KN', 127: 'KP', 128: 'KR', 129: 'KW', 130: 'KY',
    131: 'KZ', 132: 'LA', 133: 'LB', 134: 'LC', 135: 'LI', 136: 'LK', 137: 'LR', 138: 'LS', 139: 'LT', 140: 'LU',
    141: 'LV', 142: 'LY', 143: 'MA', 144: 'MC', 145: 'MD', 146: 'ME', 147: 'MF', 148: 'MG', 149: 'MH', 150: 'MK',
    151: 'ML', 152: 'MM', 153: 'MN', 154: 'MO', 155: 'MP', 156: 'MQ', 157: 'MR', 158: 'MS', 159: 'MT', 160: 'MU',
    161: 'MV', 162: 'MW', 163: 'MX', 164: 'MY', 165: 'MZ', 166: 'NA', 167: 'NC', 168: 'NE', 169: 'NF', 170: 'NG',
    171: 'NI', 172: 'NL', 173: 'NO', 174: 'NP', 175: 'NR', 176: 'NU', 177: 'NZ', 178: 'OM', 179: 'PA', 180: 'PE',
    181: 'PF', 182: 'PG', 183: 'PH', 184: 'PK', 185: 'PL', 186: 'PM', 187: 'PN', 188: 'PR', 189: 'PS', 190: 'PT',
    191: 'PW', 192: 'PY', 193: 'QA', 194: 'RE', 195: 'RO', 196: 'RS', 197: 'RU', 198: 'RW', 199: 'SA', 200: 'SB',
    201: 'SC', 202: 'SD', 203: 'SE', 204: 'SG', 205: 'SH', 206: 'SI', 207: 'SJ', 208: 'SK', 209: 'SL', 210: 'SM',
    211: 'SN', 212: 'SO', 213: 'SR', 214: 'SS', 215: 'ST', 216: 'SV', 217: 'SX', 218: 'SY', 219: 'SZ', 220: 'TA',
    221: 'TC', 222: 'TD', 223: 'TF', 224: 'TG', 225: 'TH', 226: 'TJ', 227: 'TK', 228: 'TL', 229: 'TM', 230: 'TN',
    231: 'TO', 232: 'TR', 233: 'TT', 234: 'TV', 235: 'TW', 236: 'TZ', 237: 'UA', 238: 'UG', 239: 'UM', 240: 'US',
    241: 'UY', 242: 'UZ', 243: 'VA', 244: 'VC', 245: 'VE', 246: 'VG', 247: 'VI', 248: 'VN', 249: 'VU', 250: 'WF',
    251: 'WS', 252: 'XK', 253: 'YE', 254: 'YT', 255: 'ZA', 256: 'ZM', 257: 'ZW'
);

$iconsMap: (1: 'fb-connect', 2:'fb-connect_b', 3: 'tt-connect', 4: 'go-connect');
$oneClickMap: (1: 'ap-oneclick', 2:'pp-oneclick', 3: 'go-oneclick', 4: 'fb-oneclick', 5: 'apple-oneclick');

.w-oneclick-connect {
    display: flex;

    .w-social-btn {
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        cursor: pointer;
        background-color: #F3F3F3;

        & > div {
            width: 60%;
            height: 60%;
            margin: auto;
            @include spriter("../svg/oneclick_connect.svg", 5, 1, $oneClickMap);
        }

        span {
            display: none
        }

        + .w-social-btn {
            margin-left: 1.3rem;
        }
    }

    #googleConnect {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.4rem;
        height: 2.4rem;
        margin: 0 1rem;
        background: linear-gradient(180deg, #F7F7F7 0%, #E6E6E6 100%);

        .g_id_signin {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;

            >div {
                width: 100% !important;
                height: 100% !important;
            }
        }

        #googleConnectLabel {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            font-size: 0;

            &:before {
                content: '';
                display: block;
                width: 60%;
                height: 60%;
                @include bgIconPath("../svg/oneclick_connect.svg");
                @include bgIconCoord(3, 5, 1);
            }
        }
    }
}

// Animations
@keyframes fadeInFromRight {

    0% {
        transform: translateX(25%);
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    75% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeIn {

    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// Sprites
.has-flag-icn {
    @include spriter('../img/icons/flags.png', 23, 12, $countryCodes);
}

// Layout
.w-lines-group {
    position: relative;
    width: 100%;
    margin-bottom: 30 / 16 * 1rem;

    .w-form-line {
        margin-bottom: 4 / 16 * 1rem;
    }
}

.w-input-group {
    margin-bottom: 30 / 16 * 1rem;

    .w-group-label {
        font-family: Arial, sans-serif;
        font-weight: 400;
        font-size: 14 / 16 * 1rem;
        margin-bottom: 15 / 14 * 1em;
    }
}

.w-fieldset-label {
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-size: 11 / 16 * 1rem;
    color: #AAA;
    letter-spacing: 0.5 / 11 * 1em;
    margin-bottom: 0.5rem;
}

.w-line-label {
    width: 1 / 4 * 100%;
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-size: 11 / 16 * 1rem;
    color: #AAA;
    letter-spacing: 0.5 / 11 * 1em;
}

.v-toggle {
    max-height: 0;
    overflow: hidden;
    box-sizing: border-box;

    .w-form-line:first-of-type {
        margin-top: 30 / 16 * 1rem;
    }
}

.w-form-line {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    margin-bottom: 30 / 16 * 1rem;
    animation: fadeIn 0.35s ease-out 0s 1 forwards;

    &.w-has-tel {
        z-index: 5;
    }

    &.w-submit {
        max-width: 254 / 16 * 1rem;
    }

    &.column {
        flex-direction: column;
        align-items: flex-start;
    }

    &.last-is-hidden {
        margin-bottom: 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.w-input-container {
    position: relative;
    width: 100%;

    & + & {
        margin-left: 1rem;
    }
}

.w-input {
    display: block;
    position: relative;
    width: 100%;

    &.w-tel-input {

        .selected-flag{
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 38 / 16 * 1rem;
            height: 70%;
            padding: 0 0.5rem;
            border-right: 1px solid #D9D9D9;
            cursor: pointer;
            box-sizing: content-box;

            span {
                display: none;
            }

            &::before {
                display: block;
                width: 22 / 16 * 1rem;
                height: 15 / 16 * 1rem;
                content: '';
            }

            &::after {
                display: block;
                width: 0;
                height: 0;
                border-left: 0.35em solid transparent;
                border-right: 0.35em solid transparent;
                border-top: 0.35em solid #000;
                margin-left: 4 / 16 * 1rem;
                content: '';
            }

            .countryDialCode {
                display: none;
            }
        }

        .country-list {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            max-height: 200px;
            background-color: #FFF;
            border: 1px solid #D9D9D9;
            z-index: 5;
            overflow: auto;

            .country {
                display: flex;
                background-color: #FFF;
                padding: 0.5em 0.5rem;
                font-family: Arial, sans-serif;
                font-weight: 400;
                font-size: 13 / 16 * 1rem;
                transition: background-color 0.2s linear;

                span {
                    margin: 0 0.75em;
                }

                &::before {
                    display: block;
                    width: 22 / 16 * 1rem;
                    height: 15 / 16 * 1rem;
                    content: '';
                }

                &::after {
                    display: inline;
                    color: #999;
                    content: attr(data-dial-code);
                }

                &:hover {
                    background-color: #F2F2F2;
                }
            }

            &.active {
                display: block;
            }
        }

        .w-input-element:not([type=radio]):not([type=checkbox]),
        input[type=email].w-input-element,  // TODO:
        input[type=password].w-input-element,   // Get rid
        input[type=text].w-input-element,   // of these
        textarea.w-input-element {  // override rules
            padding-left: 62 / 14 * 1em;
        }

        .w-input-label {
            left: 62 / 16 * 1rem;
        }
    }

    select:focus {
        outline: none;
    }

    &.w-etat-input {
        position: relative;
        height: 48 / 14 * 1em;
        border: 1px solid #D9D9D9;
        border-radius: 5px;
        transition-property: border-color, box-shadow;
        transition-duration: 0.2s;
        transition-timing-function: ease-out;

        .selected-state {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            border: none;
            font-family: Arial, sans-serif;
            font-weight: 400;
            font-size: 14 / 16 * 1rem;
            padding: 0 (12 / 14 * 1em) 0;
            background: transparent;

            &:focus {
                outline:0;
            }
        }

        .state-list {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            max-height: 200px;
            background-color: #FFF;
            border: 1px solid #D9D9D9;
            z-index: 5;
            overflow: auto;

            .country-name, .state {
                display: flex;
                padding: 0.5em 0.5rem;
                font-family: Arial, sans-serif;
                font-weight: 400;
                font-size: 13 / 16 * 1rem;
            }

            .state {
                background-color: #FFF;
                transition: background-color 0.2s linear;

                &:hover {
                    background-color: #F2F2F2;
                }

            }

            &.active {
                display: block;
            }
        }

        .w-input-element:not([type=radio]):not([type=checkbox]),
        input[type=email].w-input-element,  // TODO:
        input[type=password].w-input-element,   // Get rid
        input[type=text].w-input-element,   // of these
        textarea.w-input-element {  // override rules
            padding-left: 62 / 14 * 1em;
        }

        &::after {
            display: block;
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-left: 0.35em solid transparent;
            border-right: 0.35em solid transparent;
            border-top: 0.35em solid #000;
            margin-left: 4 / 16 * 1rem;
            content: '';
        }
    }
}

.w-input-element:not([type=radio]):not([type=checkbox]),
input[type=email].w-input-element,  // TODO:
input[type=password].w-input-element,   // Get rid
input[type=text].w-input-element,   // of these
textarea.w-input-element {  // override rules
    appearance: none;
    vertical-align: top;
    width: 100%;
    height: 48 / 14 * 1em;
    background-color: transparent;
    padding: 0 (12 / 14 * 1em);
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    box-shadow: 0 0 0 0 transparent;
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-size: 14 / 16 * 1rem;
    transition-property: padding, border-color, box-shadow;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;

    &::placeholder {
        transition: opacity 0.2s linear;
    }

    &[type=date] {

        &::-webkit-clear-button {
            appearance: none;
            display: block;
            position: absolute;
            top: 50%;
            right: 2.25rem;
            transform: translateY(-50%);
            width: 0.75rem;
            height: 0.75rem;
            background: transparent url(../img/close_btn.jpg) center no-repeat;
            background-size: contain;
            margin: 0;
            cursor: pointer;
        }

        &::-webkit-inner-spin-button {
            appearance: none;
            display: none;
        }

        &::-webkit-calendar-picker-indicator {
            appearance: none;
            display: block;
            position: absolute;
            top: 50%;
            right: 0.9rem;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            padding: 0;
            border-left: 0.35rem solid transparent;
            border-right: 0.35rem solid transparent;
            border-top: 0.35rem solid #000;
            margin: 0;
            cursor: pointer;
        }
    }

    .w-input:not(.w-dropdown) & {

        &:focus {
            border-color: #999999;
            box-shadow: 0 0 0 1px #999999;

            &::placeholder {
                opacity: 0;
            }
        }
    }

    .w-has-error:not(.w-dropdown) & {
        border-color: #FF6D6D;
        box-shadow: 0 0 0 1px #FF6D6D;
    }

    .w-nonempty:not(.no-label) &,
    .w-date-input & {
        padding-top: 1.15em;
    }

    .w-textarea & {
        line-height: #{(16 / 14)};
        height: 112 / 14 * 1em;
        padding: 1em (12 / 14 * 1em);
        resize: none;

        + .w-input-label {
            top: 0;
            transform: translateY(100%);
        }
    }

    .w-textarea.w-nonempty &,
    .w-textarea.w-nonempty:not(.no-label) & {
        padding-top: 23 / 14 * 1em;

        + .w-input-label {
            transform: translateY(55%);
        }
    }

    &::-ms-reveal, &::-ms-clear {
        display: none;
    }
}

.w-input-label {
    position: absolute;
    top: 50%;
    left: 14 / 16 * 1rem;
    transform: translateY(-50%);
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-size: 14 / 16 * 1rem;
    color: #BBBBBB;
    opacity: 1;
    user-select: none;
    transition:
        transform 0.2s ease-out,
        font-size 0.2s ease-out;

    .w-nonempty &,
    .w-date-input & {
        transform: translateY(-115%);
        font-size: 12 / 16 * 1rem;
        opacity: 1;
        transition:
            transform 0.2s ease-out,
            font-size 0.2s ease-out,
            opacity 0.2s linear;
    }

    .w-input:not(.w-nonempty):not(.w-date-input) .w-input-element:-internal-autofill-previewed + & {
        opacity: 0;
        transition:
            transform 0.2s ease-out,
            font-size 0.2s ease-out,
            opacity 0s linear;
    }
}

.w-visibility-toggle {
    position: absolute;
    top: 50%;
    right: 1px;
    transform: translateY(-50%);
    width: 24 / 16 * 1rem;
    height: 24 / 16 * 1rem;
    padding: 12 / 16 * 1rem;
    box-sizing: content-box;
    cursor: pointer;
    opacity: 0;
    z-index: -5;
    transition:
        opacity 0.199s ease-out,
        z-index 0.001s linear 0.199s;

    &:not(.w-no-bg) {
        background: transparent url(../img/icons/visibility.svg);
        background-size: 200% auto;
        background-clip: content-box;
        background-origin: content-box;
    }

    &.w-active {
        background-position: right top;
    }

    .w-nonempty & {
        opacity: 1;
        z-index: 5;
        transition:
            opacity 0.199s ease-out 0.001s,
            z-index 0.001s linear;
    }
}

.w-social-connect {
    display: flex;
    position: absolute;
    top: 50%;
    right: 1px;
    transform: translateY(-50%);
    padding: 0 0.5rem;

    .w-social-btn {
        display: block;
        width: 1.3rem;
        height: 1.3rem;
        cursor: pointer;
        @include spriter("../svg/social_fb.svg", 4, 1, $iconsMap);

        + .w-social-btn {
            margin-left: 0.5rem;
        }
    }
}

.error_field_tunnel.wrapper_msg_bounce {
    color: #FF6D6D;
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-size: 12 / 16 * 1rem;
    line-height: 1.5;
    font-style: italic;
    margin-bottom: 1.8rem;
}

.w-input-note {
    display: none;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-size: 12 / 16 * 1rem;
    line-height: 1.5;
    margin: (4 / 12 * 1em) 0 (8 / 12 * 1em);

    &:first-of-type {
        display: block;
    }

    &.w-input-error {
        color: #FF6D6D;
        animation: fadeInFromRight 0.35s ease-out 0s 1 forwards;
    }

    &.w-input-valid {
        color: #52BF52;
        animation: fadeInFromRight 0.35s ease-out 0s 1 forwards;
    }
}

.email-form-line {
    // Hide Error message on load (Avoids having an error message displayed while typing a new user email, before AJAX is done).
    .w-input-note {
        display: none;
    }

    // Display Error message when error is detected (After Ajax is done).
    &:has(.w-email-input.w-has-error) {
        .w-input-note {
            display: block;
        }
    }
}

.w-pwd-reset {
    align-self: flex-start;
    font-size: 11 / 16 * 1rem;
    color: #ABABAB;
}

.w-radio-group {
    display: flex;

    &.w-has-error .w-input-element + .w-input-label {
        color: #FF6D6D;

        &::before {
            border-color: #FF6D6D;
            box-shadow: 0 0 0 1px #FF6D6D;
        }
    }
}

.w-checkbox-input,
.w-radio-input {
    display: flex;
    align-items: center;
    position: relative;
    width: auto;
    height: 1em;
    border: none;

    & + & {
        margin-left: 1em;
    }

    .w-input-element {
        position: absolute;
        top: 0;
        left: 0;
        width: 1em;
        height: 1em;
        opacity: 0;

        + .w-input-label {
            display: flex;
            align-items: center;
            position: relative;
            top: auto;
            left: auto;
            transform: none;
            font-size: 12 / 16 * 1rem;
            cursor: pointer;
            user-select: none;

            span {
                margin-left: 0.5em;

                &.multiline {
                    display: flex;
                    flex-direction: column;
                }

                span {
                    margin-left: 0;
                }
            }

            &::before {
                display: block;
                width: 1em;
                height: 1em;
                background-color: #FFF;
                border: 1px solid #E4E4E4;
                box-shadow: inset 0 0 0 0.5em #FFFFFF;
                transition: 0.2s ease-out;
                transition-property: background-color, box-shadow;
                content: '';
            }
        }

        &:checked + .w-input-label::before {
            background-color: #3C3C3C;
            box-shadow: inset 0 0 0 0.125em #FFF;
        }
    }
}

.info_site {
    margin-top: 15 / 16 * 1rem;
}

.w-radio-input .w-input-element + .w-input-label::before {
    border-radius: 50%;
}

.w-dropdown {
    position: relative;
    height: 42 / 14 * 1em;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    transition-property: border-color, box-shadow;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;

    &.w-focused {
        border-color: #999999;
        box-shadow: 0 0 0 1px #999999;
    }

    &.w-has-error {
        border-color: #FF6D6D;
        box-shadow: 0 0 0 1px #FF6D6D;
    }

    .w-input-element {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0;
        opacity: 0;
        transition-property: padding, opacity;
        transition-duration: 0.2s;
        transition-timing-function: ease-out;
        z-index: 5;
    }

    .w-value {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        border: none;
        font-family: Arial, sans-serif;
        font-weight: 400;
        font-size: 14 / 16 * 1rem;
        padding: (16 / 14 * 1em) (12 / 14 * 1em) 0;
    }

    &::after {
        display: block;
        position: absolute;
        top: 50%;
        right: 0.5rem;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-left: 0.35em solid transparent;
        border-right: 0.35em solid transparent;
        border-top: 0.35em solid #000;
        margin-left: 4 / 16 * 1rem;
        content: '';
    }
}

.w-btn-wrapper {
    position: relative;
    width: 100%;

    & + & {
        margin-left: 1.25rem;
    }
}

.w-submit-button,
button.w-submit-button {
    appearance: none;
    width: 100%;
    height: 50 / 14 * 1em;
    background-color: #3C3C3C;
    padding: 0;
    border: 1px solid #3C3C3C;
    border-radius: 0;
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-size: 14 / 16 * 1rem;
    color: #FFFFFF;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.2s ease-out;

    &:not(.w-loader):hover,
    &:not(.w-loader):focus {
        background-color: #FFFFFF;
        color: #3C3C3C;
    }

    &.w-loader {
        position: absolute;
        top: 0;
        left: 0;
        cursor: default;
        background: #3C3C3C url(../svg/three_dots.svg) center no-repeat;
        background-size: auto 25%;
        opacity: 0;
        z-index: -5;
        transition:
            opacity 0.199s ease-out,
            z-index 0.001s linear 0.199s;

        span {
            display: none;
        }

        &.loading {
            opacity: 1;
            z-index: 5;
            transition:
                opacity 0.199s ease-out 0.001s,
                z-index 0.001s linear;
        }
    }
}

//
//  Address Autocomplete (override Google)
// *********************************************************

.pac-container {
    font-family: Arial, sans-serif;
    font-weight: 400;

    .pac-item {
        display: flex;
        align-items: center;
        line-height: (30 / 11 * 14) / 14 * 1em;
        font-size: 14 / 16 * 1rem;
    }

    .pac-icon {
        margin-top: 0;
    }
}

//
//  Formulaire d'identification (connexion, inscription...)
// *********************************************************

.login_bloc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding-bottom: 30 / 16 * 1rem;

    .w-client-form {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 300px;
        height: 100%;
    }

    .w-form-line.w-submit {
        max-width: none;
        margin-top: auto;
    }

    .content_form.sponsor {
        margin-bottom: 30 / 16 * 1rem;
        width: 100%;

        .wrapper_link_sponsor {
            margin-bottom: 1rem;

        .link_sponsor {
            display: inline-block;
            border-bottom: 1px solid #000000;
            &::first-letter {
                text-transform: uppercase;
            }
        }
        }
    }

    .form-msg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Arial, sans-serif;
        font-size: 12 / 16 * 1rem;
        text-align: center;
        width: 100%;
        max-height: 0;
        background-color: #F8F3F0;
        margin-bottom: 0;
        opacity: 0;
        transition:
            max-height 0.2s linear,
            margin-bottom 0.15s ease-out 0.2s,
            opacity 0.35s ease-out;

        &.error {
            background-color: #FFD9D9;
            color: #FF6D6D;
        }

        &.active {
            max-height: 4em;
            margin-bottom: 1rem;
            opacity: 1;
            transition:
                max-height 0.2s ease-out 0.15s,
                margin-bottom 0.15s linear,
                opacity 0.35s ease-out;
        }

        p {
            padding: 0.5em 0;
        }
    }

    #AuthInactifErr.form-msg p {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
            padding: 0 (14 / 12 * 1em);
        }
    }
}

//
//  Nouveau formulaire de connexion
// *********************************************************

.newlogin_page {
    display: flex;
    justify-content: flex-start;;
    align-items: center;
    min-height: 453px;
    padding-top: 30px;
    flex-direction: column;

    .login_bloc {
        width: 32%;

        &:first-of-type {
            border-bottom: 1px solid #b1b1b1;
            padding-bottom: 0.8rem;
        }

        &:last-of-type {
            padding-top: 2.2625rem
        }

        .title {
            text-align: center;

            &:not(.my_account) {
                margin-bottom: 1.2rem;
            }

            h2,
            h3,
            &.my_account .subtitle,
            h4 {
                text-transform: uppercase;
                font-family: Arial, sans-serif;
                font-weight: 600;
            }

            h2 {
                font-size: 30px;
                letter-spacing: 0.27rem;
                margin-bottom: 0.3rem;
            }

            h3 {
                margin-top: 2.7rem;
                font-size: 18px;
                letter-spacing: 0.15rem;
                margin-bottom: 0.5rem;
            }

            h4 {
                font-size: 12px;
                letter-spacing: 0.1rem;
                margin-bottom: 0.5rem;
            }

            &.my_account .subtitle {
                font-size: 12px;
                letter-spacing: 0.15rem;
            }

            &:not(.my_account) .subtitle {
                font-size: 15px;
                color: #000;
                display: block;

                &.cache {
                    display: none;
                }
            }
        }

        .login_form_wrapper,
        .resetPass,
        .w-lostpass-form,
        .AuthErrContainer {
            width: 100%;
        }

        .w-lostpass-form {

            .mdpSentEmail {
                margin-bottom: 25 / 16 * 1rem;
                font-size: 15px;
            }

            .w-form-line.w-submit {
                margin-bottom: 25 / 16 * 1rem;
                max-width: 240 / 16 * 1rem;
                margin-top: 0;
            }
        }
    }

    .w-newlogin-form {
        width: 96%;
        margin: auto;

        .w-fieldset-label {
            font-family: Arial, sans-serif;
            font-weight: 400;
            font-size: 14 / 16 * 1rem;
            color: #000;
            letter-spacing: 0;
            margin: 0 auto 0.8rem;
        }

        .knowMoreOptin {
            margin-left: 2em;

            .info_site {
                margin-top: 0;
                a {
                    text-decoration: none;
                    font-size: 15px;
                    color: #ABABAB;
                    border-bottom: 1px solid #ABABAB;
                }
            }
        }

        .optin_container {
            justify-content: center;

            &.column {
                flex-direction: column;
                align-items: center;

                & > .w-form-line {
                    width: 50%;
                    justify-content: center;

                    .w-line-label {
                        width: auto;
                        min-width: 100px;
                    }
                }

                .knowMoreOptin {
                    margin: 0;
                }
            }

            .w-input-note.w-input-error {
                text-align: center;
            }
        }

        .w-pwd-reset {
            text-decoration: none;
            align-self: flex-start;
            font-size: 15px;
            color: #ABABAB;
            margin: 0 auto;
            border-bottom: 1px solid #ABABAB;
        }

        .intro {
            font-size: 15px;
            color: #ABABAB;
            margin-bottom: 1.2rem;
            text-align: center;
        }

        .g-recaptcha {
            margin: 0 auto 25 / 16 * 1rem;
        }

        .w-form-line.w-submit {
            margin-bottom: 25 / 16 * 1rem;
            max-width: 240 / 16 * 1rem;
            margin-top: 0;
            order: 8;
        }

        .content_form {
            &.sponsor {
                .wrapper_input_sponsor {
                    display: none;
                }
            }
        }
    }
}

body.in_tunnel {
    .newlogin_page {
        padding-top: 10px;
        .login_bloc .title h3 {
            margin-top: 0;
        }
    }
}

//
//  Formulaire d'info client
// *********************************************************

.w-info-form {
    padding-bottom: 80 / 16 * 1rem;
}

//
//  Formulaire d'adresse
// *********************************************************

.w-address-form {
    padding-bottom: 40 / 16 * 1rem;
}

.w-form-line .noaddr {
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: red;
    text-decoration: underline;
    animation: fadeIn 0.35s ease-out 0s 1 forwards;
}

.elem_global {
    margin-bottom: 30 / 16 * 1rem;
}

//
//  Formulaire de code promo
// *********************************************************

.w-coupon-form {
    position: relative;
    width: 100%;

    .w-form-line {
        margin-bottom: 0;

        .w-input-element:not([type=radio]):not([type=checkbox]),
        input[type=text].w-input-element {
            border-radius: 5px 0 0 5px;
        }

        &.w-submit {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: auto;

            .w-submit-button {
                width: 3rem;
                height: 3rem;
            }
        }
    }
}

.hidden_elem_liv > .wrapper_select_adr {
    padding-bottom: 32 / 16 * 1rem;
}

//
//  Formulaire de contact
// *********************************************************

.w-contact-form {
    padding-bottom: 80 / 16 * 1rem;

    .file-input-row {
        margin-top: 1.5rem;

        label {
            @extend .w-submit-button;
            background-color: white !important;
            border: 1px solid #2f2d2e !important;
            color: #2f2d2e !important;
            width: 280px;
            height: 35px !important;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        input[type="file"] {
            width: 1px;
            height: 1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }
    }

    .file-list {
        .rowList {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            span.uploadedFile {
                font-family: sans-serif;
                font-size: 13px;
                color: black;
            }

            button.closePj {
                position: relative;
                width: 1rem;
                height: 1rem;
                margin-left: 1rem;
                opacity: .6;
                cursor: pointer;

                &:hover {
                    opacity: 1;
                }

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    width: 2px;
                    height: 1rem;
                    left: .5rem;
                    top: 0;
                    background-color: black;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }
            }
        }
    }
}

//
//  Formulaire de partage à un ami (par mail)
// *********************************************************

.w-share-form {
    padding-bottom: 40 / 16 * 1rem;

    .w-submit {
        margin: auto;
    }
}

//
//  Formulaire d'alerte réassort
// *********************************************************

.w-alertestock-form {
    width: 338px;
    margin: 0 auto (10 / 16 * 1rem);

    .w-form-line {
        &.w-submit {
            max-width: none;
        }
    }

}

//
//  Formulaire d'inscription à la newsletter
// *********************************************************

.w-newsletter-form {
    position: relative;
    width: 20rem;

    .w-input-element:not([type=radio]):not([type=checkbox]),
    input[type=email].w-input-element {
        padding-right: 60 / 16 * 1rem;
        border-radius: 5px 0 0 5px;
    }

    .w-form-line.w-submit {
        position: absolute;
        top: 0;
        right: 0;

        &, .w-submit-button {
            width: 48 / 16 * 1rem;
            height: 48 / 16 * 1rem;
        }
    }
}

//
//  Formulaire d'inscription à la newsletter (lightbox)
// *********************************************************

.w-adwords-form,
.lightbox#popup_inscription_home #formu_inscription_news form.w-adwords-form,
.lightbox#exit_overlay_OVI #formu_inscription_news form.w-adwords-form {
    text-align: left;

    .w-form-line.w-submit {
        margin: 0 auto;
    }
}

//
//  Formulaire de création d'un nouveau mot de passe
// *********************************************************

.w-newpass-form {
    text-align: left;

    .w-form-line.w-submit {
        max-width: none;
    }
}

//
//  Formulaire de message
// *********************************************************

.w-reply-form {
    padding: 30 / 16 * 1rem;
}

//
//  Text formating classes
// *********************************************************

.is-capitalized {
    text-transform: capitalize;
}

.is-uppercased {
    text-transform: uppercase;
}

//
//  IE Fix
// *********************************************************

html.ie {

    .form-msg:not(.active) {
        display: none;
    }
}

/**
 * PAYMENT CARDS SAVED IN ACCOUNT (STRIPE)
 */

#payment_form_stripe {
    label {
        display: block;
        margin-bottom: .5rem;
    }

    input[type="text"] {
        appearance: none;
        vertical-align: top;
        width: 80%;
        height: 2.5rem;
        background-color: transparent;
        padding: 0 .85714em;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        box-shadow: 0 0 0 0 transparent;
        font-family: Arial,
            sans-serif;
        font-weight: 400;
        font-size: .875rem;
        transition-property: padding, border-color, box-shadow;
        transition-duration: .2s;
        transition-timing-function: ease-out;

        &#cardholder_name_stripe {
            max-width: 500px;
        }
    }

    .StripeElement {
        appearance: none;
        vertical-align: top;
        width: 250px;
        height: 2.5rem;
        box-sizing: border-box;
        background-color: transparent;
        padding: .8rem .85714em 0;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        box-shadow: 0 0 0 0 transparent;
        font-family: Arial,
            sans-serif;
        font-weight: 400;
        font-size: .875rem;
        transition-property: padding,
            border-color,
            box-shadow;
        transition-duration: .2s;
        transition-timing-function: ease-out;
        margin-top: 1rem;

        &--invalid {
            border-color: red;
        }
    }

    #card_errors_stripe {
        font-family: sans-serif;
        font-size: .8rem;
        color: red;
        margin: .5rem 0 1rem;
    }

    .btn_container {
        width: 250px;
    }
}

.w-eresa-form  {
    .form-eresa-civility {
        justify-content: space-between;
    }
    .mandatory {
        font-size: 13px;
        color: #acabab;
    }
}

.w-sponsor-form {
    .remove_filleul {
        position: absolute;
        right: -1.5rem;
        cursor: pointer;
    }

    .label_parrainage {
        margin-bottom: 1rem;
        font-size: .9rem;
        display: block;
    }

    .error_parrainage {
        margin-bottom: 30 / 16 * 1rem;
    }

    .bloc_parrainage_form,
    .elem_parrainage {
        margin-bottom: 30 / 16 * 1rem;
    }

}

.w-newuser-form,
#cookiesLightbox {
    // checkbox switch on/off
    .w-checkbox-input .w-input-element +.w-input-label::before {
        width: 23px;
        height: 13px;
        background: #ECECEC;
        display: block;
        border-radius: 10px;
        position: relative;
        box-shadow: none;
    }

    .w-checkbox-input .w-input-element +.w-input-label::after {
        content: '';
        display: block;
        width: 13px;
        height: 13px;
        background: #fff;
        border-radius: 13px;
        transition: all .2s ease-out;
        position: absolute;
        top: 1px;
        left: 1px;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.48);
    }

    .w-checkbox-input .w-input-element:checked+.w-input-label::before {
        background-color: #909090;
        box-shadow: none;
    }

    .w-checkbox-input .w-input-element:checked+.w-input-label::after {
        left: 23px;
        transform: translateX(-13px);
    }
}

body.footer_contact,
body.contact_faq,
body.footer_contact_page{
    #recaptcha{
        justify-content: flex-start;
        margin-bottom: 2rem;
    }
}

/********** GIFT CARD FORM ***********/
.w-giftcard-form {
    p {
        font-size: .813rem;
    }

    .w-input-note {
        position: relative;
    }

    .w-form-line {
        &.giftcard-intro {
            justify-content: space-between;
        }

        &.giftcard-buttons-container {
            .w-form-line.w-submit:first-child {
                margin-right: 1rem;
            }
        }

        &.giftcard-buttons-container {
            margin-top: 1.875rem;
        }

        &.w-submit {
            max-width: 220px;
            margin: 0;
        }
    }

    .gift_card_title {
        font-weight: 600;
    }

    .w-submit-button {
        width: 220px;

        &.cancel {
            background-color: $primaryColor;
            color: $secondaryColor;
            border-color: $primaryColor;
            transition: background-color .2s ease-out;

            &:hover {
                background-color: $secondaryColor;
                color: $primaryColor;
                border-color: $secondaryColor;
            }
        }
    }

    .giftcard_desc {
        margin-bottom: 1rem;
    }

}

/**
 * Image erreur tel (ancien système)
 */
img#iconErrtelephone {
    display: none !important;
}

/* [WP-23678] Numéro de téléphone portable à rendre obligatoire - CSS pour generaliser le style */
body.customer,
body.step_2_0,
body.step_2.in_tunnel {
    #adresseForm,
    .wrapper_form_adresse #adresseForm,
    .wrapper_form form.w-form.w-info-form.w-login-form {
        .w-has-tel {
            align-items: start;

            .errormsgadd {
                color: #888c8d;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
}

/* [WP-26862] Password security check - global specific styling */
.w-form-line:has(.w-class-pwd-creation) {
    position: relative;
}

.w-password-input {

    & ~ .w-class-pwd-creation {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease-out;

        width: 100%;
    
        display: flex;
        flex-direction: column;
        gap: 15px;
    
        // list with checks on the children (.w-pwd-req) by default and crosses when the .w-pwd-req-error class is added
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .w-pwd-req {
                display: flex;
                align-items: center;
                gap: 10px;

                &::before {
                    content: '';
                    display: block;
                    width: 11px;
                    height: 11px;
                    background-color: $primaryColor;
                    mask-image: url(../svg/pwd-req-check.svg);
                    mask-size: contain; 
                    mask-repeat: no-repeat;
                    mask-position: center;
                }

                &.w-pwd-req-error::before {
                    mask-image: url(../svg/pwd-req-cross.svg); 
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    mask-position: center;
                    background-color: currentcolor;
                }
            }
        }

        //progressbar
        progress {
            --bar-color: $primaryColor;
            width: 100%;
            height: 5px;
            appearance: none;
            background-color: rgb(0, 0, 0);
            border: none;

            &.strength-0, &.strength-1 {
               --bar-color: #FF6D6D;
            }

            &.strength-2, &.strength-3, &.strength-4 {
                --bar-color: #FFD700;
            }

            &.strength-5 {
                --bar-color: #52BF52;
            }

            &::-webkit-progress-bar {
                background-color: rgb(0, 0, 0);
            }

            &::-webkit-progress-value {
                background-color: var(--bar-color);
                border-radius: 5px;
            }

            &::-moz-progress-bar {
                background-color: var(--bar-color);
                border-radius: 5px;
            }
        }
    }

    &.w-focused {
        & ~ .w-class-pwd-creation {
            opacity: 1;
            visibility: visible;
            display: flex !important;
        }
    }
}
