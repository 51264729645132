#site_head_wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 6;
}

html:not(.tablet) {
    #appContainer:has(.headerContainer:hover, .headerRollover.actif) #shad_menu {
        display: block;
        opacity: 1;
        z-index: 5;
    }
}

.tablet {
    #appContainer:has(.is-opened-menu, .is-opened-search, .headerRollover.actif) #shad_menu {
        display: block;
        opacity: 1;
        z-index: 5;
    }

    .is_logged a {
        pointer-events: none;
    }

    #cart_top {
        &:not(.actif) #show_top_cart {
            left: 100vw !important; // To prevent the cart from being displayed on the right side of the screen in, tablet mode 
        }

        .tablet_menu,
        .wrap_search_field,
        .basket_head {
            pointer-events: none;
        }
    }

}

/***** No promo bar case *****/
.headerContainer {
    height: var(--headerHeight);
    padding: 20px 0 0;
}

.show_top_cart {
    height: calc(100vh - #{var(--headerHeight)});
}

.rollAccountEntries {
    top: var(--headerHeight);
    height: calc(100vh - #{var(--headerHeight)});
}

.wrap_search_field {
    top: var(--headerHeight);
}

.sticky {
    .headerContainer {
        height: var(--headerRecalcHeight);
        padding: 0;
    }

    .show_top_cart {
        height: calc(100vh - #{var(--headerRecalcHeight)});
    }

    .rollAccountEntries {
        top: var(--headerRecalcHeight);
        height: calc(100vh - #{var(--headerRecalcHeight)});
    }

    .wrap_search_field {
        top: var(--headerRecalcHeight);
    }
}

.pheader {
    .show_top_cart {
        height: calc(100vh - #{var(--headerFull)});
    }

    .rollAccountEntries {
        top: var(--headerFull);
        height: calc(100vh - #{var(--headerFull)});
    }

    .wrap_search_field {
        top: var(--headerFull);
    }

    .sticky {
        .show_top_cart {
            height: calc(100vh - #{var(--headerRecalcHeightFull)});
        }

        .rollAccountEntries {
            top: var(--headerRecalcHeightFull);
            height: calc(100vh - #{var(--headerRecalcHeightFull)});
        }

        .wrap_search_field {
            top: var(--headerRecalcHeightFull);
        }
    }
}

/**** PROMO BAR *****/

.promo_bar .swiper-slide span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--preHeaderHeight);
    font-family: $primaryFont;
    font-weight: 400;

    &+span {
        font-weight: inherit;
    }
}

// countdown in preheader style 
.promo_bar:has(#preheaderCountdown) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--preHeaderHeight);
    font-family: $primaryFont;
    font-weight: 400;
    gap: fs-vw(3, 1920);
    color: $secondaryColor;
    background-color: $primaryColor;
}

#preheaderCountdown {
    width: 120px;
}

.wrapperCountdown {
    position: absolute;
    top: 50%;
    transform: translateY(-55%);
    display: flex;
    width: 700px;
    gap: 5px;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    animation: defilement 30s infinite linear;

    & > span {
        display: flex;

        &::after {
            content: '';
            display: flex;
            width: 1px;
            height: 15px;
            margin: 0 0 0 7px;
            background-color: $secondaryColor;
        }
    }
}

@keyframes defilement {
    0% {
        left: 100%;
    }
    100% {
        left: -700px;
    }
}

#preheaderCountdown,
.countdown-cont,
.countdown-block {
    display: flex;
    gap: fs-vw(3, 1920);
}

.countdown-block {
    filter: blur(4px);
    transition: filter 1s ease-in-out 1s;


    &.unblur {
        filter: blur(0);
    }
}

/**** HEADER CONTAINER ****/

.headerContainer {
    // position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    max-width: 1820px;
    height: var(--headerHeight);
    padding: 20px 0 0;
    margin: 0 auto;
    box-sizing: border-box;
    transition: height 0.3s, padding 0.3s;
}

.block_logo_menu {
    display: flex;
    align-items: center;

    .backdropArea {
        padding-left: 30px;
    }

    .wrap_lang {
        position: relative;
        display: block;
        height: 24px;
        width: 24px;
    }
}

.headerLogo {
    display: block;
    width: 180px;
    height: 29px;
    font-size: 0;
    background: var(--logo-color-home-scroll, $primaryColor);
    mask-image: url(../svg/logo.svg);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100%;
    margin-right: 30px;
}

.block_top_links {
    display: flex;
}

#appContainer:not(:has(.promo_bar)) {
    .rollAccountEntries {
        top: var(--headerRecalcHeight);
        height: calc(100vh - #{var(--headerRecalcHeight)});
    }
}

.rollAccountEntries {
    position: absolute;
    z-index: 6;
    top: var(--headerFull);
    left: 100vw;
    width: 350px;
    height: calc(100vh - #{var(--headerFull)});
    background-color: $secondaryColor;
    padding: 20px 0 0 25px;
    transition: left .5s ease, opacity .2s ease;
    cursor: default;
    opacity: 0;
    box-sizing: border-box;

    @media screen and(min-width: 1921px) {
        width: calc(350px + ((100vw - 1920px) / 2));
    }

    &#show_top_cart,
    &#show_top_wish {
        width: 400px;

        @media screen and(min-width: 1921px) {
            width: calc(400px + ((100vw - 1920px) / 2));
        }
    }

    .menu_compte_head {
        display: none;
    }
}

.upselling.price {
    order: 6;
    flex-direction: column;
    margin-top: 15px;

    p,
    strong .price {
        order: 1;
        @include fs-sml-thin-text;
    }

    progress {
        /* Reset the default appearance */
        appearance: none;
        display: block;
        width: 100%;
        height: 5px;
        border: 1px solid $primaryColor;
        border-radius: 20px;
        margin: 5px auto;
        background-color: rgba($secondaryColor, 0.1);

        /* Set a background color for the progress bar container */
        &::-webkit-progress-bar {
            background-color: rgba($secondaryColor, 0.1);
            border-radius: 0;
        }

        /* Set a background color for the filled portion of the progress bar */
        &::-webkit-progress-value {
            background-color: rgba($primaryColor, 1);
        }

        /* Set a background color for the filled portion of the progress bar in Firefox */
        &::-moz-progress-bar {
            background-color: rgba($primaryColor, 1);
        }
    }
}

.backdropArea {
    position: relative;
    display: flex;
    align-items: center;
    height: var(--headerHeight);
    width: 32px;
    cursor: pointer;
}

.lang_switch {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;

    &:hover {
        a+a {
            visibility: visible;
            opacity: 1;
            right: 29px;
        }
    }

    a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: $primaryColor;
        text-decoration: none;
        transition: visibility 0.2s, opacity 0.3s, left 0.3s, right 0.3s;
        padding-left: 5px;

        &.active,
        &:hover {
            span:first-child {
                background-color: $primaryColor;
                color: $secondaryColor;
                transition: background-color 0.3s;
            }
        }

        span {
            color: $white;

            &:first-child {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background-color: $tertiaryColor;
                @include fs-sml-medium-subtitle;
                color: $primaryColor;
                text-transform: uppercase;
                text-align: center;
            }
        }

        +a {
            position: absolute;
            visibility: hidden;
            opacity: 0;
            right: 0;
            padding-right: 5px;
            padding-left: 0;

        }
    }
}

.headerRollover {
    display: flex;
    align-items: center;
    height: var(--headerHeight);
    padding: 0 13px;
    cursor: pointer;

    &.actif .rollAccountEntries,
    &:hover .rollAccountEntries {
        left: calc(100vw - 350px);
        opacity: 1;

        .wrapper_menu_compte_container {
            opacity: 1;
            transition: opacity 0.5s ease;
        }

        @media screen and (min-width: 1921px) {
            left: calc(100vw - 350px - ((100vw - 1920px) / 2));
        }

        &#show_top_cart,
        &#show_top_wish  {
            left: calc(100vw - 400px);

            @media screen and (min-width: 1921px) {
                left: calc(100vw - 400px - ((100vw - 1920px) / 2));
            }

            &>div {
                opacity: 1;
                transition: opacity 0.5s ease;
            }
        }
    }

    &:last-of-type {
        padding: 0 0 0 15px;
    }

    &#search_top_small {
        padding: 0 30px 0 30px; // fix style after globalE elements loading
    }

    .bgSprite::before {
        @include maskIconPath('../svg/spritesheet.svg');
        background: $primaryColor;
        cursor: pointer;
    }

    /***** TOP ACCOUNT ROLLOVER STYLE *****/

    .wrapper_menu_compte_container {
        opacity: 0;
        display: flex;
        flex-wrap: wrap;
    }

    .account_name {
        width: 100%;
        @include fs-nrml-bold-title;
        margin-bottom: 25px;
    }

    .wrapper_menu_compte {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        div {
            display: flex;
            align-items: center;
            height: 32px;
            width: 100%;

            a {
                position: relative;
                width: fit-content;
                font-size: 13px;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;

                &:hover::after {
                    width: 100%;
                }

                &::after {
                    transition: width 0.2s ease-in;
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    display: block;
                    width: 0;
                    height: 1px;
                    background-color: $primaryColor;
                }
            }
        }
    }

    .btn_container {
        position: absolute;
        bottom: 75px;
        left: 137px;
        @include fs-std-medium-text;
        cursor: pointer;

        &:hover::after {
            width: 100%;
        }

        &::after {
            transition: width 0.2s ease-in;
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            width: 0;
            height: 1px;
            background-color: $primaryColor;
        }

        #btn-disconnect {
            color: $primaryColor;
        }
    }

    /***** TOP CART ROLLOVER STYLE *****/
    .rollover_title {
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        color: $primaryColor;
        margin-bottom: 16px;
    }

    .no_product {
        font-weight: 100;
    }
}

#ge_countryCode {
    @include secondaryButton(center, 3);
}

#cart_list {
    width: calc(100% + 17px);
    /* to compensate the 17px padding right for cross-browser compatibility */
    height: 100%;
    max-height: calc(100vh - #{var(--headerFull)} - 210px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 17px;
    /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box;
    /* So the width will be 100% + 17px */
    scrollbar-color: $primaryColor $greyBorder;
    scrollbar-width: thin;

    @media screen and(min-width: 1921px) {
        max-width: 360px;
    }

    .product-link {
        display: flex;

        &:not(:last-of-type) {
            margin-bottom: 20px
        }
    }

    .upselling.price {
        progress {
            width: 100%;
        }
    }
}

.wrap_top_info {
    .title {
        @include fs-std-bold-title;
        margin-top: 5px;
        white-space: nowrap;
        text-transform: capitalize
    }

    .wrapper_price {
        @include fs-std-thin-title;
    }
}

.bask_action {
    position: absolute;
    top: 0;
    left: 245px;

    .remove_prod span {
        font-size: 0;
    }

    .cross::before {
        @include maskIconCoord(24, $initialColSize, $initialRowSize);
        width: 11px;
        height: 11px;
    }
}

#topBasketToWish {
    display: none;
}

.wrap_tot_panier {
    width: 350px;
    height: 150px;
    border-top: 1px solid $primaryColorSlime;
    padding-top: 5px;
    box-sizing: border-box;

    .form_submit {
        position: relative;
        margin-top: 20px;

        .button {
            width: 100%;
        }

        .loader {
            position: absolute;
            top: 0;
            left: 0;
            height: 40px;
            background: unset;

            span {
                position: relative;
                display: block;
                width: 100%;
                height: 100%;
                font-size: 0;

                &::after {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: $primaryColor ;
                    mask-image: url(../svg/three_dots.svg);
                    mask-repeat: no-repeat;
                    mask-position: center;
                    mask-size: 25%;
                    border: 1px solid $primaryColor;
                }
            }
        }
    }
}

.cart_panier_total {
    display: flex;
    justify-content: space-between;

    .titre_tot {
        text-transform: uppercase;
    }

    .titre_tot,
    .pricetag,
    .price {
        @include fs-std-regular-title;
    }
}

#search_top_small,
#search_top {
    &:hover {
        .bgSprite::before {
            @include maskIconCoord(3, $initialColSize, $initialRowSize);
        }

    }

    .bgSprite::before {
        @include maskIconCoord(2, $initialColSize, $initialRowSize);
    }
}

.headerContainer:has(.is-opened-search) #search_top,
.headerContainer:has(.is-opened-search) #search_top_small {
    .bgSprite::before {
        @include maskIconCoord(3, $initialColSize, $initialRowSize);
    }
}

#distrib_top {
    &:hover {
        .bgSprite::before {
            @include maskIconCoord(5, $initialColSize, $initialRowSize);
        }
    }

    .bgSprite::before {
        @include maskIconCoord(4, $initialColSize, $initialRowSize);
    }
}

.satellite_store_details,
.store_locator {
    #distrib_top {
        .bgSprite::before {
            @include maskIconCoord(6, $initialColSize, $initialRowSize);
        }
    }
}


#account_top {
    &:hover {
        .bgSprite::before {
            @include maskIconCoord(8, $initialColSize, $initialRowSize);
        }

    }

    .bgSprite::before {
        @include maskIconCoord(7, $initialColSize, $initialRowSize);
    }

    &.is_logged {
        .bgSprite::before {
            @include maskIconCoord(9, $initialColSize, $initialRowSize);
        }
    }
}

#cart_top {

    &.actif,
    &:hover {
        .basket_head.bgSprite::before {
            @include maskIconCoord(11, $initialColSize, $initialRowSize);
        }

    }

    &:has(.nbProd) {
        .basket_head.bgSprite::before {
            @include maskIconCoord(12, $initialColSize, $initialRowSize);
        }
    }

    .basket_head {
        position: relative;

        .nbProd {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-55%, -45%);
            display: flex;
            @include fs-sml-bold-label;
            color: $secondaryColor;
            font-style: normal;
        }
    }

    .basket_head.bgSprite::before {
        @include maskIconCoord(10, $initialColSize, $initialRowSize);
    }
}

#wishlist_top {
    .wish_head.bgSprite::before {
        @include maskIconCoord(20, $initialColSize, $initialRowSize);
        height: 28px;
        width: 28px;
    }

    &.actif,
    &:hover {
        .wish_head.bgSprite::before {
            @include maskIconCoord(28, $initialColSize, $initialRowSize);
        }
    }

    &:has(.nbProd) {
        .wish_head.bgSprite::before {
            @include maskIconCoord(21, $initialColSize, $initialRowSize);
        }
    }

    .wish_head {
        position: relative;

        .nbProd {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-55%, -45%);
            display: flex;
            @include fs-sml-bold-label;
            color: $secondaryColor;
            font-style: normal;
        }
    }

    #topWishTobasket {
        span {
            font-weight: 700;
            font-size: 10px;
            line-height: 14.1px;
            font-family: $primaryFont;
            color: $primaryColor;
            text-decoration: underline;
            text-underline-offset: 5px;
            cursor: pointer;
            bottom: -5px;
            position: relative;
        }
    }

    .btn_voir_wishlist {
        margin-top: 0;

        .btn_voir_wishlist {
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-weight: 500;
                font-size: 15px;
                line-height: 37px;
                font-family: $primaryFont;
                color: $primaryColor;
                text-decoration: underline;
                text-underline-offset: 5px;
            }
        }
    }
}

#search_top {
    font-size: 0;

    .title_search {
        display: none;
    }

    #form_recherche {
        height: 100%;

    }

    #trigger_recherche {
        cursor: pointer;
    }
}