#cms_page_btn_back,
#cms_page_title {
    display: none;

}

/**
 * MODULE 1 Cover module
 */
.cms-page-module.slider-cover-module {
    position: relative;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;

    .swiper-slide {
        img {
            width: 100%;
            height: auto;
            vertical-align: top;
        }

        .slide_video {
            @include slide-video(960, 1920);

            a {
                display: block;
                padding-bottom: calc(920 / 1920* 100%);
            }
        }
    }

    .cms_module_contents {
        position: absolute;
        padding: 34px 30px;
        box-sizing: border-box;
        min-width: 26%;

        &.align-left {
            left: 6%;
        }

        &.align-center {
            left: 50%;
            transform: translateX(-50%);
        }

        &.align-right {
            right: 6%;
        }

        &.align-top {
            top: 8%;
        }

        &.align-middle {
            top: 50%;
            transform: translateY(-50%);
        }

        &.align-bottom {
            bottom: 8%;
        }

        &.align-center.align-middle {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .cms_module_pretitle {
        position: relative;
        font-size: clamp(16px, 1.042vw, 20px);
        font-weight: 200;
        letter-spacing: 0.2em;
        margin-bottom: .3em;
    }

    .cms-module-title {
        position: relative;
        font-family: $primaryFont;
        font-size: clamp(32px, 2.083vw, 40px);
        font-weight: 700;
        letter-spacing: 0.05em;
        margin-bottom: .6em;
        padding-bottom: .5em;

        &:after {
            content: '';
            display: block;
            width: 150px;
            height: 3px;
            background-color: $primaryColor;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    .cover-pagination {
        position: absolute;
        z-index: 1;
        bottom: 95px;
        text-align: center;

        .swiper-pagination-bullet {
            background: $bulletsColor;

            &.swiper-pagination-bullet-active {
                background: transparent;
                border: 1px solid $tertiaryColor;
            }
        }
    }
}

/**
 * MODULE 2 Text module
 */

.cms-page-module-body.text-module {
    max-width: 1920px;
    margin: 12px auto;
    width: 100%;
    padding: 6rem 0;

    .cms_page_text {
        max-width: 895px;
        width: 80%;
        margin: auto;
        @include fs-std-light-text;
    }
}


/**
 * MODULE 3 Image text
 */

.cms-page-module-body.image-text-1 {
    display: flex;
    max-width: 1920px;
    margin: 12px auto;

    img {
        width: 100%;
        height: auto;
    }

    .cms-page-column {
        width: 50%;
    }

    .cms-page-column.cms-page-column-txt {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .cms_page_title {
        max-width: 386px;
        width: 70%;
        margin-bottom: 15px;
        font-size: clamp(20px, fs-vw(30, 1920), 30px);
        font-weight: 700;
        line-height: normal;
    }

    .cms_page_text {
        max-width: 386px;
        width: 70%;
        font-size: 15px;
        font-weight: 100;
        line-height: normal;
    }
}

/**
 * MODULE 4 3 images
 */

.cms-page-module-body.three-images {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1920px;
    margin: auto;

    img {
        width: 100%;
        height: auto;
    }

    .cms-page-column {
        width: 33%;
        max-width: 630px;
    }
}

/**
 * MODULE 5 text image
 */

.cms-page-module-body.text-image {
    display: flex;
    max-width: 1920px;
    margin: 16px auto;

    img {
        width: 100%;
        height: auto;
    }

    .cms-page-column {
        width: 50%;
    }

    .cms-page-column.cms-page-column-txt {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .cms_page_title {
        max-width: 386px;
        width: 70%;
        margin-bottom: 15px;
        font-size: clamp(20px, fs-vw(30, 1920), 30px);
        font-weight: 700;
        line-height: normal;
    }

    .cms_page_text {
        max-width: 386px;
        width: 70%;
        font-size: 15px;
        font-weight: 100;
        line-height: normal;
    }
}

/**
 * MODULE 6 TG PRODUCTS
 */

.cms-page-module.tg-module {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 1605px;
    width: 98%;
    margin: 3rem auto;
    padding: 0 25px;
    box-sizing: border-box;

    .cms_page_title {
        @include fs-big-bold-title;
        margin-bottom: 1rem;
    }

    .swiper-container {
        width: 100%;
    }

    .slide_video {
        @include slide-video(412, 715);
    }

    .tg-module-next,
    .tg-module-prev {
        position: absolute;
        top: 45%;
        transform: translateY(-50%);
        display: block;
        width: 15px;
        height: 15px;
        @include maskIconPath('../svg/spritesheet.svg');
        @include maskIconCoord(1, $initialColSize, $initialRowSize);
        background-color: $primaryColor;
        z-index: 3;
        cursor: pointer;

        &.swiper-button-disabled {
            opacity: 0.5;
        }
    }

    .tg-module-prev {
        left: 0;
        transform: rotate(90deg);
    }

    .tg-module-next {
        right: 0;
        transform: rotate(-90deg);
    }

    .tg-module-pagination {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        z-index: 1;
    }

    .swiper-pagination-bullet {
        background: $bulletsColor;
    }

    .swiper-pagination-bullet-active {
        background: $white;
        border: 1px solid $primaryColor;
    }

}

/** 
 * MODULE 7 2 images
 */

.cms-page-module-body.image-2 {
    display: flex;
    justify-content: space-between;
    max-width: 1120px;
    margin: 12px auto;

    img {
        width: 100%;
        height: auto;
    }

    .cms-page-column {
        width: 49.7%;
        max-width: 510px;
    }

}

/**
 * MODULE 8 2 images + buttonon
 */

.cms-page-module-body.image-text-2 {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1920px;
    margin: auto;

    img {
        width: 100%;
        height: auto;
    }

    .cms-page-column {
        display: flex;
        justify-content: space-between;

        a {
            width: 49.7%;
            max-width: 952px;
        }
    }

    .cms-page-column-txt {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        a {
            display: flex;
            width: fit-content;
        }

        .cms_page_cta {
            width: fit-content;
            padding: 15px 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            background: $primaryColor;
            color: $white;
            border: 1px solid $primaryColor;
            box-sizing: border-box;

            &:hover {
                background: $white;
                color: $primaryColor;
            }
        }
    }
}


/**
 * MODULE 9 ARTICLES
 */

.cms-page-module.articles-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1920px;
    margin: 12px auto;

    img {
        width: 100%;
        height: auto;
    }

    a {
        width: 33%;
        max-width: 630px;
    }

    .title_cms_articles {
        width: 100%;
        padding-left: 7%;
        margin-bottom: 17px;
        color: $primaryColor;
        font-family: $primaryFont;
        font-size: 30px;
        font-weight: 700;
        line-height: 34px;

    }

    .cms-page-column.article_description {
        color: $primaryColor;
        font-family: $primaryFont;
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        margin: 20px 0 6px 0;
    }

    .name {
        margin-left: 20px;
    }

    .description {
        color: $primaryColor;
        font-family: $primaryFont;
        font-size: 15px;
        font-weight: 100;
        line-height: normal;
        margin-left: 20px;
    }
}

/**
 * MODULE 10 RECRUTEMENT
 */

.cms-page-module.recruitment-post {
    display: flex;

    .cms-page-module-img {
        width: 50%;
        max-width: 960px;
        margin-right: 11%;

        img {
            width: 100%;
            height: auto;
        }
    }

    .content_txt {
        width: 45%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .cms_page_title {
        font-family: $primaryFont;
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .cms-page-module-text {
        font-family: $primaryFont;
        font-size: 15px;
        font-weight: 100;
        line-height: normal;
    }

    .home-module-link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 269px;
        width: 100%;
        height: 40px;
        margin-top: 20px;
        transition: all 0.6s ease;

        &:hover {
            .cms_page_cta {
                background: $tertiaryColor !important;
                color: $primaryColor !important;
            }
        }
    }

    .cms_page_cta {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 50px;
        font-size: 15px;
    }
}


/**
 * END MODULE 10 RECRUTEMENT
 */