// PAGE LOGIN

.body_login .wrapper_tunnel .newlogin_page .login_bloc .login_form_wrapper .w-input-container {
    // _app override 😡
    padding: 8px 0 0;

    .w-nonempty {

        .w-input-element:not([type=radio]):not([type=checkbox]),
        input[type=email].w-input-element {
            height: 40px;
        }
    }
}

.body_login {
    #appContainer:not(:has(.promo_bar)) .content_wrapper {
        margin-top: 0;
    }

    &.customer {
        .login_tunnel_title {
            display: none;
        }
    }

    &:not(.cart):not(:has(.dynasearchwrapper)) {
        .page_wrapper {
            background: url(../img/satellite/background_account_connexion_sept_2024.jpg) no-repeat;
            background-size: cover;
            min-height: 995px;
            background-position: center top;
        }

        .content_wrapper {
            position: relative;
            min-height: 885px;
            box-sizing: border-box;
            padding-top: calc(8rem + var(--headerFull));
            margin-top: var(--preHeaderHeight);
        }
    }

    .bandeau_container {
        display: none;
    }


    .wrapper_tunnel {

        display: flex;
        justify-content: center;

        .newlogin_page {
            display: flex;
            flex-direction: column;
            align-items: center;
            box-sizing: border-box;
            width: 100%;
            max-width: 800px;
            padding: 2.2rem 1rem;
            margin-bottom: 2rem;
            background: $white;

            .login_bloc {
                width: 100%;
                border-bottom: none;

                .title.my_account {
                    width: 100%;

                    .acc_login_title {
                        display: flex;
                        justify-content: center;
                        font-family: $primaryFont;
                        font-size: 30px;
                        font-weight: 700;
                        line-height: 34px;
                        color: $primaryColor;
                        text-transform: initial;
                        letter-spacing: normal;
                    }

                    .subtitle {
                        display: none;
                    }
                }

                .title h3 {
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: normal;
                    color: $primaryColor;
                    margin-top: 28px;
                    text-transform: initial;
                    letter-spacing: normal;
                }

                .login_form_wrapper {

                    .title {
                        margin-bottom: 12px;

                        .subtitle {
                            font-family: $primaryFont;
                            font-size: 15px;
                            font-weight: 100;
                            line-height: normal;
                            color: $primaryColor;
                            opacity: .9;
                        }
                    }

                    .pwd-form-line,
                    .email-form-line {

                        .w-input-label {
                            font-weight: $light;
                        }
                    }

                    .email-form-line,
                    .pwd-form-line {
                        justify-content: center;
                        margin-bottom: 20px;

                        .w-input-label {
                            left: 0;
                        }

                        .w-visibility-toggle {
                            border: none;
                        }
                    }

                    .pwd-form-line {
                        margin: 0 0 1rem;
                    }

                    .pwd-reset-line {
                        margin: 15px 0 10px;

                        .w-pwd-reset {
                            color: $primaryColor;
                            font-family: $primaryFont;
                            font-size: 15px;
                            font-weight: 500;
                            line-height: normal;
                            border-bottom: none;
                            @include secondaryButton(center, 4);
                        }
                    }

                    .w-input-container {
                        display: flex;
                        border-bottom: 1px solid $greyLabel;
                        padding: 0;
                        max-width: 600px;
                    }

                    .w-input-element:not([type=radio]):not([type=checkbox]),
                    input[type=email].w-input-element {
                        height: 2rem;
                        border: none;
                        padding: 0;
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 14px;
                        font-weight: 100;
                        line-height: 18px;

                        &:focus {
                            outline: none;
                            box-shadow: none;
                            background: none;
                        }
                    }

                    .w-lostpass-form {
                        .w-form-line {
                            justify-content: center;

                            .w-nonempty .w-input-label {
                                left: 0;
                            }
                        }
                    }

                    .mdpSentEmail {
                        font-size: 13px;
                        font-weight: 100;
                        background: #f6f8ff;
                        padding: 8px;
                        line-height: normal;
                    }

                }

                .w-lines-group {

                    .w-fieldset-label {
                        width: 100%;
                        max-width: 600px;
                        text-align: left;
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 15px;
                        font-weight: 100;
                        line-height: normal;
                    }

                    .optin_container.column {

                        justify-content: flex-start;
                        max-width: 600px;
                        margin: auto;
                        align-items: flex-start;

                        .w-input-note.w-input-error {
                            top: 20%;
                            left: 40%;
                            width: fit-content;
                        }

                        >.w-form-line {
                            width: 50%;
                            max-width: 600px;
                            justify-content: flex-start;
                            align-items: center;
                            margin-bottom: 15px;

                            .w-line-label {
                                width: 33%;
                                color: $primaryColor;
                                font-family: $primaryFont;
                                font-size: 15px;
                                font-weight: 100;
                            }
                        }
                    }

                    .w-radio-input .w-input-element+.w-input-label {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 15px;
                        font-weight: 100;

                        span {
                            display: flex;
                            width: 45%;
                            height: 20px;
                            align-items: center;
                        }
                    }

                    .knowMoreOptin {
                        display: none;
                    }
                }

                .w-lines-group {
                    margin-bottom: 0;
                }

                .w-form-lines.w-submit {
                    justify-content: center;
                    margin-top: 20px;

                    .w-btn-wrapper {
                        display: flex;
                        justify-content: center;
                    }

                    .w-submit-button,
                    button.w-submit-button {
                        max-width: 189px;
                        width: 100%;
                    }
                }


                .title {
                    h4 {
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-weight: 500;
                        color: $primaryColor;
                        text-transform: initial;
                        letter-spacing: normal;
                    }

                    &:not(.my_account) .subtitle {
                        text-align: center;
                        font-family: $primaryFont;
                        font-size: 15px;
                        font-weight: 100;
                        line-height: normal;
                        color: $primaryColor;
                        opacity: 0.9;

                        span {
                            display: block;
                        }
                    }
                }

                .w-oneclick-connect {
                    #googleConnect {
                        margin: 0 24px;
                        background: $white;
                    }

                    .w-social-btn {
                        background-color: $white;
                        border: none;

                        >div {
                            width: 90%;
                            height: 90%;

                            &.fb-oneclick {
                                background-position: 75% 65%;
                            }

                            &.pp-oneclick {
                                background-position: 25% 65%;
                            }
                        }
                    }

                    .pp-connect {
                        order: 0;
                    }

                    #googleConnect {
                        order: 1;
                    }

                    .fb-connect {
                        order: 2;
                    }
                }

                .login_protection_donnees_msg {
                    display: flex !important;
                    justify-content: center;
                    align-items: center;

                    p {
                        font-family: $primaryFont;
                        font-size: 11px;
                        font-weight: 100;
                        color: $primaryColor;
                        margin-top: 30px;
                        opacity: .9;
                    }
                }

                &:last-of-type {
                    padding-top: 0;
                }
            }
        }
    }
}

.tablet .body_login {
    .newlogin_page {
        padding: 9.2rem 1rem 5rem;
    }
}

.body_login.en .wrapper_tunnel .newlogin_page .login_bloc .w-lines-group .optin_container.column>.w-form-line .w-line-label {
    width: 45%;
}

// FIN PAGE LOGIN

// PAGE MDP OUBLIÉ

#wrapper_moncompte {

    display: flex;
    justify-content: center;

    .bandeau_mdp_oublie {
        display: none;
    }

    .passwordForgoten {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        padding: 2.2rem 1rem;
        background: $white;
    }

    #clientForm {
        width: 95%;

        input {
            box-sizing: border-box;
        }

        .w-visibility-toggle {
            border: none;
        }
    }
}