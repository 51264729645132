/* Contact */
.contact_faq {
    .main_wrapper {
        .contactForm {
            .contactForm_content {
                display: flex;
                justify-content: space-between;
                width: 100%;

                @media screen and (max-width: 1200px) {
                    width: 98%;
                }

                .contactForm_content_left {
                    width: 49%;

                    #block_img {
                        position: relative;
                        overflow: hidden;
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;

                        img {
                            width: auto;
                            height: 900px;
                        }
                    }
                }

                .contactForm_content_right {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 49%;
                    padding-left: 15px;

                    .content_right_text {
                        max-width: 500px;
                        width: 90%;
                        margin-bottom: 1rem;
                    }

                    .titre_contact {
                        font-family: $primaryFont;
                        font-weight: 700;
                        font-size: clamp(20px, fs-vw(30, 1920), 30px);
                        line-height: normal;
                        color: $primaryColor;
                        margin-bottom: 11px;
                    }

                    .sous_text {
                        font-family: $primaryFont;
                        font-weight: 100;
                        font-size: 15px;
                        color: $primaryColor;
                        line-height: normal;
                    }

                    .contact {
                        display: none;
                        margin-top: 1.4rem;

                        .email_contact,
                        .email_whatsapp {
                            font-family: $primaryFont;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 26px;
                            text-align: center;
                            letter-spacing: 0.05em;
                            text-decoration-line: underline;
                            text-transform: uppercase;
                            margin-top: 1rem;

                            @media screen and (max-width: 1237px) {
                                padding-bottom: 1rem;
                            }

                        }

                        .email_contact {
                            margin-right: 18px;

                            @media screen and (max-width: 1081px) {
                                max-width: 209px;
                            }
                        }

                        .email_whatsapp {
                            left: 226px;
                        }
                    }

                    .sous_text_subtitle {
                        display: block;
                    }

                    .content_right_form {
                        position: relative;
                        max-width: 500px;
                        width: 90%;

                        #contact_form {
                            #mes_err_contact {
                                .red {
                                    color: red;
                                    font-family: $primaryColor;
                                }
                            }

                            form {
                                max-width: 585px;
                                width: 100%;
                            }

                            input {
                                height: 50px;
                            }

                            #optinId {
                                .w-fieldset-label {
                                    font-family: $primaryFont;
                                    font-style: normal;
                                    font-weight: 300;
                                    font-size: 15px;
                                    line-height: 19px;
                                    color: $primaryColor;
                                }

                                .w-line-label {
                                    font-family: $primaryFont;
                                    font-style: normal;
                                    font-weight: 300;
                                    font-size: 15px;
                                    line-height: 19px;
                                    color: $primaryColor;
                                }

                                .w-input-label {
                                    span {
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 21px;
                                        color: $primaryColor;
                                    }
                                }

                                .w-radio-group.w-has-error .w-input-label span {
                                    color: red;
                                }
                            }

                            .w-textarea .w-input-element {
                                max-width: 559px;
                                height: 5.7em;
                                padding: 1.2em 1.15714em 1.5em;
                                border: 1px solid $primaryColor;
                            }

                            .w-textarea textarea.w-input-element+.w-input-label {
                                left: 10px;
                                top: -10px;
                            }

                            .w-input.w-textarea.w-nonempty .w-input-element {
                                padding: 1.5em 1.15714em 1.5em;
                            }

                            .w-form-line:nth-child(9) {
                                margin-bottom: 0;
                            }

                            .file-input-row {
                                margin: 5px auto 10px;

                                .ajout_pj_label {
                                    font-size: 13px;
                                    justify-content: flex-start;
                                }

                                label {
                                    width: 266px;
                                    height: 49px !important;
                                    border: 1px solid transparent !important;
                                    background-color: transparent !important;


                                    &::before {
                                        content: "+";
                                        padding-right: 3%;
                                    }
                                }
                            }

                            .w-form-line.w-submit {
                                max-width: 16.675rem;

                                .w-submit-button {
                                    height: 40px;
                                    width: 100%;
                                    max-width: 155px;
                                    font-size: 15px;
                                }
                            }

                            .uploadedFile {
                                font-family: $primaryFont;
                            }

                            .w-input.w-dropdown.w-nonempty .w-value {
                                font-family: $primaryFont;
                            }

                            .w-input-note.w-input-error {
                                top: 95%;
                            }

                            .input[type="text"],
                            input[type="email"],
                            textarea {
                                font-family: $primaryFont;
                            }

                            #message_mail {
                                font-family: $primaryFont;
                                color: $primaryColor;
                            }

                            .wrapperErrors {

                                #mes_err_contact,
                                #message_mail {
                                    font-family: $primaryFont;
                                }
                            }

                            .champs_requis {
                                display: none;
                            }

                            #recaptcha {
                                transform: scale(0.8);
                                position: relative;
                                top: -8px;
                                left: -60px;
                                filter: none;
                                -webkit-filter: blur(0);
                                -moz-filter: blur(0);
                                -ms-filter: blur(0);
                                margin-bottom: 7px;

                                @media screen and (max-width: 1024px) {
                                    left: -45px;
                                }

                                @media screen and (min-width: 1025px) and (max-width: 1200px) {
                                    left: -53px;
                                }
                            }
                        }
                    }
                }
            }

            .w-form.w-contact-form.w-login-form {
                .w-input.w-textarea {
                    display: flex;
                }

                .w-input.w-dropdown .w-input-element {
                    top: -8px;
                }

                .file-list .rowList {
                    margin-bottom: 10px;

                    .closePj {
                        background-color: transparent;
                    }
                }

                .rowList:last-child {
                    display: none;
                }
            }
        }


        .w-contact-form .file-list .rowList button.closePj:after,
        .w-contact-form .file-list .rowList button.closePj:before {
            height: 10px; // _app override 😡
            left: 5px; // _app override 😡
            top: 1px; // _app override 😡
        }
    }

    #contact_form form.w-contact-form {
        padding-bottom: 0.5rem;
    }

    #abox {
        display: none;
        align-items: flex-start;
        justify-content: center;
        text-align: center;
        width: 610px;
        height: 240px;
        padding: 50px 15px;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        transform: none;
        z-index: 120;

        .form_submit {
            max-width: 250px;
            margin: 2rem auto auto;

            button {
                width: 100%;
            }

            span {
                font-family: $primaryFont;
                font-weight: 400;
                font-size: 12px;
                letter-spacing: 1.8px;
                text-decoration: none;
                text-transform: uppercase;
            }
        }

        .txt_alert {
            font-family: $primaryFont;
            font-size: 16px;
            font-weight: 300;
            padding: 0;
        }
    }
}