.categ_link {
    display: flex;
    align-items: center;
    height: var(--headerRecalcHeight);
    text-align: center;
    cursor: pointer;
}

.categ_name span {
    @include fs-std-medium-title;
    position: relative;
    display: block;
    color: var(--hovercolor, $primaryColor);

    &::before {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 0;
        height: 1px;
        background-color: var(--hovercolor, $primaryColor);
        transition: width 0.2s ease-in;
    }
}

.subcat_link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    white-space: nowrap;

    span {
        @include fs-std-bold-subtitle;
        text-transform: uppercase;
        color: var(--color, $primaryColor);
    }
}

.object_link {
    @include fs-std-thin-subtitle;
    white-space: nowrap;

    &:hover {
        font-weight: $medium;
    }

    span {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        height: 25px;
        color: var(--color, $primaryColor);

        &::after {
            content: attr(data-text);
            height: 0;
            visibility: hidden;
            overflow: hidden;
            user-select: none;
            pointer-events: none;
            font-weight: $bold;

            @media speech {
                display: none;
            }
        }
    }
}

@media screen and(min-width: 1401px) {
    .headerContainer {
        .leftPart {
            display: flex;
            gap: 0;

            .menu_wrapper {
                padding: 0 15px;

                &:first-of-type {
                    padding: 0 15px 0 0;
                }

                &:last-of-type {
                    padding: 0 0 0 15px;
                }
            }
        }

        .menu_wrapper {
            &:hover {
                .categ_name span::before {
                    width: 25px;
                }

                .categ_container {
                    display: flex;
                    z-index: 100;
                    justify-content: space-between;
                    opacity: 1;
                    visibility: visible;
                    min-height: 262px;
                    max-height: 500px;
                    transition: initial; // set to initial open transition when hovering another menu item with categ container in menu

                    .categ_push_wrapper {
                        opacity: 1;
                        transition: opacity 0.2s ease;
                    }
                }
            }

            &.is_hovered {
                .categ_container {
                    border-top: 1px solid $greyBorder;
                    transition: initial; // set to initial close transition when hovering another menu item with categ container in menu

                }

                &:hover .categ_container {
                    transition: min-height 0.5s, max-height 1.5s, z-index 0.01s; // open transition when hovering menu item with categ container in menu for the first time
                }
            }
        }

        .categ_container {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            z-index: 99;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            height: fit-content;
            max-height: 0;
            min-height: 0;
            background-color: $secondaryColor;
            overflow: hidden;
            transition: border-color 0.02s, background-color 0.05s 0.75s, opacity 0.05s .75s, visibility 0.05s .75s, max-height 0.125s, min-height 0.375s, z-index 0.01s; // close transition when off menu or hovering menu item without categ container

            .categ_push_wrapper {
                display: flex;
                opacity: 0;
                width: 97.5%;
                max-width: 1400px;
                margin: 0 auto;
                transition: opacity 0.2s ease;

                &:has(.menu_img_push) {
                    max-width: 1400px;
                    margin: 0 auto;
                }

                .categ_wrapper {
                    &>li:last-of-type {
                        padding-right: 0;
                    }

                    &>li:first-of-type {
                        padding-left: 0;
                    }
                }

                .gap_2>li {
                    padding: 0 clamp(1px, fs-vw(44, 1920), 44px);
                }

                .gap_3>li {
                    padding: 0 clamp(1px, (#{fs-vw(130, 1920)}), 130px);
                }

                .gap_4>li {
                    padding: 0 clamp(1px, fs-vw(80, 1920), 80px);
                }

                .gap_5>li {
                    padding: 0 clamp(1px, fs-vw(80, 1920), 80px);
                }

                .gap_6>li {
                    padding: 0 clamp(1px, #{fs-vw(44, 1920)}, 44px);
                }

                .gap_7>li {
                    padding: 0 clamp(1px, fs-vw(44, 1920), 44px);
                }

                .gap_8>li {
                    padding: 0 clamp(1px, fs-vw(32, 1920), 32px);
                }

                .gap_9>li {
                    padding: 0 clamp(1px, fs-vw(32, 1920), 32px);
                }

                .gap_10>li {
                    padding: 0 clamp(1px, fs-vw(32, 1920), 32px);
                }

                .gap_11>li {
                    padding: 0 clamp(1px, fs-vw(32, 1920), 32px);
                }

                .gap_12>li {
                    padding: 0 clamp(1px, fs-vw(32, 1920), 32px);
                }
            }

            @media screen and(min-width: 1921px) {
                .categ_push_wrapper {
                    margin: 0 auto;
                }
            }

            .categ_wrapper {
                display: grid;
                grid-template-columns: repeat(var(--data-cols), auto);
                justify-content: flex-start;
                align-items: stretch;
                width: 100%;
                height: fit-content;
                margin: 0 auto;

                &>li {
                    width: max-content;
                    margin-top: 30px;
                    box-sizing: border-box;
                    margin-bottom: 23px;

                    &:not(:first-of-type) {
                        border-left: 1px solid $primaryColor;
                    }
                }

                .subcat_name {
                    &:has(+ .subcat_wrapper) {
                        margin-bottom: 15px;
                    }
                }

                .subcat_name,
                .subcat_wrapper {
                    width: fit-content;
                }

                &:not(:has(.subcat_wrapper)) .subcat_link {
                    &:hover {
                        font-weight: $bold;

                        span {
                            font-weight: $bold;
                        }
                    }

                    span {
                        justify-content: center;
                        height: 25px;
                        font-weight: $thin;
                        text-transform: unset;
                    }

                    h3,
                    span {
                        display: inline-flex;
                        flex-direction: column;

                        &::after {
                            content: attr(data-text);
                            position: initial;
                            bottom: unset;
                            display: initial;
                            width: 100%;
                            height: 0;
                            visibility: hidden;
                            overflow: hidden;
                            user-select: none;
                            pointer-events: none;
                            font-weight: $bold;

                            @media speech {
                                display: none;
                            }
                        }
                    }
                }
            }

            .menu_img_push {
                margin: 19px 0 19px 0;
            }
        }

        .block_logo_menu .backdropArea {
            display: none;
        }
    }
}

/****** FOR TABLET MENU ********/

.tablet_menu_wrapper,
#search_top_small {
    display: none;
}

@media screen and(max-width: 1400px) {
    #search_top_small {
        display: flex;
    }

    .block_top_links .backdropArea {
        display: none;
    }

    .block_logo_menu {
        width: 100%;
    }

    .headerLogo {
        position: absolute;
        left: 0;
        right: 0;
        margin-inline: auto;
    }

    #search_top {
        padding: 0;

        .trigger_recherche {
            display: none;
        }
    }

    .tablet_menu_wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 25px;
        height: 24px;
        cursor: pointer;

        .tablet_menu {
            display: block;
            width: 25px;
            height: 2px;
            background-color: $primaryColor;
            border-radius: 30px;

            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 0;
                width: 25px;
                height: 2px;
                background-color: $primaryColor;
                border-radius: 30px;
                transition: all .2s ease-out;
            }

            &::before {
                top: 4px;
            }

            &::after {
                bottom: 3px;
            }

            &.is-opened-menu {
                margin-top: 4px;
                background-color: transparent;

                &::before {
                    top: 50%;
                    transform: translateY(-50%) rotate(45deg);
                    background-color: $primaryColor;
                }

                &::after {
                    bottom: 50%;
                    transform: translateY(50%) rotate(-45deg);
                    background-color: $primaryColor;
                }
            }
        }
    }

    #appContainer:not(:has(.promo_bar)) {
        .menu_categ_wrapper {
            top: var(--headerRecalcHeight);
            height: calc(100vh - #{var(--headerRecalcHeight)});
        }
    }

    .sticky .menu_categ_wrapper {
        top: var(--headerRecalcHeightFull);
        height: calc(100vh - var(--headerRecalcHeightFull));

        .leftPart {
            max-height: calc(100vh - var(--headerRecalcHeightFull) - 60px);
        }
    }

    .menu_categ_wrapper {
        position: absolute;
        top: var(--headerFull);
        left: -100%;
        display: flex;
        height: calc(100vh - var(--headerFull));
        width: 350px;
        background: $secondaryColor;
        padding: 0 0 0 40px;
        transition: left 0.2s ease, opacity 0.2s ease;
        opacity: 0;

        .leftPart {
            max-height: calc(100vh - var(--headerFull) - 60px);
            width: 100%;
            overflow-y: auto;
            height: 100%;
            opacity: 0;
        }
    }

    .headerContainer:has(.is-opened-menu) .menu_categ_wrapper .leftPart {
        opacity: 1;
    }

    .headerContainer:has(.is-opened-menu) {
        .menu_categ_wrapper {
            left: 0;
            opacity: 1;
            z-index: 1;
        }
    }

    .menu_wrapper:hover {
        .categ_name h2::before {
            width: 100%;
        }
    }

    .categ_name span::before {
        left: 0;
        transform: unset;
    }

    .is_hovered .categ_name span::before {
        width: 100%;
    }

    .categ_link {
        height: 45px;
    }

    .subcat_name::before {
        content: '';
        display: block;
    }

    .subcat_link {
        height: 35px;
    }

    .lang_switch {
        flex-direction: row;

        &:hover {
            a+a {
                left: 29px;
                right: 0;
            }
        }

        a {
            display: block;
            padding-right: 5px;
            padding-left: 0;
        }

        +a {
            // right: 0;
            left: 0;
            padding-left: 5px;
            padding-right: 0;

        }
    }
}

//specific for tablet
.tablet .menu_img_push {
    display: none;
}