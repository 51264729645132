.ill_img {

    &:not(.imgLoaded) {
        background-size: auto 25%;
    }
}

.photo_product,
.cart_thumb_holder {
    width: 100px;
    height: 132px;

    img {
        display: block;
        width: 99px;
    }
}

.wrapper_product,
.product-link {
    display: flex;
}

.product_desc,
.formWrapper,
.cart_detail_box {
    position: relative;
    width: 100%;
    padding: 0 0 0 15px;
    box-sizing: border-box;

    &:not(:has(.sub_quantity)) {

        .color,
        .couleur,
        .sub_couleur {
            margin-top: 15px;
        }
    }

    .title,
    .titre,
    .sub_title {
        @include fs-std-bold-title;
        text-transform: capitalize;
    }

    .item_subtitle,
    .sstitre {
        display: none;
    }

    .price_tag {
        font-weight: $thin;
    }

    .sub_qte,
    .sub_quantity,
    .color,
    .couleur,
    .sub_couleur,
    .shoesize,
    .taille,
    .sub_taille {
        @include fs-sml-thin-subtitle;
    }

    .couleur {
        text-transform: lowercase;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    .sub_quantity {
        margin-top: 10px;
    }

    .report.error,
    .no_stock {
        @include fs-sml-thin-subtitle;
        color: $redError;
        margin-top: 7px;
    }
}

/****** RAYON GENERAL LAYOUT *******/
#list_item {
    display: flex;
    flex-wrap: wrap;
    column-gap: calc((15 / 1893) * 100%);
    row-gap: fs-vw(15, 1893);
    width: 99%;
    max-width: 1893px;
    margin: 0 auto 25px;

    .push img {
        width: 100%;
    }

    .video_container {
        position: relative;
        height: 0;
        width: 100%;
        padding-top: calc((616 / 462) * 100%);

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .push_weight_1 {
        width: calc((462 / 1893) * 100%);
    }

    .push_weight_2 {
        width: calc((939 / 1893) * 100%);

        .video_container {
            padding-top: calc((616 / 939) * 100%);
        }
    }

    &:has(.item_look) {
        // lookbook case
        width: 100%;
        max-width: 1920px;
        column-gap: calc((15 / 1920) * 100%);
    }
}

/*lookbook case*/
.category:has(.item_look) {
    #breadcrumbs {
        position: absolute;
        top: 120px;
        left: 0;
        z-index: 2;
        width: 100%;

        .breadcrumbs,
        .breadcrumbs a {
            color: $secondaryColor;
        }
    }

    .tag_tooltip .product_price .pricetag {
        @include price_style(20px, 100, $primaryColor);

        &::after {
            display: none;
        }

        & + .pricetag {
            position: relative;
            @include price_style(20px, 100, $greyLabel);

            &::before {
                border-top: 1px solid $greyLabel;
                position: absolute;
                content: "";
                right: 0;
                top: 58%;
                left: 0;
            }
        }
    }

}

.item_look_contents {
    position: relative;
    width: 100%;

    img {
        width: 100%;
    }
}

.item_look.look5 {
    width: calc((100% / 3) - ((10 / 1920) * 100%));
}

.productTag {
    position: absolute;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    z-index: 1;

    &>span {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: $primaryColor;
        border: 2px solid $secondaryColor;
        box-sizing: border-box;
        z-index: 3;
    }

    &:hover {
        z-index: 3;

        .tag_tooltip {
            opacity: 1;
        }
    }
}


.tag_tooltip {
    position: absolute;
    top: -15px;
    right: -18px;
    display: block;
    width: 250px;
    padding: 17px 20px 18px;
    background-color: $secondaryColor;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    color: $primaryColor;

    strong {
        @include fs-std-bold-title;
    }

    .product_price {
        display: flex;
        align-items: center;
        margin: 5px 0 12px;
        @include fs-std-thin-title;

        .pricetag.discount {
            order: 1;
            @include price_style(20px, 100, $primaryColor);
        }

        .pricetag:not(.discount) {
            order: 0;
            position: relative;
            white-space: nowrap;
            @include price_style(20px, 100, #9A9996);
            margin-right: 15px;

            &::after {
                border-top: 1px solid #9a9996;
                position: absolute;
                content: "";
                right: 0;
                top: 58%;
                left: 0;
            }
        }
    }

    .button.secondary {
        display: block;
        margin-top: 20px;
        width: fit-content;

        @include secondaryButton(left, 3);
    }
}

.right .tag_tooltip {
    top: -10px;
    left: -10px;
    right: unset;
}

/*end lookbook case*/

.item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: calc((462 / 1893) * 100%);

    &:hover {

        .productSizeFieldset:has(.size_list),
        .ill_img.imgLoaded:has(.rollover) {
            visibility: visible;
            opacity: 1;
            bottom: 99px;
        }
    }

    .eclat1,
    .eclat2 {
        position: absolute;
        top: 0;
        z-index: 3;

        img {
            width: auto;
            height: 30px;
        }
    }

    .eclat1 {
        left: 0;
    }

    .eclat2 {
        right: 0;
    }

    .imgWrapper {
        position: relative;
        width: 100%;
    }

    .block_lnk {
        position: relative;
        display: block;
        padding-top: calc(616 / 462 * 100%);
        z-index: 1;

        .swiper-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .imgProd {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            vertical-align: top;
            font-size: 0;
        }
    }

    .swiper-lazy {
        width: 100%;
    }

    .swiper-lazy-preloader {
        position: absolute;
        width: 100%;
        height: 0;
        padding-top: calc(616 / 462 * 100%);
        left: 0;
        top: 0;
        margin-left: 0;
        margin-top: 0;
        z-index: 1;
        transform-origin: none;
        transform: none;
        animation: none;
    }

    .ill_img {
        display: block !important; // _app override 😡
        position: absolute !important; // _app override 😡
        top: 0;
        left: 0;
        width: 100% !important; // _app override 😡
        height: 0 !important; // _app override 😡
        padding-top: calc(616 / 462 * 100%);
        opacity: 0;
        transition: opacity .3s ease-in-out;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            vertical-align: top;
        }

        &.imgLoaded {
            opacity: 1;

            &:has(.rollover) {
                opacity: 0;
            }
        }
    }

    &:has(.swiper-initialized):hover {

        .rollover_rayonsPrev,
        .rollover_rayonsNext {
            visibility: visible;
            transition: visibility .15s, opacity .3s, left .3s, right .3s;
        }

        .rollover_rayonsPrev {
            left: 15px;
        }

        .rollover_rayonsNext {
            right: 15px;
        }
    }

    .rollover_rayonsPrev,
    .rollover_rayonsNext {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        visibility: hidden;
        width: 15px;
        height: 15px;
        @include maskIconPath('../svg/spritesheet.svg');
        @include maskIconCoord(1, $initialColSize, $initialRowSize);
        background-color: $primaryColor;
        z-index: 3;
        transition: opacity .3s, left .3s, right .3s;
        transition: visibility .15s, opacity .3s, left .3s, right .3s;

        &.swiper-button-disabled {
            opacity: 0.5;
        }

    }

    .rollover_rayonsPrev {
        left: -15px;
        transform: rotate(90deg);
    }

    .rollover_rayonsNext {
        right: -15px;
        transform: rotate(-90deg);
    }

    .productVisualMulti {
        width: 100%;
        overflow: hidden;
    }

    .wrapper_description {
        position: relative;
        display: block;
        flex-wrap: wrap;
        text-align: center;
        width: 100%;
        height: 100px;
        background-color: $secondaryColor;
        z-index: 2;
    }

    .title_content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .item_title {
            @include fs-std-bold-title;
            width: 100%;
            margin-top: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            text-transform: capitalize;

            .item_title {
                margin-top: 0;
            }
        }

        .item_subtitle {
            display: none;
        }
    }

    .item_price {
        display: flex;
        gap: 10px;
        margin-top: 8px;
        @include fs-std-thin-title;
        justify-content: center;

        .new-price {
            order: 1;
            visibility: visible;
        }

        &:has(.new-price) .pricetag:not(.new-price) {
            position: relative;
            color: $greyLabel;
            visibility: visible;

            &::after {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 1px;
                left: 0;
                top: 57%;
                background-color: $greyLabel;
                transform: translateY(-50%);
            }
        }

        &:has(.pricetag + .pricetag) {
            .pricetag:not(.new-price)+.pricetag {
                position: relative;
                order: -1;

                &::after {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 1px;
                    left: 0;
                    top: 50%;
                    background-color: $greyLabel;
                    transform: translateY(-50%);
                }

                .price {
                    color: $greyLabel;
                }
            }
        }
    }

    .wrap_rolloverproduit {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 37px;
        background-color: $secondaryColor;

        form {
            width: 100%;
        }
    }

    .titre_choices_list {
        display: none;
    }

    .right_element_prod {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;

        &.size_list {
            padding: 5px 10px;
        }
    }

    .choices_list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: fs-vw(10, 1920);
        width: 100%;

        .size,
        .color {
            transition: border-color .3s;

            &:hover {
                border-color: $primaryColor;
            }

            input {
                display: none;
            }
        }

        .size {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 28px;
            height: 28px;
            background-color: $secondaryColor;
            cursor: pointer;
            border: 1px solid $secondaryColor;

            &:has(.disabled) {
                opacity: .3;
            }

            label {
                @include fs-std-thin-text;

                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                margin-top: -3px;
                cursor: pointer;
            }
        }

        .color {
            margin-top: 12px;
            width: 23px;
            height: 23px;
            border-radius: 50%;
            border: 1px solid $secondaryColor;
            cursor: pointer;
            box-sizing: content-box;

            &:has(input:checked) {
                border-color: $primaryColor;
            }

            label {
                display: block;
                width: 17px;
                height: 17px;
                background-color: var(data-hex);
                background-position: center;
                border-radius: 50%;
                border: 3px solid $secondaryColor;
                cursor: pointer;
                box-sizing: content-box;

            }

            label[title="Blanc"],
            label[title="BLANC"] {
                border-color: $tertiaryColor;
            }

            &.color_selected {
                label[title="BLANC"] {
                    border-color: $secondaryColor;
                }
            }
        }

        .remaining_colors {
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 17px;
            height: 17px;
            font-size: 0;
            margin-top: 12px;
            box-sizing: border-box;

            &::after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                width: 100%;
                height: 16px;
                background-image: url("../svg/more_color.svg");
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }

    .rollover_left {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .productSizeFieldset:has(.size_list) {
        position: absolute;
        bottom: 0px;
        left: 0;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: fit-content;
        min-height: 100px;
        width: 100%;
        opacity: 0;
        visibility: hidden;

        transition: bottom 0.6s, visibility .3s;

        background-color: rgba($tertiaryColor, .95);
    }

    .productColorFieldset {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 37px;
        background-color: $secondaryColor;
        z-index: 2;
    }

    .bloc_add_color,
    .form_submit.bloc_add_color {
        display: none !important; //override js app
    }
}

#search_results,
.category,
.tg_module {
    .btnAddBasketWrapper {
        position: absolute;
        bottom: 99px;
        left: 0;
        z-index: 2;
        width: 100%;
        padding: 20px 0 15px;
        background-color: rgba($tertiaryColor, .95);

        #size_is_not_available {
            @include fs-std-bold-title;
            margin: 12px auto;
            text-align: center;
        }

        .alert_stock {
            @include fs-std-bold-title;
            text-align: center;
            margin-bottom: 12px;
        }

        .alert_return {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 12px;
            height: 12px;
            @include maskIconPath("../svg/spritesheet.svg");
            @include maskIconCoord(24, $initialColSize, $initialRowSize);
            background-color: $primaryColor;
            cursor: pointer;

            span {
                font-size: 0;
            }
        }

        .w-form-line {
            width: 95%;
            max-width: 350px;
            margin: 0 auto;
        }

        .form_submit {
            position: absolute;
            right: 0;

            button {
                @include fs-std-medium-text;
                background: unset;
                border: none;
                padding: 0;
                cursor: pointer;
                background-color: rgba($tertiaryColor, .95);
            }
        }

        .bloc_add_alert_confirmation {
            @include fs-sml-thin-text;
            text-align: center;
        }
    }
}