// MODULES HOME ADV

.home-module {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
}

.home-module-main-title {
    width: 100%;
    font-size: clamp(22px, 1.458vw, 28px);
    font-weight: 800;
    letter-spacing: .2em;
    text-align: center;
}

.home_module.text,
.home_text {
    font-weight: 100;
    line-height: normal;
}

/**
 * MODULE 1 Cover module
 */
.home_module.cover_module {
    position: relative;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto 14px;

    @media (min-width: 1024px) and (max-width: 1919px) {
        margin-bottom: 7px;
    }

    .swiper-slide {
        img {
            width: 100%;
            height: auto;
            vertical-align: top;
            opacity: 1;
            transform: scale(1);
            filter: blur(0px);
            transition: all .4s ease-in-out;

            &.pre-load {
                opacity: 0;
                transform: scale(1.1);
                filter: blur(5px);
                transition: all .4s ease-in-out;
            }
        }



        .slide_video {
            @include slide-video(960, 1920);

            a {
                display: block;
                padding-bottom: calc(920 / 1920* 100%);
            }

            a.container_video {
                iframe {
                    position: unset;
                    transform: none;
                }
            }

            .block_redirect {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: calc(100% - 14cqh);
            }
        }
    }

    .home_module_contents {
        position: absolute;
        padding: 34px 30px;
        box-sizing: border-box;
        min-width: 26%;
        color: $white;
        font-family: $primaryFont;
        font-size: clamp(20px, fs-vw(50, 1920), 50px);
        line-height: 1;

        &.align-left {
            left: 6%;
        }

        &.align-center {
            left: 50%;
            transform: translateX(-50%);
        }

        &.align-right {
            right: 6%;
        }

        &.align-top {
            top: 8%;
        }

        &.align-middle {
            top: 50%;
            transform: translateY(-50%);
        }

        &.align-bottom {
            bottom: 8%;
        }

        &.align-center.align-middle {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .home_module_pretitle {
        position: relative;
        font-size: clamp(16px, 1.042vw, 20px);
        font-weight: 200;
        letter-spacing: 0.2em;
        margin-bottom: .3em;
    }

    .home-module-title {
        position: relative;
        font-family: $primaryFont;
        font-size: clamp(32px, 2.083vw, 40px);
        font-weight: 700;
        letter-spacing: 0.05em;
        margin-bottom: .6em;
        padding-bottom: .5em;

        &:after {
            content: '';
            display: block;
            width: 150px;
            height: 3px;
            background-color: $primaryColor;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    .home-module-cta {
        color: #fff;
    }

    .cover-pagination {
        position: absolute;
        z-index: 1;
        bottom: 65px;
        text-align: center;

        .swiper-pagination-bullet {
            background: $white;
            opacity: 1;

            &.swiper-pagination-bullet-active {
                background: transparent;
                border: 1px solid $tertiaryColor;
            }
        }
    }
}


/**
 * MODULE 2 MODULES 3 IMAGES HORIZONTALES
 */

.home_module.three_pictures {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1920px;
    margin-bottom: 12px;

    @media (min-width: 1024px) and (max-width: 1919px) {
        margin-bottom: 6px;
        justify-content: normal;
        column-gap: 8px;
    }
      
    .home-column {
        position: relative;
        width: 33%;
        max-width: 630px;
        height: 0;
        padding-bottom: calc(693 / 630 * 33%);
        overflow: hidden;

        @media (min-width: 720px) and (max-width: 1280px) {
            width: 32.9%;
        }

        img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: width .3s ease-in-out;
        }

        .home_title {
            opacity: 0;
            transition: opacity .3s ease-in-out;
        }

        &:hover {
            img {
                width: 105%;
                filter: brightness(.8);
            }

            .home_title {
                opacity: 1;
            }
        }
    }

    .home_title {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 50%;
        margin: auto;
    }

    .slide_video {
        @include slide-video(393, 357);
    }
}

/**
 * MODULE 3 MODULE 2 IMAGES
 */

.home_module.two_picture {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1920px;
    margin-bottom: 12px;

    @media (min-width: 1024px) and (max-width: 1919px) {
        margin-bottom: 6px;
        justify-content: normal;
        column-gap: 8px;
    }

    .home-column {
        position: relative;
        width: 49.8%;
        max-width: 952px;
        height: 0;
        padding-bottom: calc(1048 / 952 * 49.8%);
        overflow: hidden;

        @media (min-width: 720px) and (max-width: 1280px) {
            width: 49.7%;
        }

        img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: width .3s ease-in-out;
        }

        .home_title {
            opacity: 0;
            transition: opacity .3s ease-in-out;
        }

        &:hover {
            img {
                width: 105%;
                filter: brightness(.8);
            }

            .home_title {
                opacity: 1;
            }
        }
    }

    .home_title {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 50%;
        margin: auto;
    }

    .slide_video {
        @include slide-video(393, 357);
    }
}


/**
 * MODULE 4 –PUSH CATEGORIES 2 IMAGES
 */

.home_module.double_image_image {
    display: flex;
    justify-content: space-between;
    max-width: 1920px;
    margin-bottom: 14px;

    @media (min-width: 1024px) and (max-width: 1919px) {
        margin-bottom: 7px;
        column-gap: 8px;
        justify-content: normal;
    }

    .main_column {
        position: relative;
        display: flex;
        align-items: stretch;
        width: 66.5%;
        height: 0;
        max-width: 1275px;
        padding-bottom: calc(1400 / 1275 * 66.5%);
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: width .3s ease-in-out;
        }

        .home_title {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 50%;
            margin: auto;
            opacity: 0;
            transition: opacity .3s ease-in-out;
        }

        &:hover {
            img {
                width: 105%;
                filter: brightness(.8);
            }

            .home_title {
                opacity: 1;
            }
        }
    }

    .second_column_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 32.8%;
        max-width: 630px;

        @media (min-width: 1024px) and (max-width: 1919px) {
            justify-content: center;
            row-gap: 7px;
        }

        .home-column {
            position: relative;
            display: flex;
            padding-bottom: calc(871 / 259* 32.8%);
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: width .3s ease-in-out;
            }

            .home_title {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                height: 50%;
                margin: auto;
                opacity: 0;
            }

            &:hover {
                img {
                    width: 105%;
                    filter: brightness(.8);
                }

                .home_title {
                    opacity: 1;
                }
            }
        }
    }

    .slide_video {
        @include slide-video(650, 510);
    }
}



/**
 * MODULE 5 – BLOC TEXTE
 */

.home_module.text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1920px;
    padding: 6rem 0;
    box-sizing: border-box;
    margin-bottom: 16px;

    @media (min-width: 1024px) and (max-width: 1919px) {
        margin-bottom: 8px;
    }

    div {
        max-width: 477px;
    }

}


/**
 * MODULE 6 - IMAGE FULL
 */

.home_module.full_picture {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1920px;
    margin-bottom: 16px;

    @media (min-width: 1024px) and (max-width: 1919px) {
        margin-bottom: 8px;
    }

    img {
        display: flex;
        width: 100%;
        height: auto;
    }

    .slide_video {
        @include slide-video(1080, 1920);
    }
}

/**
 * MODULE 7 –IMAGE DUO + TEXTE
 */



.home_module.two_pictures_text {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1920px;
    margin-bottom: 16px;

    @media (min-width: 1024px) and (max-width: 1919px) {
        margin-bottom: 8px;
    }

    .home_pictures_container {
        display: flex;
        max-width: 1020px;
        width: 100%;
        margin: 6rem auto 0;
    }

    .home_column {
        width: 50%;

        img {
            width: 100%;
            height: auto;
        }
    }

    .home_text {
        display: flex;
        flex-direction: column;
        width: 80%;
        max-width: 428px;
        margin: 4rem auto;
    }

    .slide_video {
        @include slide-video(581, 455);
    }
}


/**
 * MODULE 8 –IMAGE ½ + IMAGE TEXTE
 */

.home_module.pictures_text {
    display: flex;
    max-width: 1920px;
    margin-bottom: 16px;

    @media (min-width: 1024px) and (max-width: 1919px) {
        margin-bottom: 8px;
    }

    .home_column {
        width: 50%;

        img {
            display: flex;
            width: 100%;
            height: auto;
        }
    }

    .second_column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (max-width: 1100px) {
            img {
                width: 50% !important;
                margin: auto;
            }
        }

        @media (max-width: 850px) {
            img {
                width: 40% !important;
                margin: auto;
            }
        }

        @media (max-width: 1460px) {
            img {
                width: 70%;
                margin: auto;
            }
        }

        .home_text {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 320px;
            margin-top: 3rem;
        }
    }

    .slide_video {
        @include slide-video(412, 715);
    }
}

.tablet .home_module.pictures_text {

    .home_column.second_column {
        img {
            width: 45%;
            height: auto;
            margin: auto;
        }
    }
}


/**
 * MODULE 9–TÊTE DE GONDOLE
 */

.home_module.tg_module {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 1655px;
    width: 98%;
    margin: 3rem auto;
    padding: 0 25px;
    box-sizing: border-box;

    .home_title {
        @include fs-big-bold-title;
        margin-bottom: 1rem;
    }

    .main-swiper {
        padding-bottom: 50px;
    }

    .swiper-container {
        width: 100%;
    }

    .slide_video {
        @include slide-video(412, 715);
    }

    .tg-module-next,
    .tg-module-prev {
        position: absolute;
        top: 45%;
        transform: translateY(-50%);
        display: block;
        width: 15px;
        height: 15px;
        @include maskIconPath('../svg/spritesheet.svg');
        @include maskIconCoord(1, $initialColSize, $initialRowSize);
        background-color: $primaryColor;
        z-index: 3;
        cursor: pointer;

        &.swiper-button-disabled {
            opacity: 0.5;
        }
    }

    .tg-module-prev {
        left: 0;
        transform: rotate(90deg);
    }

    .tg-module-next {
        right: 0;
        transform: rotate(-90deg);
    }

    .swiper-pagination-bullet {
        background: $bulletsColor;
    }

    .swiper-pagination-bullet-active {
        background: $white;
        border: 1px solid $primaryColor;
    }

    .item_price:has(.pricetag+.pricetag) .pricetag:not(.new-price)+.pricetag {
        position: relative;
        white-space: nowrap;
        @include price_style(16px, 100, #9A9996);
        margin-right: 15px;

        &::after {
            border-top: 1px solid #9a9996;
            position: absolute;
            content: "";
            right: 0;
            top: 56%;
            left: 0;
        }
    }

}


/**
 * MODULE 10 – SIGNUP EMAIL
 */

.home_module.signup_picture_module {
    display: flex;

    img {
        width: 100%;
        height: auto;
    }

    .home_column {
        width: 50%;
    }

    .home_column:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;

        .home_column_container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            max-width: 421px;
            width: 44%;

            .home_title {
                @include fs-big-bold-title;
                margin-bottom: 1rem;
            }

            .home_text {
                @include fs-sml-thin-subtitle;
                margin-bottom: 1rem;
            }

            .w-newsletter-form .w-form-line {
                margin-bottom: 15px;
            }

            .w-newsletter-form .w-form-line.w-submit {
                left: 0;
            }

            .w-btn-wrapper {
                position: relative;
            }

            .w-newsletter-form .w-form-line.w-submit .w-submit-button {

                display: inline-flex;
                padding: 15px 50px;
                justify-content: center;
                align-items: center;
                border: 1px solid $primaryColor;
                background: $primaryColor;
                color: $white;

                span {
                    font-family: $primaryFont;
                    font-weight: 500;
                }

                &.w-loader {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 44px;
                    font-size: 0;

                    span {
                        display: none;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: 100%;
                        background: $white ;
                        mask-image: url(../svg/three_dots.svg);
                        mask-repeat: no-repeat;
                        mask-position: center;
                        mask-size: 25%;
                        border: 1px solid $primaryColor;
                    }

                    &.loading {
                        z-index: 1;
                    }
                }



                &:hover {

                    background: $white;
                    color: $primaryColor;

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}

// END MODULES HOME ADV