.cart {

    .tunnel_track,
    .nb_products,
    // .pricetag.was,
    .cart_product_brand,
    .dragOverlay,
    .nb_products {
        display: none;
    }

    @media screen and (max-width: 1280px) {
        .tunnel_track_pusher {
            margin: 4rem 0 57px;

            .tunnel_track {
                margin-left: -40px;
            }

            .cart_logo {
                display: none;
            }
        }
    }

    .wrapper_panier {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        max-width: 1300px;
        width: 80%;
        margin: auto;

        // Exception for smaller tablets under 1024px
        @media (max-width: 1023px) {
            width: 95%;
        }
    }

    .left_side {
        width: 64%;
        max-width: 810px;
    }

    .right_side {
        display: flex;
        flex-direction: column;
        width: 34%;
        max-width: 425px;
    }

    // PANIER


    &.step_1 {

        .tunnel_track_pusher {
            display: none;
        }

        .wrapper_panier {
            justify-content: flex-start;

            .right_side {
                margin-left: 45px;

                /* Cart share block */
                .share_cart {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 15px;
                    border: 1px solid $primaryColor;
                    padding: 0 22px;
                    height: 45px;

                    .share_cart_header {
                        @include fs-sml-thin-subtitle;
                        margin-right: auto;
                        font-weight: 300;
                        line-height: 45px;
                        letter-spacing: 0.05em;
                        text-transform: uppercase;
                    }

                    .share_cart_icon {
                        display: block;
                        margin-left: 17px;
                        border: none;
                        border-radius: 50%;
                        background-color: $tertiaryColor;
                        width: 28px;
                        height: 28px;

                        &:hover {
                            cursor: pointer;
                        }

                        &:active {
                            transform: translateY(1px);
                        }

                        &.share_cart_copy {
                            @include bgIconPath('../svg/copy.svg');
                            background-position: center;
                            background-size: auto;
                            background-repeat: no-repeat;
                        }
                    }
                }
            }

            &.emptyBasket {
                max-width: 1680px;
                width: 100%;

                @media screen and (max-width: 1669px) {
                    margin: 0 7.2%;
                    max-width: unset;
                    width: auto;
                }

                .left_side.elem_tunnel {
                    max-width: unset;
                    width: 100%;

                    h1.cart_main_title.basket,
                    .cart_main_table.basket.dropper {
                        margin-left: 12.5rem;
                    }

                    .frz_nocache {
                        margin-top: 2.1rem;
                    }
                }

                .right_side {
                    width: 0;
                    margin: 0;
                }

                #gondole_derniers_articles_vus {
                    .swiper-button-prev,
                    .swiper-button-next {
                        top: 43%;
                    }

                    .wrap_rolloverproduit {
                        .right_element_prod {
                            .remaining_colors_link {
                                .remaining_colors {
                                    margin: 0 0 .2rem 0;
                                }
                            }
                        }
                    }
                }

                .wishlist  {
                    margin-left: 2.3rem;

                    &.dropper {
                        justify-content: unset;
                        gap: 2rem;
                    }
                }
            }
        }

        // SWIPER TG CART

        #cart_slider_wrapper {
            position: relative;
            width: 92%;
            max-width: 1685px;
            margin: 60px auto 30px;
            padding: 0 40px;
            box-sizing: border-box;

            #cart_slider_container {
                opacity: 0;
                overflow: hidden;
            }

            #cart_slider {
                width: 100%;
                padding-bottom: 75px;
            }

            .full_w_sldr_title {
                width: 100%;
                @include fs-big-bold-title;
                margin-bottom: 15px;
            }

            .swiper-button-next,
            .swiper-button-prev {
                position: absolute;
                display: block !important;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                width: 20px;
                height: 20px;
                @include maskIconPath('../svg/spritesheet.svg');
                @include maskIconCoord(1, $initialColSize, $initialRowSize);
                background-color: $primaryColor;
                z-index: 3;
                cursor: pointer;

                &.swiper-button-disabled {
                    display: none;
                }

                &::after {
                    display: none;
                }
            }

            .swiper-button-prev {
                left: 0;
                transform: rotate(90deg);
            }

            .swiper-button-next {
                right: 0;
                transform: rotate(-90deg);
            }

            .swiper-pagination-bullet {
                background: $bulletsColor;
            }

            .swiper-pagination-bullet-active {
                background: $white;
                border: 1px solid $primaryColor;
            }

            .ecalt_rect {
                z-index: 1;
                position: absolute;
                bottom: -17px;
                left: 0;
            }

            .ecalt_rond {
                z-index: 1;
                position: absolute;
                top: -16px;
                left: 0;
            }

            .productVisualMulti {
                .swiper-slide {
                    width: 100% !important;
                }
            }

            .item .title_content .item_title {
                text-align: center;
            }
        }

        .left_side {
            display: flex;
            flex-direction: column;

            .cart_main_title.wishlist {
                @include fs-big-bold-title;
                font-size: 22px;
                line-height: normal;
                margin-bottom: 3px;
            }

            .wishlist_persis_info {
                width: 100%;
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 11px;
                font-weight: 100;
                line-height: normal;
            }

            .wishlist.dropper {
                margin-bottom: 3.3rem;
            }

            #cart_slider_wrapper {
                order: 3;
            }

            .cart_main_title.basket {
                @include fs-big-bold-title;
                line-height: 1;
                margin-bottom: 5px;
            }

            .basket_contents {
                padding-bottom: 28px;
                margin-bottom: 24px;
                border-bottom: 1px solid $primaryColor;
            }

            .dropdown_cart {
                h3 {
                    display: none;
                }

                .precoDeliveryDate {
                    @include fs-std-thin-text;
                }
            }

            .precoBasket {
                .precoBasketIntro {
                    display: none;
                }
            }

            .precoSubtitle {
                display: none;
            }

            .dropdown_cart.activPreorder {
                .dropdown_cart_link.active {
                    position: relative;
                    display: flex;
                    justify-content: flex-start;
                }

                h3 {
                    position: relative;
                    display: block;
                    @include fs-std-thin-text;
                    font-size: 12px;
                    font-weight: 700;
                    padding-right: 10px;
                    margin-right: 10px;
                    box-sizing: border-box;

                    &::after {
                        position: absolute;
                        top: -1px;
                        right: 0;
                        content: "|";
                        display: block;
                        width: 1px;
                        height: auto;
                        font-size: 13px;
                        font-weight: 100;
                        color: $primaryColor;
                    }
                }

                .precoSubtitle {
                    display: flex;
                    align-items: center;
                    @include fs-std-thin-text;
                    font-size: 11px;
                }

                .precoDeliveryDate {
                    position: absolute;
                    @include fs-std-thin-text;
                    font-size: 11px;
                    right: 0;
                }
            }

            .product_line_basket {
                position: relative;
                display: flex;
                padding-top: 15px;

                &:not(:last-of-type) {
                    margin-bottom: 5px
                }

                &.out_of_stock {
                    padding-top: 45px !important;

                    .cart_product_pic,
                    .wrap_titre_prix,
                    .cart_product_sizecol,
                    .toggle_modal_actions {
                        opacity: .5;
                    }

                    .product_towish::after,
                    .product_del,
                    .product_quantity_change {
                        display: none;
                    }

                    .cart_product_nostock {
                        height: 8px;
                    }

                    .product_nostock_label {
                        position: absolute;
                        top: 49px;
                        right: 100px;
                        display: flex;
                        align-items: center;

                    }
                }

                .cart_product_pic {
                    width: 26%;
                    max-width: 99px;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .wrapper_cart_product_price {
                    width: auto!important;
                }
            }

            .product_sfs_warning_txt {
                @include fs-std-thin-text;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 40px;
                background-color: $tertiaryColor;
                padding-left: 28px;
                opacity: 0.5;
            }

            .cart_product_desc {
                position: relative;
                width: 100%;
                padding: 0 26px 0 15px;
                box-sizing: border-box;


                .wrapper_cart_product_desc {
                    display: flex;
                    flex-direction: column
                }

                .cart_product_title {
                    @include fs-std-bold-title;
                    line-height: 1;
                    text-transform: capitalize;
                    margin: 7px 0 0;
                }

                .cart_product_subtitle {
                    display: none
                }

                .wrap_titre_prix {
                    order: 0;
                    @include fs-std-thin-title;
                }

                .product_nostock_label,
                .tunnel_wish_nostock {
                    @include fs-std-bold-title;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                }

                .cart_product_sizecol {
                    @include fs-sml-thin-subtitle;

                    &.quantity {
                        order: 1;
                        margin-top: 10px;

                        label {
                            display: flex;
                        }

                        span {
                            //style inline
                            display: block !important;
                        }
                    }

                    &.color {
                        order: 2;
                    }

                    &.size {
                        order: 3;
                        margin-bottom: 6px;
                    }
                }
            }

            .toggle_modal_actions {
                order: 5;
                @include fs-std-medium-subtitle;
                font-size: 15px;
                line-height: 1;
                margin-top: 6px;
                cursor: pointer;
                @include secondaryButton(left, 3);
            }

            .cart_item_actions {
                order: 6;
                display: none;
                position: relative;
                left: -114px;
                margin-top: 30px;
                background-color: $tertiaryColor;
                padding: 20px;
                width: calc(100% + 100px);
            }

            .productSizeFieldset .choices_list {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                width: 100%;

                .form_itm {
                    position: relative;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    font-family: $primaryFont;
                    font-size: 11px;
                    font-weight: 100;
                    line-height: normal;
                    color: $primaryColor;
                    line-height: 0;
                    cursor: pointer;
                    box-sizing: border-box;

                    &.selected {
                        border: 1px solid $primaryColor;
                    }

                    &:hover {
                        border: 1px solid $primaryColor;
                    }

                    &.disabled {
                        opacity: .3;
                    }
                }

                .input_check {
                    position: absolute;
                    width: 100%;
                    left: 0;
                    top: 0;
                    opacity: 0;
                }

                label {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }

                span {
                    display: block;
                    width: 100%;
                    cursor: pointer;
                    text-align: center;
                }
            }

            #cart_slider {
                .choices_list .form_itm {
                    width: 23px;
                    height: 23px;
                }
            }

            .cart_product_modify {
                position: absolute;
                top: 51px;
                right: -2px;
                display: flex;
                align-items: center;

                .product_towish {
                    order: 1;
                    margin: 0 60px 0 26px;
                    position: relative;

                    span {
                        font-size: 0;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: -30px;
                        width: 1px;
                        height: 100%;
                        background-color: $primaryColor;
                        opacity: .5;
                    }

                    // SW-15798 : NO NO NO NO NO !!!!!
                    /* &::before {
                        background-position: 40% 60%;
                    } */

                    &:hover {
                        &::before {
                            @include maskIconPath('../svg/spritesheet.svg');
                            @include maskIconCoord(21, $initialColSize, $initialRowSize);
                            background-color: $primaryColor;
                        }
                    }
                }

                .product_del {
                    order: 2;

                    span {
                        font-size: 0;
                    }

                    &.cross::before {
                        @include maskIconCoord(24, $initialColSize, $initialRowSize);
                        width: 11px;
                        height: 11px;
                    }
                }

                .product_quantity_change {
                    order: 0;

                    .qteWrapper {
                        position: relative;
                        display: flex;
                        align-items: center;
                        max-width: 82px;
                        width: 100%;
                        height: 30px;
                        padding: 0 5px;
                        border: 1px solid rgba(61, 69, 87, .5);
                        box-sizing: border-box;
                    }

                    .change_qte {
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: none;
                        background: transparent;
                        cursor: pointer;

                        &.lessQte {
                            left: 2px;
                            top: 16%;
                        }

                        &.moreQte {
                            right: 2px;
                            top: 16%;
                        }

                        &.selector_disabled {
                            opacity: .3;
                        }

                        span {
                            display: none;
                        }
                    }

                    .selected_qte {
                        width: 66px;
                        text-align: center;
                        background: transparent;
                        border: none;
                    }

                    .lessQte::before {
                        content: "-";
                        display: block;
                        @include fs-sml-thin-subtitle;
                        font-size: 20px;
                        margin-bottom: 5px;
                    }

                    .moreQte::before {
                        content: "+";
                        display: block;
                        @include fs-sml-thin-subtitle;
                        font-size: 20px;
                        margin-bottom: 5px;
                    }

                    .qte_error_msg {
                        font-size: 11px;
                    }
                }
            }

            .empty_cart {
                @include fs-std-thin-text;
                margin-bottom: 30px;
            }

            .cart_main_title.wishlist {
                span {
                    @include fs-nrml-bold-title;
                }
            }

            .cart_main_table.wishlist {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                @media (max-width: 1024px) {
                    flex-direction: column;
                }

                .wrap_titre_prix {
                    display: flex;
                    flex-direction: column;

                    .wrapper_cart_product_price {
                        width: 175px !important;

                    .pricetag.is_discounted {
                            background-color: transparent;
                            text-decoration: line-through;

                            &::before {
                                display: none;
                            }
                        }
                    }
                }

                .cart_product_line {
                    position: relative;
                    display: grid;
                    width: 48%;
                    max-width: 380px;
                    padding-bottom: 3rem;
                    margin-top: 15px;
                    grid-template-rows: repeat(3, auto);
                    grid-template-columns: 90px auto;

                    &.draggableElem {
                        width: 48%;
                        max-width: 380px;
                    }

                    @media (max-width: 1024px) {
                        width: 100%;
                        max-width: 100%;
                    }

                    form {
                        grid-row: 2;
                        grid-column: 1 / 3;

                        .btnAddBasketWrapper.expressAlertStock {
                            background-color: rgba(240,238,233,.95);
                            display: none;
                            width: 100%;
                            padding: 20px 0 15px;
                            margin-top: 3px;

                            .alert_stock {
                                font-family: $primaryFont;
                                font-size: 16px;
                                font-weight: 700;
                                line-height: normal;
                                color: $primaryColor;
                                text-align: center;
                                margin-bottom: 12px;
                            }

                            .bloc_add_alert_form {
                                .w-form-line {
                                    width: 95%;
                                    max-width: 350px;
                                    margin: 0 auto;
                                }
                            }

                            .bloc_add_alert_erreur {
                                display: none;
                            }

                            .alert_return {
                                position: absolute;
                                right: 10px;
                                top: 135px;
                                cursor: pointer;
                                width: 11px;
                                height: 11px;
                                -webkit-mask-image: url(../svg/spritesheet.svg);
                                mask-image: url(../svg/spritesheet.svg);
                                -webkit-mask-repeat: no-repeat;
                                mask-repeat: no-repeat;
                                -webkit-mask-size: 600% auto;
                                mask-size: 600% auto;
                                -webkit-mask-position: 100% 60%;
                                mask-position: 100% 60%;
                                background-color: $primaryColor;

                                span {
                                    display: none;
                                }
                            }
                        }

                        .bloc_add_alert_form {
                            .form_submit {
                                position: absolute;
                                right: 0;

                                button {
                                    font-family: $primaryFont;
                                    font-size: 15px;
                                    font-weight: 500;
                                    line-height: normal;
                                    color: $primaryColor;
                                    background: unset;
                                    border: none;
                                    padding: 0;
                                    cursor: pointer;
                                }
                            }
                        }

                        .warn_availability {
                            @include fs-std-medium-text;
                            display: flex !important;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            margin-top: 3px;
                            border: 1px solid $tertiaryColor;
                            cursor: pointer;
                            transition: background-color .3s, border-color .3s;
                            z-index: none;
                            color: $white;

                            &:hover {
                                background-color: $secondaryColor;
                                border-color: $primaryColor;
                                color: $primaryColor;
                            }
                        }

                        .warn_availability.none-important {
                            display: none !important;
                        }

                        .bloc_add_alert_confirmation {
                            font-family: $primaryFont;
                            font-size: 12px;
                            font-weight: 100;
                            line-height: normal;
                            color: $primaryColor;
                            text-align: center;
                            margin: 0 10px;
                        }

                        .noSizeAvailable {
                            display: flex !important;
                            align-items: center;
                            justify-content: center;
                            height: 40px;
                            background-color: $primaryColor;
                            border: 1px solid $primaryColor;
                            font-family: $primaryFont;
                            font-size: 15px;
                            font-weight: 500;
                            font-style: normal;
                            line-height: 1;
                            color: $white;
                            box-sizing: border-box;
                            cursor: not-allowed;
                            grid-row: 2;
                            grid-column: 1 / 3;
                        }
                    }
                }

                .cart_product_pic {
                    width: 100%;
                    max-width: 99px;
                    grid-row: 1;
                    grid-column: 1;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .cart_product_desc {
                    padding: 0 0 0 15px;
                    grid-row: 1;
                    grid-column: 2;
                }

                .cart_product_sizecol.color {
                    background-color: transparent;
                }

                .cart_product_modify {
                    position: unset;
                    flex-direction: column;
                    align-items: start;
                }

                .wishToBasket {
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    height: 40px;
                    background-color: $primaryColor;
                    border: 1px solid $primaryColor;
                    font-family: $primaryFont;
                    font-size: 15px;
                    font-weight: 500;
                    font-style: normal;
                    line-height: 1;
                    color: $white;
                    box-sizing: border-box;
                    cursor: pointer;
                    grid-row: 2;
                    grid-column: 1 / 3;

                    &:hover {
                        background-color: #fff;
                        color: $primaryColor;
                    }

                    .alert_return {
                        span {
                            display: none;
                        }
                    }
                }

                .remove_wish {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: auto;

                    &::before {
                        width: 11px;
                        height: 11px;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }

        .right_side {
            margin: 5px 0 0 45px;

            #formPromo p.w-input-label {
                color: #9A9996;
                font-size: 14px;
                font-weight: 400;
                top: 44%;
            }

            #right_col_sticky {

                .main_table {

                    display: flex;
                    flex-direction: column;
                    padding: 21px 21px 25px;
                    background: $tertiaryColor;

                    .recap_cart_title {
                        order: 0;
                        @include fs-std-bold-title;
                        text-align: center;
                    }

                    .bill_line {
                        display: flex;
                        justify-content: space-between;
                        @include fs-std-light-text;
                        font-weight: 100;
                        line-height: 1;

                        &.total_produit {
                            display: none;
                        }

                        &.top_nb_products {
                            //style inline
                            display: flex !important;
                            order: 1;
                            margin: 21px 0 13px;

                            .origine_price {
                                display: none;
                            }
                        }

                        &.discount {
                            order: 2;

                            &.hide_shippingfees {
                                #fdp_txt {
                                    &:has(.price) {
                                        font-size: 0;
                                    }
                                }
                            }
                        }

                        &.hide_shippingfees {
                            display: none;
                        }

                        &.coupon {
                            order: 3;
                            margin: 18px 0 12px;

                            .w-coupon-form .w-form-line .w-input-element:not([type=radio]):not([type=checkbox]),
                            .w-coupon-form .w-form-line input[type=text].w-input-element {
                                border-radius: 0;
                            }

                            .w-submit {
                                top: 16px;
                                width: 26px;
                                height: 21px;
                                margin-bottom: 0;
                            }

                            .w-submit-button {
                                height: fit-content;
                                background-color: transparent;
                                border: none;
                                color: $primaryColor;
                                font-weight: 300;
                            }

                            .w-loader {
                                width: 26px;
                                height: 21px;
                            }
                        }

                        &.total {
                            order: 4;
                            text-transform: uppercase;
                            font-weight: 400;
                            margin-bottom: 20px;

                            .price {
                                font-weight: 400;
                            }
                        }

                        &.promo {
                            display: none;
                        }

                        &.used_cp {
                            order: 3;
                            margin: 18px 0 20px;
                        }

                        &.total_realised_economy {
                            order: 5;
                            margin-bottom: 20px;
                        }
                    }

                    .formPanier_container {
                        order: 5;

                        .form_submit.full {
                            position: relative;
                        }

                        .loader.cartLoader {
                            height: 40px;
                            position: absolute;
                            width: 100%;
                            max-width: 383px;
                            top: 0;
                            z-index: -1;
                        }
                    }

                    .upselling.price {
                        order: 6;
                        display: flex;
                        flex-direction: column;
                        margin: 14px 0 0;

                        p,
                        strong {
                            order: 1;
                            @include fs-sml-thin-text;
                        }

                        .price {
                            @include fs-sml-thin-text;
                        }

                        progress {
                            width: 100%;
                            border: 1px solid $primaryColor;
                            border-radius: 20px;
                            height: 5px;
                            order: 0;
                        }
                    }

                    #btn_cmd_valid {
                        width: 100%;
                    }
                }
            }

            .encart_3xcb_panier {
                display: none;
            }
        }
    }

    // END PANIER



    // LIVRAISON

    &.step_2_0,
    &.step_2 {
        .wrap_bottom_rightcol {
            display: none;
        }

        @media screen and (max-width: 1120px) {
            .deconnection_tunnel {
                #btn-disconnect {
                    font-size: 13px;
                }
            }
        }

        .tunnel_track_pusher {
            display: block;
            margin: 9rem 0 57px;

            .tunnel_track_wrapper {
                padding-top: 12px;
                box-sizing: border-box;
            }

            .tunnel_track {
                display: flex;
                justify-content: center;
            }

            .tunnel_step {
                flex-direction: row-reverse;
                @include fs-std-thin-label;
                text-transform: uppercase;
                letter-spacing: 0.7px;
                opacity: .5;

                &.on {
                    opacity: 1;
                }

                &.bgSprite.bfr::before {
                    @include maskIconCoord(1, $initialColSize, $initialRowSize);
                    transform: rotate(-90deg);
                    width: 9px;
                    height: 9px;
                    margin: 0 14px;
                }

                &#step-05::before {
                    display: none;
                }
            }
        }

        .wrapper_panier {
            justify-content: flex-start;

            .right_side {
                margin-left: 45px;

                // Exception for smaller tablets under 1024px
                @media (max-width: 1023px) {
                    margin-left: auto;
                }
            }
        }

        #tunnel_right_col {
            .main_table {
                order: 2;
                display: flex;
                flex-direction: column;
                background: $tertiaryColor;
                padding: 21px 21px 25px;

                .recap_cart_title {
                    order: 0;
                    @include fs-std-bold-title;
                    text-align: center;
                    padding-bottom: 25px;
                    border-bottom: 1px solid $primaryColor;
                }

                .bill_line {
                    display: flex;
                    justify-content: space-between;
                    @include fs-std-light-text;

                    &.total_produit_dropdown {
                        display: none;
                        width: 100%;
                    }

                    .product_line_basket {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        margin-bottom: 20px;
                    }

                    .cart_product_pic {
                        width: 26%;
                        max-width: 99px;
                        margin-right: 15px;

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }

                    .wrapper_cart_product_desc {
                        display: flex;
                        flex-direction: column;

                        .wrap_titre_prix {
                            order: 0;
                        }

                        .size {
                            order: 3;
                        }

                        .color {
                            order: 2;
                        }

                        .quantity {
                            order: 1;

                            label {
                                display: flex;
                            }

                            span {
                                display: block !important;
                            }
                        }
                    }

                    .cart_product_desc {
                        width: 73%;
                        @include fs-sml-thin-text;
                    }

                    .cart_product_title {
                        @include fs-std-thin-label;
                        font-weight: 700;
                        text-transform: capitalize;
                    }

                    .wrap_titre_prix {
                        @include fs-std-thin-label;
                    }

                    .cart_product_brand,
                    .cart_product_subtitle,
                    .cart_product_modify {
                        display: none;
                    }

                    .wrapper_cart_product_price {
                        margin-bottom: 15px;
                    }

                    &.total_produit.toggle {
                        position: relative;
                        order: 0;
                        margin: 14px 0 18px;
                        cursor: pointer;
                        font-weight: 500;

                        label {
                            display: flex;
                        }

                        .nb_products {
                            //style inline
                            display: flex !important;
                            margin-left: 3px;
                        }

                        .price {
                            display: none;
                        }

                        &::after {
                            position: absolute;
                            top: 5px;
                            right: 0;
                            content: '';
                            display: block;
                            width: 12px;
                            height: 12px;
                            @include maskIconPath('../svg/spritesheet.svg');
                            @include maskIconCoord(1, $initialColSize, $initialRowSize);
                            background-color: $primaryColor;
                            cursor: pointer;
                        }

                        &.open {
                            &::after {
                                transform: rotate(-180deg);
                            }
                        }
                    }


                    &.discount {
                        order: 2;

                        label {
                            font-weight: 100;
                        }
                    }

                    &.under_dropdown {
                        display: flex !important;
                        padding-top: 22px;
                        border-top: 1px solid $primaryColor;
                        margin-bottom: 4px;

                        label {
                            font-weight: 100;
                        }
                    }

                    &.coupon {
                        order: 3;
                        margin: 20px 0 29px;

                        .w-coupon-form .w-form-line .w-input-element:not([type=radio]):not([type=checkbox]),
                        .w-coupon-form .w-form-line input[type=text].w-input-element {
                            border-radius: 0;
                        }

                        .w-submit-button {
                            background-color: transparent;
                            border: none;
                            color: $primaryColor;
                            font-weight: 300;
                        }
                    }


                    &.used_cp {
                        display: none;
                    }

                    &.total {
                        order: 4;
                        text-transform: uppercase;
                        font-weight: 400;
                        margin-bottom: 29px;

                        .pricetag .price {
                            font-weight: 400;
                            font-size: 15px;
                        }
                    }
                }

                .formPanier_container {
                    position: relative;
                    order: 5;
                }

                #btn_cmd_valid {
                    width: 100%;
                }

                .loader.cartLoader {
                    height: 40px;
                    position: absolute;
                    width: 100%;
                    max-width: 383px;
                    top: 0;
                    z-index: -1;
                }
            }
        }

        /**
        * Delivery
        */
        .delivery_section_title {
            display: block;
            width: 100%;
            text-align: left;
            font-size: 16px;
            margin-bottom: 15px;
        }

        .delivery_section_subtitle {
            display: block !important;
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 15px;
            font-weight: 100;
            line-height: 1;
            margin-bottom: 30px;
        }

        #wrapper_form_adresse {
            .delivery_section_title {
                padding: 0;
            }
        }

        .w-newuser-form-v2 {

            .delivery_section_title {
                margin-bottom: 5px;
            }

            .delivery_section_subtitle {
                margin-bottom: 38px;
            }

            .w-input-group {
                margin-bottom: 9px;

                &:first-of-type {
                    .w-form-line {
                        margin-bottom: 9px;
                    }
                }
            }

            .w-form-line {
                margin-bottom: 18.5px;
            }

            .w-input-label {
                font-size: 14px;
            }
        }

        .wrapper_delivery_choice {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 25px;
            column-gap: 20px;
            row-gap: 15px;

            .delivery_section_title {
                margin-bottom: 0;
            }

            .delivery_choice_btn {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                flex-direction: column;
                width: 140px;
                height: 80px;
                box-sizing: border-box;
                border: 1px solid $primaryColor;
                background-color: $white;
                font-size: 14px;
                font-weight: 400;
                color: $primaryColor;
                line-height: 18px;
                cursor: pointer;
                transition: all .3s ease-in-out;

                &.home_delivery_btn {

                    .delivery_choice_btn_label:before {
                        content: '';
                        @include maskIconPath('../svg/spritesheet.svg');
                        @include maskIconCoord(26, $initialColSize, $initialRowSize);
                        width: 23px;
                        height: 22px;
                        position: absolute;
                        bottom: 17px;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: $primaryColor;
                    }

                    .delivery_choice_btn_price {
                        margin: 2px 0 7px;
                    }
                }

                .delivery_choice_btn_label {
                    position: relative;
                    width: 100%;
                    line-height: 1;
                    text-align: center;


                    &:before {
                        content: '';
                        @include maskIconPath('../svg/spritesheet.svg');
                        @include maskIconCoord(26, $initialColSize, $initialRowSize);
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        bottom: 20px;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: $primaryColor;
                    }
                }

                &.store_delivery_btn {
                    .delivery_choice_btn_label:before {
                        @include maskIconCoord(4, $initialColSize, $initialRowSize);
                    }
                }

                &.relay_delivery_btn {
                    .delivery_choice_btn_label:before {
                        @include maskIconCoord(4, $initialColSize, $initialRowSize);
                    }
                }

                .delivery_choice_btn_price {
                    position: relative;
                    width: 100%;
                    @include fs-sml-thin-text;
                    font-size: 11px;
                    line-height: 1;
                    text-align: center;
                    margin-bottom: 8px;
                }

                .price {
                    @include fs-sml-thin-text;
                }

                &:hover,
                &.selected {
                    background-color: $tertiaryColor;
                    color: $primaryColor;


                }

            }

        }

        .delivery_choice {
            position: relative;
            display: none;
            opacity: 0;
            transition: all .3s ease-in-out;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
            padding: 0 0 25px 33px;
            cursor: pointer;
            border-bottom: 1px solid $primaryColor;


            input.choix_livraison {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }

            .checkbox {
                position: absolute;
                top: 32%;
                left: 0;
                transform: translateY(-50%);
                width: 15px;
                height: 15px;
                border: 1px solid $primaryColor;
                background-color: $primaryColor;
                box-shadow: inset 0 0 0 10px $white;
                border-radius: 50%;
            }

            &.selected {
                display: flex;
                opacity: 1;
                transition: all .3s ease-in-out;
            }

            &:hover,
            &.active {
                border-color: $primaryColor;

                .checkbox {
                    box-shadow: inset 0 0 0 2px $white;
                }
            }

            .transporteur_infos {
                padding: 3px 0 0 7px;
            }

            .transporteur_type,
            .transporteur_id {
                @include fs-std-bold-subtitle;
            }

            .transporteur_delay,
            .shop_name,
            .adresse {
                @include fs-std-thin-text;
            }

            .warning_point_relais {
                font-family: $primaryFont;
                font-size: 15px;
                font-weight: 300;
                margin: 2px 0 6px;
            }

            .shop_name {
                font-family: $primaryFont;
                font-size: 15px;
                text-transform: uppercase;
                margin-top: 14px;

                +.adresse {
                    text-transform: capitalize;
                }
            }

            .adresse {
                font-family: $primaryFont;
                font-size: 15px;
                font-weight: 300;
                margin: -2px 0 6px;
            }

            .show_delivery_info {
                position: relative;
                z-index: 2;
                font-family: $primaryFont;
                font-size: 15px;
                font-weight: 300;
                margin-top: 4px;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            .price_tag,
            .fdp_offered {
                font-size: 15px;
                text-transform: uppercase;
                color: $primaryColor;
                font-weight: 100;
            }
        }

        /* Cart addresses reminder */
        #blocs_address.addresses_recap {
            order: 1;
            border-top: 1px solid $primaryColor;
            margin-bottom: 5px;
        }

        .addresses_recap {

            section .address:first-of-type {
                border-top: 1px solid $primaryColor;
            }

            .address {
                position: relative;
                padding: 9px 19px;
                cursor: pointer;

                &:first-child {
                    border-bottom: 1px solid $primaryColor;
                }

                &.processing:after {
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: 5;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(231, 226, 222, .5);
                    background-image: url(../svg/three_dots.svg);
                    background-repeat: no-repeat;
                    background-size: auto 20%;
                    background-position: center;
                }

                &.delivery {
                    display: none;
                }

                &.active {
                    display: block;
                }

                &#delivery_address_recap_other {
                    cursor: default;
                    border-bottom: 1px solid $primaryColor;

                    .full_name {
                        font-size: 15px;
                        margin-top: 7px;
                    }

                    .addr_line.full_address {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 15px;
                        font-weight: 100;
                        line-height: 1;
                        margin-bottom: 0;
                    }
                }

                .arrow {
                    position: absolute;
                    height: fit-content;
                    z-index: -1;
                    top: 43%;
                    right: 17px;
                    transform: translateY(-50%);

                    &:before {
                        width: 12px;
                        height: 12px;
                        transform: rotate(-90deg);
                    }
                }

                &:hover {
                    a.modlink {
                        text-decoration: none;

                        span {
                            @include secondaryButton(left, 3);
                        }
                    }
                }

                &.bill {
                    padding: 0;

                    a {
                        display: block;
                        padding: 7px 19px;
                    }

                    .modlink {
                        text-decoration: none;
                        @include secondaryButton(left, 3);
                    }
                }
            }

            .addr_label {
                @include fs-std-medium-text;
            }

            .infos {
                @include fs-std-thin-text;
            }

            .addr_line {

                &.full_address {
                    width: 50%;
                    margin-bottom: 8px;
                }
            }

            .modlink {
                @include fs-std-medium-text;
                line-height: 1;
                cursor: pointer;
                @include secondaryButton(left, 3);
            }
        }

        .lightbox {
            .address {
                position: relative;
                cursor: pointer;
                transition: background-color .3s ease-in-out;
                padding: 0;

                &:last-child {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 40px;
                    background-color: #F0EEE9;
                    color: $primaryColor;
                    border: 1px solid #F0EEE9;
                    margin-top: 20px;
                    box-sizing: border-box;

                    .addr_line {
                        width: 100%;
                        font-family: $primaryFont;
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 1;
                        text-transform: initial;
                        letter-spacing: normal;
                        color: $primaryColor;
                    }

                    &:hover {
                        border: 1px solid $primaryColor;
                        background-color: $white !important;

                        .addr_line {
                            width: 100%;
                            color: $primaryColor;
                        }
                    }

                    .infos {
                        font-family: $primaryFont;
                        font-size: 13px;
                        letter-spacing: .07em;
                        text-transform: uppercase;
                        padding: 0;
                    }

                    .addr_line.full_address {
                        margin-bottom: 0;
                    }
                }

                &:hover {
                    a.modlink {
                        text-decoration: underline;
                    }
                }

                .infos {
                    padding: 12px 25px 30px;
                }

                .modlinks_wrapper {
                    position: absolute;
                    z-index: 5;
                    bottom: 12px;
                    left: 25px;
                }

                a.modlink {
                    position: relative;
                    z-index: 2;

                    &:hover {
                        text-decoration: none;
                    }
                }

                .add_addr {
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;

                    &.loading {
                        background: $primaryColor url(../svg/three_dots_w.svg) no-repeat center/auto 20%;
                    }
                }
            }

            .addresses_recap {
                .addr_line.full_name {
                    display: block;
                }
            }


        }

        .deconnection_tunnel {
            order: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: fit-content;
            padding: 0 14px;
            font-family: $primaryFont;
            font-size: 15px;
            font-weight: 100;
            margin-bottom: 12px;

            .button {
                height: initial;
                font-family: $primaryFont;
                font-size: 15px;
                font-weight: 500;
                line-height: 1;
                text-transform: initial;
                letter-spacing: initial;
                color: $primaryColor;
                background-color: transparent;
                border: none;
                cursor: pointer;

                span {
                    @include secondaryButton(center, 4);
                }

                &:hover {
                    background-color: transparent;
                }
            }
        }

        .wrap_bottom_rightcol {
            display: block;
            order: 3;
        }

        .addresses_recap+.wrap_bottom_rightcol {
            display: none;
        }

        .w-input-container.checkCgv {
            position: absolute;
            bottom: 83px;
            width: 100%;

            .w-checkbox-input .w-input-element+.w-input-label {
                align-items: flex-start;
                font-family: $primaryFont;
                font-size: 11px;
                font-weight: 100;
                color: $primaryColor;

                &:before {
                    width: 14px;
                    height: 14px;
                    box-sizing: border-box;
                    margin-top: 5px;
                    border: 1px solid $primaryColor;
                }

                span {
                    width: calc(100% - 23px);
                    margin-left: 8px;
                }

                a {
                    color: $primaryColor;
                    text-decoration: underline;
                    text-underline-position: under;
                }
            }
        }

        #err_cgv_compulsory {
            position: absolute;
            bottom: 58px;
            left: 23px;
            font-family: $primaryFont;
            font-size: 11px;
            font-weight: 100;
            color: #EC6907;
        }

        .form_submit.full {
            margin-top: 46px;
        }
    }

    &.step_2 {

        #tunnel_right_col .main_table .bill_line.discount {
            margin: 0 0 18px;
            padding: 0;
            border: none;
        }
    }

    .wrapper_locate_relais {

        .gm-style .gm-style-iw-c .info_popup::before {
            bottom: initial;
            top: 0;
        }

        .gm-style-iw .relayLink {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 15px 0;
            padding: 10px 0;
            border: 1px solid $primaryColor;
            background: $primaryColor;
            color: $white;

            &:hover {
                background: $white;
                color: $primaryColor;
            }
        }
    }

    // END LIVRAISON
    .w-newsletter-form .w-form-line {
        margin-bottom: 44px;
    }

    .frz_nocache {
        order: 1;
        padding-bottom: 75px;
    }

    #gondole_derniers_articles_vus {
        position: relative;
        box-sizing: border-box;
        padding-bottom: 75px;
        
        #derniers_articles_vus {
            font-family: $primaryFont;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 34px;
            color: $primaryColor;
            margin-bottom: 12px;
        }

        #productVisitedSwiper {
            width: 100%;
            padding-bottom: 75px;

            & > .swiper-wrapper {
                & >  .item.swiper-slide {
                    width: 30.529%;
                    min-width: 30.529%;
                    max-width: inherit;
                    margin-right: 15px;

                    .item_price {
                        margin-top: 2px;

                        .pricetag {
                            line-height: 1;
                        }
                    }

                    .choices_list {
                        .color {
                            margin-top: 0;
                        }

                        .remaining_colors_link {
                            .remaining_colors {
                                margin: 0 0 4px 0;
                            }
                        }
                    }

                    .productColorFieldset {
                        height: 48px;

                        .right_element_prod {
                            display: block;
                            padding-top: .3rem;
                        }
                    }

                    .productSizeFieldset {
                        .choices_list {
                            .form_itm {
                                width: 28px;
                                height: 28px;
                            }
                        }

                        &:has(.size_list) {
                            min-height: 80px;
                        }
                    }

                    .rollover_rayonsNext,
                    .rollover_rayonsPrev {
                        cursor: pointer;
                    }

                    .eclat_rond {
                        position: absolute;
                        top: 0;
                        z-index: 3;

                        img {
                            width: auto;
                            height: 30px;
                        }
                    }

                    &:hover {
                        .productColorFieldset {
                            opacity: 1;
                            z-index: 5;
                        } 
                    }
                }
            }

            .wrap_rolloverproduit {
                height: 160px;
                align-items: flex-end;
            }

            .btnAddBasketWrapper {
                position: absolute;
                bottom: 100px;
                width: 100%;
                background-color: rgba($tertiaryColor, .95);
                padding: 0.5rem;
                z-index: 5;
                box-sizing: border-box;

                .bloc_add_alert_form {
                    .form_submit {
                        button {
                            display: block;
                            width: 13px;
                            height: 13px;
                            @include maskIconPath('../svg/spritesheet.svg');
                            @include maskIconCoord(1, $initialColSize, $initialRowSize);
                            background-color: #000;
                            padding: 0;
                            transform: rotate(-90deg);
                            cursor: pointer;

                            span {
                                font-size: 0;
                            }
                        }
                    }
                }

                .alert_stock {
                    width: 90%;
                }

                .alert_return {
                    display: block;
                    width: 13px;
                    height: 13px;
                    @include maskIconPath('../svg/spritesheet.svg');
                    @include maskIconCoord(24, $initialColSize, $initialRowSize);
                    background-color: #000;
                    padding: 0;
                    cursor: pointer;
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }
            }

            .bloc_add_alert_confirmation {
                font-size: 13px;
                font-weight: 100;
                line-height: normal;
            }

            &.emptyBasketSwiper {
                & > .swiper-wrapper {
                    & >  .item.swiper-slide {
                        width: 23.23%;
                        min-width: 23.23%;
                        max-width: inherit;
                        margin-right: 15px;

                        .wrap_rolloverproduit {
                            .btnAddBasketWrapper {
                                padding: 20px 0 15px;

                                .alert_stock {
                                    width: 100%;
                                    font-family: $primaryFont;
                                    font-size: 16px;
                                    font-weight: 700;
                                    line-height: normal;
                                    color: $primaryColor;
                                    text-align: center;
                                    margin-bottom: 12px;
                                }

                                .bloc_add_alert_form {
                                    .w-form-line {
                                        width: 95%;
                                        max-width: 350px;
                                        margin: 0 auto;
                                    }
                                }

                                .bloc_add_alert_confirmation {
                                    font-family: $primaryFont;
                                    font-size: 12px;
                                    font-weight: 100;
                                    line-height: normal;
                                    color: $primaryColor;
                                    text-align: center;
                                }
                            }

                            .btnAddBasketWrapper {
                                .alert_return {
                                    position: absolute;
                                    top: 10px;
                                    right: 10px;
                                }

                                &.noSizeAvailable {
                                    #size_is_not_available {
                                        font-size: 16px;
                                        font-weight: 700;
                                        line-height: normal;
                                        color: $primaryColor;
                                        margin: 12px auto;
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .panier-swiper-pagination {
                .swiper-pagination-bullet {
                    background: $bulletsColor;
                }

                .swiper-pagination-bullet-active {
                    background: $white;
                    border: 1px solid $primaryColor;
                }
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            position: absolute;
            display: none;
            top: 40%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(1, $initialColSize, $initialRowSize);
            background-color: $primaryColor;
            background-image: none;
            z-index: 3;
            cursor: pointer;
            
            &::after {
                display: none;
            }
        }

        .swiper-button-prev {
            left: 0;
            transform: rotate(90deg);
        }

        .swiper-button-next {
            right: 0;
            transform: rotate(-90deg);
        }

        &:has(#productVisitedSwiper.swiper-initialized) {
            padding: 0 36px;

            .swiper-button-next,
            .swiper-button-prev {
                display: block !important;
            }

        }
    }
}

// COPIED CART NOTIFICATION
.copied-text-notification {
    @include fs-sml-thin-text;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: $primaryColor;
    color: $secondaryColor;
    text-align: center;
    padding: 10px 0;
    margin: 10px auto;
    min-width: 30ch;
    width: fit-content;
    transform: translateY(calc(100% + 10px));
    transition: transform 0.3s ease-in-out;
    animation: slideIn 0.3s forwards;

    &.slide-out {
        animation: slideOut 0.3s forwards;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(calc(100% + 10px));
    }

    to {
        transform: translateY(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(calc(100% + 10px));
    }
}

// PAIEMENT

.checkout {

    #main_cart_wrapper {
        justify-content: flex-start;
        margin: 46px auto 20px;
    }

    .tunnel_track_pusher {
        display: block;
        margin: 8rem 0 47px;

        .tunnel_track_wrapper {
            padding-top: 34px;
        }

        .tunnel_track {
            display: flex;
            justify-content: center;
        }

        .tunnel_step {
            flex-direction: row-reverse;
            @include fs-std-thin-label;
            text-transform: uppercase;
            letter-spacing: 0.7px;
            opacity: .5;

            &.on {
                opacity: 1;
            }

            &.bgSprite.bfr::before {
                @include maskIconCoord(1, $initialColSize, $initialRowSize);
                transform: rotate(-90deg);
                width: 9px;
                height: 9px;
                margin: 0 14px;
            }

            &#step-05::before {
                display: none;
            }
        }
    }

    .wrapper_basket_content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        max-width: 1335px;
        width: 80%;
        margin: auto;

        // Exception for smaller tablets under 1024px
        @media (max-width: 1023px) {
            width: 95%;
        }
    }

    .bank_left_side {
        width: 64%;
        max-width: 810px;
    }

    .bank_bloc_right {
        display: flex;
        flex-direction: column;
        width: 34%;
        max-width: 425px;
        margin-left: 45px;

        // Exception for smaller tablets under 1024px
        @media (max-width: 1023px) {
            margin-left: auto;
        }
    }

    /**
 * Payment type buttons
 */
    .wrapper_payment_mode {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 16px;

        .selectPaymentModeTitle {
            width: 100%;
            @include fs-std-bold-title;
            line-height: 1;
            margin-bottom: 18px;
        }

        .payment_mode_btn {
            position: relative;
            width: 22%;
            max-width: 140px;
            height: 80px;
            border: 1px solid $primaryColor;
            margin: 0 20px 15px 0;
            cursor: pointer;
            box-sizing: border-box;
            transition: all .3s ease-in-out;

            &:hover,
            &.actif {
                background-color: $tertiaryColor;
                border: 1px solid $primaryColor;

                span {
                    color: $primaryColor;
                }
            }

            span {
                position: absolute;
                bottom: 16px;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                @include fs-sml-medium-subtitle;
                font-size: clamp(12px, fs-vw(14, 1920), 14px);
                white-space: nowrap;

                &:before {
                    content: '';
                    display: block;
                    width: 33px;
                    height: 22px;
                    margin-bottom: 5px;
                }
            }

            &[data-payment-mode="hosted"] {
                span:before {
                    background: transparent url(../svg/cb.svg) no-repeat center;
                }
            }

            &[data-payment-mode="paypal"] {
                span:before {
                    width: 56px;
                    background: transparent url(../svg/paypal.svg) no-repeat center;
                }
            }

            &[data-payment-mode="alma_remote_payment_1"],
            &[data-payment-mode="alma_remote_payment_3"],
            &[data-payment-mode="alma_remote_payment_4"] {
                max-width: 150px;

                span:before {
                    width: 60px;
                    margin-bottom: 0;
                    background: transparent url(../svg/alma.svg) no-repeat center;
                }
            }
        }
    }

    /**
 * Each payment types
 */
    .wrapper_basket_content .bank_left_side .wrapper_bloc_banque {
        margin-bottom: 0; // _app override 🤬
        padding: 0; // _app override 🤬
    }

    .wrapper_bloc_banque {
        display: none;

        &.actif {
            display: block !important;
        }

        .bank_title {
            @include fs-std-bold-title;
        }

        .bank_subtitle {
            @include fs-std-thin-text;

            strong {
                font-weight: 100;
            }
        }

        .cards-wrapper,
        .bank_confiance {
            display: none;
        }

        .total_transaction {
            @include fs-std-thin-text;
            margin-bottom: 18px;

            .pricetag {
                @include fs-std-thin-text;
            }
        }
    }

    #bank_div #bloc_payment_cancel {
        margin-top: 3rem;
        width: 70%;
    }

    .bank_left_side {
        .loader_container {
            position: relative;
            width: fit-content;

            .loader {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                background: $primaryColor;
                border-color: $primaryColor;

                span::after {
                    background: $white;
                }

                &.loading {
                    z-index: 2;
                }
            }
        }

        .mode_sms {
            margin-top: 15px;

            .mode_title {
                display: block;
                font-family: $primaryFont;
                font-weight: 100;
                margin-bottom: 10px;
            }

            input.tel_input {
                border: none;
                border-bottom: 1px solid $primaryColor;

                &:focus {
                    outline: none;
                }
            }
        }
    }

    /**
 * PayPal
 */
    .wrapper_basket_content .bank_left_side .paypal .bloc_bank_contents {
        // _app override 🤬
        justify-content: flex-start;
        align-items: flex-end;
    }

    .wrapper_bloc_banque.paypal {
        .title_wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .bank_title {
            margin-bottom: 1em;
        }

        .bank_subtitle {
            display: block;
            margin-bottom: .2em;
        }

        input#inputPAYPAL {
            opacity: 0;
        }
    }

    .wrapper_bloc_banque.alma {
        .bank_subtitle {
            opacity: 0;
        }

        #inputAlma {
            background-color: transparent;
            color: $primaryColor;
            border: 1px solid $primaryColor;
            border-radius: 0;
            padding: 9px 20px 11px 20px;
            cursor: pointer;
            text-align: center;
            font-size: 15px;
            font-family: $primaryFont;
            transition: all 400ms linear;

            &:hover {
              background-color: $tertiaryColor;
            }

            &:focus {
              outline: none;
              background-color: $tertiaryColor;
            }
        }
    }

    /**
 * Payment right side
 */
    .bank_right_side {

        .upselling.price {
            display: none;
        }

        .deconnection_tunnel {
            order: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: fit-content;
            padding: 0 14px;
            font-family: $primaryFont;
            font-size: 15px;
            font-weight: 100;
            margin-bottom: 7px;


            .button {
                height: initial;
                font-family: $primaryFont;
                font-size: 15px;
                font-weight: 500;
                line-height: initial;
                text-transform: initial;
                letter-spacing: initial;
                color: $primaryColor;
                background-color: transparent;
                border: none;
                cursor: pointer;

                span {
                    @include secondaryButton(center, 4);
                }

                &:hover {
                    background-color: transparent;
                }
            }
        }

        .payment_addr_wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 8px 0 12px 20px;
            border-top: 1px solid $primaryColor;

            .title {
                @include fs-std-bold-subtitle;
                font-size: 15px;
                font-weight: 500;
            }

            .address {

                .phone {
                    display: none
                }

                p {
                    @include fs-std-thin-text;
                    max-width: 40%;
                }
            }

            &.conveyor_infos .address .name {
                display: block;
            }

            .address_mod {
                @include fs-std-bold-subtitle;
                font-size: 15px;
                font-weight: 500;
                margin-top: 2px;
            }

            &::after {
                position: absolute;
                top: 40%;
                right: 20px;
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                @include maskIconPath('../svg/spritesheet.svg');
                @include maskIconCoord(1, $initialColSize, $initialRowSize);
                background-color: $primaryColor;
                transform: rotate(-90deg);
            }
        }

        .main_table {
            background: $tertiaryColor;
            display: flex;
            flex-direction: column;
        }

        .wrapper_payment {
            order: 0;
            background: $white;
        }

        .recap_cart_title {
            order: 1;
            @include fs-std-bold-title;
            text-align: center;
            margin-top: 20px;
        }

        .bill_line {
            display: flex;
            justify-content: space-between;
            margin: 20px 20px 10px;


            &.used_cp,
            &.total_realised_economy,
            &.promo {
                display: none;
            }
        }

        .total_produit.toggle {
            order: 2;
            position: relative;
            margin-top: 25px;
            margin-bottom: 0;
            padding-top: 15px;
            cursor: pointer;
            border-top: 1px solid $primaryColor;

            label {
                display: flex;
            }

            label,
            .nb_products {
                //style inline
                display: flex !important;
                @include fs-std-regular-text;
                margin-left: 3px;
            }

            .price {
                display: none;
            }

            &::after {
                position: absolute;
                top: 55%;
                right: 0;
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                @include maskIconPath('../svg/spritesheet.svg');
                @include maskIconCoord(1, $initialColSize, $initialRowSize);
                background-color: $primaryColor;
                cursor: pointer;
            }

            &.open {

                .total_produit_dropdown {
                    display: block;
                }

                &::after {
                    transform: rotate(-180deg);
                }
            }
        }

        .total_produit_dropdown {
            display: none;
            order: 3;

            .product_line_basket {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-top: 6px;

                &:not(:first-child) {
                    margin-top: 20px;
                }

            }

            .cart_product_pic {
                width: 26%;
                max-width: 99px;
                margin-right: 15px;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .wrapper_cart_product_desc {
                display: flex;
                flex-direction: column;

                .wrap_titre_prix {
                    order: 0;
                }

                .size {
                    order: 3;
                }

                .color {
                    order: 2;
                }

                .quantity {
                    order: 1;
                }
            }

            .wrapper_cart_product_price {
                margin-bottom: 15px;
            }

            .cart_product_desc {
                width: 73%;
                @include fs-sml-thin-text;
            }

            .cart_product_title {
                @include fs-std-thin-label;
                font-weight: 700;
                text-transform: capitalize;
            }

            .wrap_titre_prix {
                @include fs-std-thin-label;
            }



            .cart_product_brand,
            .cart_product_subtitle,
            .cart_product_modify,
            .pricetag.was {
                display: none;
            }
        }

        .top_nb_products {
            // style inline
            display: flex !important;
            order: 4;
            @include fs-std-thin-text;
            padding-top: 23px;
            margin: 17px 20px 0 20px;
            border-top: 1px solid $primaryColor;

            .origine_price {
                display: none;
            }
        }

        .discount {
            order: 5;
            @include fs-std-thin-text;
            margin-top: 7px;
        }

        .total {
            order: 6;
            text-transform: uppercase;
            @include fs-std-medium-text;
            margin: 7px 20px 18px;

            .pricetag {
                @include fs-std-medium-text;
            }
        }

        .wrap_bottom_rightcol {
            display: none;
        }

    }

    /**
 * Order fail
 */
    .banque_erreur_txt {
        margin: 0 0 2em 0;
        padding: 2em;
        font-family: $primaryFont;
        font-size: 15px;
        font-weight: 300;
        background-color: $tertiaryColor;

        a {
            color: $primaryColor;
            text-decoration: underline;
        }

        +.txt_recommencer_paiement {
            display: none;
        }

        #bloc_erreur_order {
            .rose.displayNone {
                display: none;
            }
        }
    }
}

.cancel_validation_payment {
    margin-top: 32px;
}

// END PAIEMENT

// REMERCIEMENT

.thanksWrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 98%;
    max-width: 1335px;
    margin: 44px auto 52px;
}

.gm-style .gm-style-iw-c {
    padding: 48px 28px 22px 28px !important;
    box-shadow: none;

    .gm-style-iw-d {
        padding: 0 !important;
        overflow: visible !important;

        .infoWindowContent {
            position: relative;
            font-family: $primaryFont;
            font-size: 15px;
            font-weight: 100;

            strong {
                font-size: 16px;
                font-weight: 700;
                color: $primaryColor;
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: -30px;
                left: 50%;
                transform: translateX(-50%);
                width: 20px;
                height: 20px;
                @include maskIconPath('../svg/spritesheet.svg');
                @include maskIconCoord(6, $initialColSize, $initialRowSize);
                background-color: $primaryColor;
            }
        }
    }
}

.thanksWrapper .bankThanksTop {
    max-width: 707px;
    width: 57%;
    margin: 0;

    #around_me_map {
        width: 100%;
        height: 530px;
        margin: 0;
    }
}

.thanksWrapper .thanksRecap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 531px;
    width: 43%;
    margin: 17px 0 0 48px;

    h1 {
        width: 100%;
        @include fs-big-bold-title;
        text-align: center;
    }

    .thanks_subtitle_confirm {
        display: block;
        width: 100%;
        @include fs-std-bold-title;
        font-size: 22px;
        text-align: center;
        margin-bottom: 8px;
    }

    .thanks_subtitle {
        display: none;
    }

    .thanksLeft {
        width: 100%;
        @include fs-std-thin-text;
        text-align: center;

        .seeOrderEvo {
            color: $primaryColor;
            text-align: center;
            font-family: $primaryFont;
            font-size: 15px;
            font-weight: 100;
            line-height: normal;
            margin-bottom: 10px;
        }

        .thanksTrust {
            display: none;
        }
    }

    .thanksRight {
        width: 100%;
        max-width: 520px;
        padding: 12px 20px;
        margin: 0 auto;
        background: $tertiaryColor;

        .transaction_date,
        .trader_name,
        .trader_address,
        .main_ref_transaction,
        .main_amount_transaction,
        .number_card {
            @include fs-std-thin-text;
            margin: 0;
            text-transform: initial;
        }

        p.txt_thanks_transaction {
            @include fs-std-thin-text;
            margin: 0;
            text-transform: initial;
            margin-top: 20px;
        }

        .trader_name,
        .trader_adress,
        .authorization_id {
            display: none;
        }
    }

    .thanksBottom {
        width: 100%;
        max-width: 520px;
        margin: 0 auto;

        .w-password-input .w-input-element {
            height: 33px !important;
        }

        .w-form-line {
            margin-bottom: 17px;
        }
    }

    .thanksBottomWrapper {
        margin-top: 25px;
    }

    .bankThanksBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 40px;
        background-color: $primaryColor;
        border: 1px solid $primaryColor;
        margin: auto;
        box-sizing: border-box;
        cursor: pointer;

        span {
            font-family: $primaryFont;
            font-size: 15px;
            font-weight: 500;
            line-height: 0;
            color: $secondaryColor;
        }

        &:hover {
            background-color: $secondaryColor;
            border: 1px solid $primaryColor;

            span {
                color: $primaryColor;
            }
        }
    }

    .createPwdTxt {
        @include fs-std-thin-text;
        text-align: center;
        margin: 30px 0 10px 0;
    }
}

.w-newpass-form {
    margin-bottom: 25px;

    .w-input-label {
        font-size: 14px;
    }
}

// END REMERCIEMENT

// CONNEXION TUNNEL

.body_login.in_tunnel {

    #subtitleLogin {
        max-width: 663px;
        margin: 0 auto;
    }

    .content_wrapper {
        padding-top: 2 rem !important;
        background: $white !important;
    }

    .wrapper_tunnel {
        flex-direction: column;
    }

    .tunnel_track_pusher {
        margin: 30px 0 0;

        .tunnel_track {
            display: flex;
            justify-content: center;

            .tunnel_step {
                flex-direction: row-reverse;
                @include fs-std-thin-label;
                text-transform: uppercase;
                letter-spacing: 0.7px;
                opacity: .5;

                &.on {
                    opacity: 1;
                }

                &.bgSprite.bfr::before {
                    @include maskIconCoord(1, $initialColSize, $initialRowSize);
                    transform: rotate(-90deg);
                    width: 9px;
                    height: 9px;
                    margin: 0 14px;
                }

                &#step-05::before {
                    display: none;
                }
            }
        }
    }

    .login_tunnel_title {
        display: block !important;
        margin: 45px 0 0;

        p {
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 30px;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            text-align: center;
        }
    }

    .wrapper_tunnel .newlogin_page {
        padding: 0 1rem 2.2rem;
        margin: 0 auto 30px;
    }

    .newlogin_page .w-newlogin-form .intro {
        display: none;
    }

    #site_head_wrap {
        display: none;
    }

    .wrapper_tunnel .newlogin_page .login_bloc .login_form_wrapper {

        .w-input-container {
            max-width: 663px;
        }

        .email-form-line {
            margin-bottom: 30px;
        }
    }

    .content_wrapper {
        padding-top: clamp(40px, fs-vw(70, 1920), 70px);
    }
}

// END CONNEXION TUNNEL

.cart_logo {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    .logo_img {
        display: block;
        height: 30px;
        width: 180px;
        background-image: url(../svg/logo.svg);
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.bloc_question {
    text-align: center;
    margin-top: 19px;

    .title_question {
        @include fs-std-bold-title
    }

    .faq {
        margin: 5px 0;

        // IF TABLE

        table {
            width: 100% !important;
        }

        table>tbody>tr:nth-child(1) {
            font-weight: 700;
            font-size: 13px;
            background: #3d45572e;
        }

        td {
            width: 33%;
            vertical-align: middle;
            border: 1px solid $primaryColor;
        }

        // END IF TABLE
    }

    .intitule_question {
        @include fs-std-thin-text;
        font-size: 11px;
        cursor: pointer;
    }

    .texte_question {
        @include fs-std-light-text;
        font-size: 11px;
        margin: 10px 0;
    }
}

.cart.step_2 {

    #tunnel_right_col {
        .main_table .bill_line.under_dropdown {
            padding-top: 25px;
        }
    }
}

.cart.step_2_0 {

    .elem_global {
        margin-bottom: 15px;
    }

    .errormsgadd {
        display: none;
    }

    .add-complementary-line {

        .w-checkbox-input .w-input-element+.w-input-label {
            display: flex;
            align-items: center;
            font-size: 15px;
            color: $primaryColor;

            span {
                font-weight: 500;
                margin-left: 3px;
            }

            &::before {
                content: '+';
                display: flex;
                width: 10px;
                height: 1em;
                font-size: 20px;
                background-color: #fff;
                border: none;
                box-shadow: none;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .sameBillAddress,
    .accountCreate {
        .w-checkbox-input .w-input-element {
            +.w-input-label {
                &:before {
                    width: 26px;
                    height: 15px;
                    background-color: #F0EEE9;
                    border: 0;
                    border-radius: 48px;
                    box-shadow: none;
                    transition: none;
                }

                &:after {
                    content: '';
                    width: 12px;
                    height: 12px;
                    background-color: $white;
                    border-radius: 50%;
                    position: absolute;
                    top: 4px;
                    left: 1px;
                }

                span {
                    font-family: $primaryFont;
                    font-size: 15px;
                    font-weight: 100;
                    color: $primaryColor;
                }
            }

            &:checked+.w-input-label {
                &:before {
                    background-color: $primaryColor;
                }

                &:after {
                    left: 12px;
                }
            }
        }
    }

    .bill_line.total {
        margin-top: 17px;
        margin-bottom: 14px !important;

        .pricetag .price {
            font-weight: 400;
        }
    }

    .form_submit.full {
        margin-top: 0;
    }
}

.body_login.in_tunnel {

    .optin_container {
        position: relative;

        .w-input-note.w-input-error {
            position: absolute;
            top: 20%;
            left: 40%;
            width: fit-content;
        }
    }
}

// Gestion tablet specific case

.tablet {

    .cart.step_2 {

        .wrapper_delivery_choice .delivery_choice_btn {
            width: 101px;
        }

        #tunnel_right_col .main_table .bill_line.total {
            margin-bottom: 60px;
        }
    }

    .checkout .wrapper_payment_mode .payment_mode_btn {
        width: 35%;
    }

    .checkout {

        .bank_right_side .deconnection_tunnel {
            flex-direction: column;
        }

        .thanksWrapper .bankThanksTop {
            width: 52%;
        }
    }
}