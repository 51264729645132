:root {
    --preHeaderHeight: 35px;
    --headerHeight: 80px;
    --headerRecalcHeight: 65px;
    --headerFull: calc(var(--preHeaderHeight) + var(--headerHeight));
    --headerRecalcHeightFull: calc(var(--preHeaderHeight) + var(--headerRecalcHeight));
}


// Position input label if autofill is active
input[type="email"]:has(+.w-input-label) {
    &:-webkit-autofill {
        height: 40px !important;

        &+.w-input-label {
            font-size: .75em;
            transform: translateY(-130%);
        }
    }
}

a,
a:visited,
a:link {
    color: $primaryColor;
    text-decoration: none;
}

.cache,
[v-cloak] {
    display: none;
}

body {
    // Without preheader
    font-family: $primaryFont;

    &:not(.body_login):not(.homepage):not(.in_tunnel):not(.category:has(.item_look)):not(:has(.dynasearchwrapper)) {
        padding-top: var(--headerHeight);

        // With preheader
        &.pheader {
            padding-top: var(--headerFull);
        }
    }

    // Tablet mode
    &.tabletMode {

        &.pheader {}
    }

    &.cart:not(.step_1),
    &.tabletMode.cart:not(.step_1),
    &.tabletMode.checkout,
    &.checkout {
        padding: 0;

        &.pheader {
            padding-top: 0;
        }
    }
}

#site_head_wrap {
    background-color: $secondaryColor;
    transition: background-color 0.02s ease;
}

.body_login:not(:has(.dynasearchwrapper)),
.category:has(.item_look),
.homepage:not(:has(.dynasearchwrapper)) {

    #site_head_wrap {
        background-color: transparent;

        .categ_container {
            background-color: transparent;
        }

        &:has(.is-opened-menu),
        &:has(.is-opened-search),
        &.sticky,
        &:hover {
            background-color: $secondaryColor;

            .categ_container {
                background-color: $secondaryColor;
            }

            .categ_name span {
                color: var(--hovercolor, $primaryColor);

                &::before {
                    background-color: var(--hovercolor, $primaryColor);
                }
            }

            .headerLogo {
                background: var(--logo-color-home-scroll, $primaryColor);
            }

            .headerRollover .bgSprite::before {
                background: $primaryColor;
            }

            .basket_head .nbProd,
            .wish_head .nbProd {
                color: $secondaryColor;
            }

            .tablet_menu::before,
            .tablet_menu::after,
            .tablet_menu:not(.is-opened-menu) {
                background: $primaryColor;
            }

            .lang_switch {
                a {
                    &.active,
                    &:hover {
                        span:first-child {
                            background-color: $primaryColor;
                            color: $secondaryColor;
                            transition: background-color 0.3s;
                        }
                    }

                    span {
                        color: $primaryColor;

                        &:first-child {
                            background-color: $tertiaryColor;
                        }
                    }
                }
            }
        }

        .categ_name span {
            color: var(--color, $secondaryColor);


            &::before {
                background-color: var(--color, $secondaryColor);
            }
        }

        .headerLogo {
            background: var(--logo-color-page, $secondaryColor);
        }

        .headerRollover .bgSprite::before {
            background: $secondaryColor;
        }

        .basket_head .nbProd,
        .wish_head .nbProd {
            color: $primaryColor;
        }

        .tablet_menu::before,
        .tablet_menu::after,
        .tablet_menu {
            background: $secondaryColor;
            transition: all .2s ease;
        }

        .lang_switch {
            a {
                display: block;
                width: 24px;
                height: 24px;
                color: $secondaryColor;

                &.active,
                &:hover {
                    span:first-child {
                        background-color: $secondaryColor;
                        color: $primaryColor;
                        transition: background-color 0.3s;
                    }
                }

                span {
                    color: $primaryColor;

                    &:first-child {
                        background-color: $greyLabel;
                        color: $primaryColor;
                    }
                }
            }
        }
    }
}