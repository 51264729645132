// handle cases where the search returns no results when padding-top adjustment is needed
.homepage,
.body_login {
    .page_wrapper .dynasearchwrapper {
        padding-top: 125px;
    }
}

//specific to noresults case
.page_wrapper .dynasearchwrapper:has(.no_results_txt_container) {
    padding-top: 125px;
}
// end

.no_results_txt_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 836px;
    margin: 100px auto 0;
    @include fs-nrml-bold-title;
    text-align: center;
    
    span {
        @include fs-std-thin-text;
        margin: 15px 0 60px;
    }
}

.title_search {
    display: block;
    @include fs-big-bold-title;
    text-align: center;
}

.total_articles {
    display: flex;
    justify-content: space-between;
    width: 90%;
    max-width: 1280px;
    margin: 4px auto 16px;
    padding-bottom: 4px;
    border-bottom: 1px solid $primaryColor;
    @include fs-nrml-thin-label;
    color: $primaryColor;
    cursor: pointer;

    .nbResults {
        @include fs-std-thin-text;
        padding-right: 16px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: calc(100% - 9px);
            display: block;
            width: 9px;
            height: 9px;
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(24, $initialColSize, $initialRowSize);
            background: $primaryColor;
        }
    }
}

.wrap_search_field {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100px;
    max-height: 0;
    background: $secondaryColor;
    transition: max-height 0.2s ease;
    z-index: -1;

    .wrap_field {
        position: relative;
        width: 90%;
        max-width: 1280px;
        display: none;
    }

    .closeBtn {
        position: absolute;
        bottom: 8px;
        right: 0px;
        display: block;
        width: 9px;
        height: 9px;
        @include maskIconPath('../svg/spritesheet.svg');
        @include maskIconCoord(24, $initialColSize, $initialRowSize);
        background: $primaryColor;
    }

    .search_ok {
        display: none;
    }

    #search_home {
        width: 100%;
        border: none;
        border-bottom: 1px solid $greyLabel;
        @include fs-nrml-thin-label;
        color: $primaryColor;
        outline: none;
    }
    
    &.is-opened-search {
        opacity: 1;
        max-height: 100px;
        transition: max-height 0.2s ease;

        .wrap_search_field {
            z-index: 1;
        }

        .wrap_field {
            display: block;
        }
    }
}

.dynasearchwrapper {
    padding-top: 10px;

    .category_name {
        display: flex;
        justify-content: center;
        align-items: center;
        height: fit-content;
        background: $greyBorder;
        padding: 4px 9px;
        border: 1px solid $greyBorder;
        cursor: pointer;
        transition: border-color 0.3s;

        &:hover {
            border-color: $primaryColor;
        }
    }
}

.fullpage-loader {
    display: flex;
    justify-content: center;
    font-size: 0;

    span {
        position: relative;
        display: block;
        width: 217px;
        height: 40px;
        margin: 85px auto 60px;

        &::after {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            background: $primaryColor ;
            mask-image: url(../svg/three_dots.svg);
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: 50%;
            border: 1px solid $primaryColor;
        }
    }
}

.applyFilters {
    width: 100%;
    max-width: 350px;
}


// Filters Price range slider

.vue-slider {
    height: 2px !important;
    width: calc(100% - 50px) !important;
    margin: 1em auto;
}

.vue-slider-rail {
    width: calc(100% - 20px);
    height: 2px;
    background-color: $greyBorder;
}

.vue-slider-process {
    background-color: $primaryColor;
}

.vue-slider-dot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    width: 7px !important;
    height: 7px !important;
    background-color: $primaryColor;
    border-radius: 50%;
}

.vue-slider-dot-tooltip-top {
    top: 30px !important;
}

.vue-slider-dot-tooltip-text {
    @include fs-std-thin-text;

    &::after {
        content: '€';
    }
}