.cache {
    display: none;
}

.faq_main_container {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1295px;
    margin: auto;
    padding: 0 15px;
    box-sizing: border-box;

    .faq_container {
        width: 100%;
    }

    .main_title {
        font-family: $primaryFont;
        font-size: fs-vw(30, 1920);
        font-weight: 700;
        line-height: fs-vw(34, 1920);
        text-align: center;
        color: $primaryColor;
    }

    .site_search {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 34px;

        .w-input-container {
            display: flex;
            max-width: 1024px;
            max-height: 30px;
            border-bottom: 1px solid $greyLabel;
            padding: 8px 0;
        }

        #faq_search {
            height: auto;
            border: none;
            padding: 0;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        .search_results {
            position: absolute;
            top: 95%;
            z-index: 2;
            display: none;
            width: 100%;
            max-width: 1024px;
            max-height: 30rem;
            overflow-y: scroll;
            border: 1px solid black;
            border-top: none;
            background: $white;
            padding: 0 1em;
            transition: all 0.2s ease-out;
            padding: 0;

            li {
                display: block;
                font-family: $primaryFont;
                color: $primaryColor;
                font-size: 13px;
                font-weight: 400;
                cursor: pointer;
                line-height: 1rem;
                margin: auto 0;
                padding: 1rem 0 1rem 1rem;
                border-top: 1px solid $greyLabel;
                cursor: pointer;

                &:hover {
                    background-color: #f1f1f1;
                }

                &:first-of-type {
                    border: none;
                }
            }
        }

        // Scrollbar suggestion gérée nativement. 

        .search_results::-webkit-scrollbar {
            width: 1px;
        }

        .search_results::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.1);
        }

        .search_results::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.5);
        }

    }

    .theme_global {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        max-width: 965px;
        margin: 45px auto;

        .theme {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            background: $tertiaryColor;
            padding: 10px;
            margin: 10px;
            cursor: pointer;

            &.selected {
                border: 1px solid $primaryColor;
            }

            .intitule {
                font-family: $primaryFont;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                text-transform: uppercase;
                color: $primaryColor;
            }
        }
    }

    .question_container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-family: $primaryFont;
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid $primaryColor;
        cursor: pointer;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            width: 14px;
            height: 14px;
        }

        &.response_opened {
            &::after {
                transform: rotate(-90deg);
                transition: transform 0.2s linear;
            }
        }

        .faq_question {
            color: $primaryColor;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
        }

        .faq_reponse {
            color: $primaryColor;
            font-size: 15px;
            font-weight: 100;
            line-height: normal;
            margin-top: 12px;
            padding-right: 10px;
        }
    }
}