/* Calibrating wrapper */
.store_locator {

    .main_wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 1280px;
        width: 100%;
        margin: auto;

        >div {
            width: 100%;
        }
    }
}

// FADE IN EFFECT

.wrapper_store {
    opacity: 1;
    transition: opacity 1000ms ease-in-out;
}

.visible {
    opacity: 1;
}


// END FADE IN EFFECT

.wrapper_retailers_map {
    max-width: 1350px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 100px auto 565px;
    grid-template-areas:
        "title title"
        "forms forms"
        "map shops";
    margin-top: 20px;


    .img-loader-container {
        display: flex;
        place-items: center;
        justify-content: center;

        grid-area: shops;
        width: 100%;
        height: 100%;

        background: $white;


        img {
            width: 20%;
        }
    }

    .page-title {
        grid-area: title;
        width: 100%;
        text-align: center;
        height: fit-content;
        @include fs-big-bold-title;
        text-transform: initial;

        // Media queries for specific case tablet only
        @media only screen and (max-width: 1024px) {
            margin: 0 -3px;
        }
    }

    #retailers_map {
        grid-area: map;
        width: calc(100% - 32px) !important;
        grid-area: map;
        margin-left: 32px;
    }

    .storeloc_form {
        grid-area: forms;
        margin: 0 auto 56px;
        width: 90%;

        .zip_input {
            max-width: 1024px;
            width: 100%;
            margin: auto;
        }

        .wrap_fields {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
        }

        .field_country {
            display: none;
        }


        .field_address {

            display: flex;
            justify-content: center;
            width: 100%;
            border-bottom: solid 1px $primaryColor;


            .magnifying_glass {
                display: none;
            }

            #search_cp {
                display: flex;
                width: 100%;
                height: 40px;
                padding: 8px 0;
                border: none;
                outline: none;

                &::placeholder {
                    @include fs-std-light-text;
                    color: $primaryColor;
                }
            }
        }

        .ou {
            display: none;
        }

        #btn_geoloc {
            position: absolute;
            right: 0;
            display: flex;
            width: 30px;
            height: 30px;
            background-image: url("../svg/geoloc.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-color: transparent;
            cursor: pointer;
            border: none;
            box-shadow: none;

            span {
                display: none;
            }
        }
    }

    .section-list-store {
        grid-area: shops;
        max-width: 351px;
        max-height: 565px;
        margin-left: 49px;
        padding-top: 10px;
        overflow: hidden;
        box-sizing: border-box;

        .search-result {
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 22px;
            font-weight: 700;
            line-height: 1;
            margin-bottom: 25px;

            &.show {
                display: flex !important;
            }
        }

        .search-result-text {
            margin-right: 7px;
        }
    }

    .filtres_wrapper {
        display: none;
    }

    #store-container {
        height: 540px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 37px;

        /* scrollbar */
        &::-webkit-scrollbar {
            width: 2px;
        }

        &::-webkit-scrollbar-track {
            background: #D9D9D9;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: #2b2b2b;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        .section-list-store {
            max-width: 351px;
            margin-left: 49px;
        }

        .elem-list-store {
            display: flex;
            width: 100%;
            border-bottom: 1px solid $primaryColorSlime;
            padding-bottom: 20px;
            margin-bottom: 20px;
            cursor: pointer;

            // 2nd, 4th, 6th.. should have margin-right to 34px since they're the closest to the scrollbar
            &:nth-child(2n) {
                margin-right: 34px;
            }


            .elem_list_contents {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;

                .nom_store h2 {
                    @include fs-std-bold-title;
                    text-transform: initial;
                }

                .distance {
                    display: none;
                }

                .detail_store .col-1 {
                    display: flex;
                    flex-direction: column;

                    .rue_store,
                    .ville_store {
                        @include fs-std-thin-subtitle;
                    }

                    .tel_store {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        height: 100%;
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: clamp(12px, 0.729vw, 15px);
                        font-weight: $light;
                        line-height: normal;
                        text-decoration: underline;

                        &::before {
                            content: '';
                            display: block;
                            width: 14px;
                            height: 14px;
                            background-image: url("../svg/phone.svg");
                            background-size: contain;
                            background-repeat: no-repeat;
                            margin: 5px 5px 0 0;
                        }
                    }
                }

                .horaire_today {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;
                    font-family: $primaryFont;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: $primaryColor;
                    white-space: nowrap;
                    margin-top: 9px;
                    margin-bottom: 11px;

                    line-break: anywhere;
                    word-break: break-all;

                    .mag_open_status {
                        color: #04AC6C;
                        margin-right: 4px;
                        display: flex;
                        align-items: center;
                        line-height: normal;
                        font-weight: 100;

                        &+span {
                            @include fs-std-thin-subtitle;
                        }

                        &:before {
                            display: flex;
                            content: " ";
                            width: 7.14px;
                            height: 7.14px;
                            background-color: #04AC6C;
                            border-radius: 50%;
                            margin-right: 4px;
                        }
                    }

                    &.puce_store_closed {
                        color: red;
                        display: flex;
                        align-items: center;

                        &::before {
                            display: flex;
                            content: " ";
                            width: 7.14px;
                            height: 7.14px;
                            border-radius: 50%;
                            margin-right: 5px;
                            background-color: red;
                        }
                    }
                }


                .horaire_plus {
                    font-size: 13px;
                    line-height: 20px;
                    font-weight: 700;
                    font-family: $primaryFont;
                    text-transform: lowercase;

                }


                .store_more {
                    margin-top: 11px;
                    font-family: $primaryFont;
                    font-weight: 500;
                    font-size: 15px;
                    color: $primaryColor;

                    a {
                        @include secondaryButton(center, 4);
                    }
                }
            }
        }
    }
}

/* Détal storelocator */
.wrapper_magasin {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1403px;

    .title_store {
        font-family: $primaryFont;
        font-weight: 900;
        font-size: 100px;
        color: $primaryColor;
        text-transform: lowercase;
        margin: 19px 0 17px;
    }

    .store_return_link {
        display: flex;
        align-items: center;
        font-family: $primaryFont;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        text-decoration: none;
        text-transform: lowercase;
        position: relative;
        margin-bottom: 30px;

        &:before {
            content: " ";
            display: block;
            mask-image: url("../svg/spritesheet.svg");
            mask-size: 900%;
            mask-position: 96% 23%;
            mask-repeat: no-repeat;
            background-color: black;
            width: 16px;
            height: 10px;
            margin-right: 12px;
        }
    }

    .section_wrapper {
        display: flex;
        width: 100%;

        .bloc_details {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 55.52%;
            max-width: 779px;
            margin-right: 3.28%;

            .distribute_info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                padding: 45px 5% 31px;
                margin-bottom: 35px;
                border: solid 1px $primaryColor;

                .bloc_adr_horaires {
                    display: flex;
                    justify-content: flex-start;
                    width: 100%;

                    .distribute_adress {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        width: 100%;
                        padding-left: 3.25%;

                        @media only screen and (max-width: 1100px) {
                            padding-left: 0%;
                        }

                        .title_addr {
                            font-family: $primaryFont;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 27px;
                            color: $primaryColor;
                            text-transform: lowercase;
                            margin-bottom: 8px;
                        }

                        .addr_info {
                            font-family: $primaryFont;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 20px;

                            &.horaire_tip {
                                display: flex;
                                align-items: center;
                                color: $primaryColor;
                                margin: 9px 0 11px;

                                span {
                                    margin-right: 5px;

                                    &.puce {
                                        display: flex;
                                        background-color: $primaryColor;
                                        width: 7.14px;
                                        height: 7.14px;
                                        border-radius: 50%;
                                        margin-right: 5px;
                                    }

                                    &.store_close {
                                        color: red;

                                        &.puce {
                                            background-color: red;
                                        }
                                    }

                                    &.store_open {
                                        color: #04AC6C;

                                        &.puce {
                                            background-color: #04AC6C;
                                        }
                                    }
                                }
                            }

                            .holiday,
                            .special {
                                font-family: $primaryFont;
                                font-weight: 700;
                                font-size: 13px;
                                line-height: 20px;
                                text-transform: lowercase;
                            }
                        }

                        .bloc_horraire {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            width: 100%;
                            padding-left: 10%;

                            @media only screen and (max-width: 1100px) {
                                padding-left: 5%;
                            }

                            .title_horraires {
                                font-family: $primaryFont;
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 27px;
                                color: $primaryColor;
                                text-transform: lowercase;
                                margin-bottom: 8px;
                            }

                            .wrap_day_time {
                                display: flex;
                                justify-content: flex-start;
                                font-family: $primaryFont;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 20px;
                                text-transform: lowercase;
                                width: 100%;
                                margin-bottom: 0.5em;

                                .day {
                                    width: 67px;
                                    margin-right: 33px;
                                }
                            }
                        }
                    }

                    .wrap_cta {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        margin-top: 30px;

                        .form_submit {
                            max-width: 340px;

                            &:last-child {
                                margin-left: 2.38%;
                            }
                        }
                    }
                }
            }
        }

        .bloc_map {
            display: flex;
            width: 41.05%;
            max-width: 576px;

            #store_detail_map {
                display: flex;
                width: 100%;
            }
        }
    }

}

/**
 * Store details
 */

.satellite_store_details {
    position: relative;
}

.store_locator.details {
    position: relative;

    .innerWrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 50px;
    }

    a.go_store {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        font-family: $primaryFont;
        font-size: 12px;
        font-weight: 100;
        color: $primaryColor;
        text-decoration: none;

        &:before {
            content: '<';
            margin-right: .4em;
        }

        span {
            @include secondaryButton(center, 4);
        }

        &:hover span {
            text-decoration: none;
        }
    }

    .left_bloc {
        position: relative;
        order: 2;
        width: 27%;
        max-width: 565px;
        margin-left: 12%;
        margin-top: 3.6%;
        padding-bottom: 30px;

        .bloc_title {
            @include fs-std-bold-title;
            margin-bottom: 10px;
        }

        .store_direction {
            position: absolute;
            top: 100%;
            width: 100%;
            max-width: 472px;
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;

            .btn_send_info {
                display: flex;
                width: 36%;
                max-width: 168px;
                padding: 7px 0 10px;
                margin-right: 30px;
                justify-content: center;
                align-items: center;
                background: $primaryColor;
                cursor: pointer;
                border: none;
                box-shadow: none;
                border: 1px solid $primaryColor;
                transition: background-color .3s;

                h2 {
                    font-family: $primaryFont;
                    color: $white;
                    font-size: clamp(12px, fs-vw(15, 1920), 15px);
                    font-weight: 500;
                }

                &:hover {
                    background: $secondaryColor;

                    h2 {
                        color: $primaryColor;
                    }
                }
            }

            .btn_to_store {
                display: flex;
                width: 62%;
                max-width: 273px;
                padding: 10px 40px;
                justify-content: center;
                align-items: center;
                background: $tertiaryColor;
                border: 1px solid $tertiaryColor;
                cursor: pointer;
                font-family: $primaryFont;
                color: $primaryColor;
                font-size: clamp(12px, fs-vw(15, 1920), 15px);
                font-weight: 500;
                box-shadow: none;
                border: 1px solid $tertiaryColor;
                transition: background-color .3s;

                &:hover {
                    background: $secondaryColor;
                    border: 1px solid $primaryColor;

                    h2 {
                        color: $primaryColor;
                    }
                }

                h2 {
                    display: inline-block;
                    width: fit-content;
                    font-family: $primaryFont;
                    color: $primaryColor;
                    font-size: clamp(12px, fs-vw(15, 1920), 15px);
                    font-weight: 500;
                    text-transform: lowercase;
                    margin-top: -3px;

                    &::first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }

        .content_info {
            margin-top: 44px;
        }
    }


    .right_bloc {
        display: flex;
        flex-direction: column;
        order: 1;
        width: 50%;
        max-width: 960px;
    }

    #store_detail_map {
        order: 2;
        width: 100%;
        height: 450px;
    }

    .image {
        order: 1;

        img {
            width: 100%;
            height: auto;
            vertical-align: top;
        }
    }

    .store_infos .favorite {
        width: fit-content;
    }

    .title_details {
        @include fs-big-bold-title;
    }

    .favorite_store_button {
        display: none;
    }

    .storeAddress {
        @include fs-std-thin-subtitle;
        margin-top: 11px;

        .contact {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 15px;
            font-weight: $light;
            line-height: normal;
            text-decoration: underline;
            margin-top: 7px;

            &::before {
                content: '';
                display: block;
                width: 14px;
                height: 14px;
                background-image: url("../svg/phone.svg");
                background-size: contain;
                background-repeat: no-repeat;
                margin: 5px 5px 0 0;
            }
        }

        .openUntil {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            font-family: $primaryFont;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: $primaryColor;
            white-space: nowrap;
            margin-top: 9px;
            margin-bottom: 11px;

            line-break: anywhere;
            word-break: break-all;

            .mag_open_status {
                margin-right: 4px;
                display: flex;
                align-items: center;
                @include fs-std-thin-subtitle;
                color: #04AC6C;

                &+span {
                    @include fs-std-thin-subtitle;
                    font-size: 11px;
                }

                &:before {
                    display: flex;
                    content: " ";
                    width: 7.14px;
                    height: 7.14px;
                    background-color: #04AC6C;
                    border-radius: 50%;
                    margin-right: 4px;
                }
            }

            &.puce_store_closed {
                color: red;
                display: flex;
                align-items: center;

                &::before {
                    display: flex;
                    content: " ";
                    width: 7.14px;
                    height: 7.14px;
                    border-radius: 50%;
                    margin-right: 5px;
                    background-color: red;
                }
            }
        }

        .store_close {
            display: flex;
            color: red;
            align-items: center;
            margin: 5px 0;

            .puce.store_closed {
                display: flex;
                content: " ";
                width: 7.14px;
                height: 7.14px;
                border-radius: 50%;
                margin-right: 5px;
                background-color: red;
            }
        }
    }

    .content_services {
        padding: 22px 0 15px;
        border-bottom: 2px solid $primaryColor;
        box-sizing: border-box;

        .services_contents {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
        }

        .serviceItem {
            margin-right: 20px;
            margin-bottom: .5rem;
        }

        h3 {
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            font-size: clamp(12px, 0.729vw, 14px);
            font-weight: 700;

            &:before {
                content: '●';
                font-size: 8px;
                font-weight: 400;
                margin-right: 7px;
            }
        }
    }

    .schedule {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 22px 0 0;
        border-bottom: 1px solid $primaryColor;
        box-sizing: border-box;
        flex-direction: column;
    }

    .hor_left {
        width: 100%;
        margin-top: 7px;
    }

    .hor_right {
        width: 100%;
        margin-top: 10px;

        .horraire_spe {
            @include fs-std-thin-subtitle;
        }
    }

    .date_time {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: 21px;
        @include fs-std-thin-subtitle;

        .day {
            padding-right: 20px;
        }

        &.actualday {

            &.store_open {
                color: #04AC6C;
            }

            &.store_close {
                color: red;
            }
        }

        span {
            +span {
                white-space: nowrap;
                text-align: right;
                width: 74px;
                position: relative;
                right: 21%;
            }
        }
    }

    .holiday {
        @include fs-std-thin-subtitle;
        margin: 10px 0;
    }

    .content_descrip {
        padding-top: 21px;

        .description {
            @include fs-std-thin-subtitle;
        }
    }

    #descrip_store {
        position: relative;
        font-size: clamp(12px, 0.729vw, 14px);
        letter-spacing: .05em;
        line-height: 1.5;

        .trigger {
            display: none;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: #fff;
            font-size: clamp(12px, 0.677vw, 13px);
            font-weight: 500;
            text-decoration: underline;
            padding-top: 15px;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }
    }


    .description {
        overflow: hidden;
    }

    .store_img {
        position: relative;
    }

    #store_slider img {
        width: 100%;
        height: auto;
        vertical-align: top;
    }

}

/* STORE DETAIL LIGHTBOX */

#ligthbox_send_store_information {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 13;
    width: clamp(500px, fs-vw(700, 1920), 700px);
    padding: 40px 25px;
    background: $white;
    box-sizing: border-box;

    .header_lightbox {
        text-align: center;

        span.close.croix {
            position: absolute;
            top: .5rem;
            right: .5rem;
            width: 15px;
            height: 15px;
            cursor: pointer;
            transform: rotate(45deg);
            z-index: 110;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 6px;
                width: 1px;
                height: 13px;
                display: block;
                background: #000;
            }

            &:after {
                content: '';
                position: absolute;
                top: 6px;
                left: 0;
                width: 13px;
                height: 1px;
                display: block;
                background: #000;
            }
        }

        .title {
            font-family: $primaryFont;
            font-size: 20px;
            font-weight: 700;
            line-height: 1;
            color: $primaryColor;
            margin-bottom: 20px;

        }
    }

    .form_item {
        margin-top: 1rem;

        input {
            box-sizing: border-box;
            height: 3rem;
            margin-top: 0.25rem;
            margin-bottom: 1.5rem;
        }
    }

    .form_submit .button {
        width: 50%;
        margin: 0 auto;
    }

    p.input_label {
        margin: 1rem 0 0.5rem;
    }

    .w-form-line.w-submit {
        max-width: 100%;
        margin: 0 auto;

        .w-btn-wrapper {
            .w-submit-button {
                background: $primaryColor;
                border: 1px solid $primaryColor;
                color: $white;
                cursor: pointer;
                transition: all .2s ease-out;

                &.w-loader {
                    position: absolute;
                    right: 0;
                    background: $white url(../svg/dots_white.svg) no-repeat center;
                    background-size: 25%;

                    span {
                        font-size: 0;
                    }
                }

                &:hover {
                    background: $white;
                    color: $primaryColor;
                }

                span {
                    font-family: $primaryFont;
                    font-size: 15px;
                    font-weight: 100;
                    line-height: 1;
                    text-transform: uppercase;
                }
            }
        }
    }

    .w-sendshop-form p:not(.w-input-label) {
        font-family: $primaryFont;
        font-size: 15px;
        font-weight: 100;
        line-height: 1;
        text-align: center;
        margin: 1rem 0 1.5rem;
    }
}



// POPUP MAP

.gm-style-iw-chr {
    display: none;
}

.gm-style .gm-style-iw-c {
    .info_popup {
        position: relative;
        display: block;
        /* Assuming you need a display property even if not specified */
        width: 280px;
        padding: 35px 0 0 10px;
        font-family: $primaryFont;
        box-shadow: none;

        .name a {
            display: flex;
            width: 100%;
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
            color: $primaryColor;
            box-shadow: none;
            outline: none;
        }

        .adresse {
            font-size: 13px;
            font-weight: 100;
            line-height: normal;
            color: $primaryColor;
        }

        .horaire_title {
            display: flex;
            margin-top: 5px;
        }

        .store_open {
            display: none;
        }

        .mag_open_status {
            display: flex;
            align-items: center;
            font-size: 11px;
            font-weight: 100;
            line-height: normal;
            color: #04AC6C;
            margin-right: 4px;

            &+span {
                font-size: 11px;
                font-weight: 100;
                line-height: normal;
            }

            &:before {
                display: flex;
                content: " ";
                width: 7.14px;
                height: 7.14px;
                background-color: #04AC6C;
                border-radius: 50%;
                margin-right: 4px;
            }
        }

        .btn_voir_magasin,
        .horaire_info,
        .coord,
        .dist {
            display: none;
        }

        &::before {
            position: absolute;
            bottom: 80%;
            left: 50%;
            content: '';
            display: block;
            width: 17px;
            height: 20px;
            background-image: url("../img/maps/marker.png");
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}

.gm-style-iw-tc {
    display: none;
}


// END POPUP MAP