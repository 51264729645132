/* SET FONTS */

/* REPLACE EXEMPLE BY YOUR TYPOGRAPHY NAME */
/* Your fonts must be in the app/fonts folder */

$primaryFont: 'halyard-text', sans-serif;

//FONT-WEIGHT SETUP -
$thin : 100;
$extralight : 200;
$light : 300;
$regular : 400;
$medium : 500;
$semibold : 600;
$bold : 700;
$extrabold : 800;

$fsSmlText: 12px;
$fsStdText: 15px;

$fsSmlSubTitle: 11px;
$fsStdSubTitle: 13px;

$fsStdTitle: 16px;
$fsNrmlTitle: 22px;
$fsBigTitle: 30px;

$fsSmlLabel : 10px;
$fsStdLabel : 14px;
$fsNrmlLabel : 18px;


//MIXXIN for global fonts uses

/** TITLES **/
@mixin fs-std-thin-title {
    font-family: $primaryFont;
    font-size: $fsStdTitle;
    font-weight: $thin;
    line-height: normal;
    color: $primaryColor;
}

@mixin fs-std-regular-title {
    font-family: $primaryFont;
    font-size: $fsStdTitle;
    font-weight: $regular;
    line-height: normal;
    color: $primaryColor;
}

@mixin fs-std-medium-title {
    font-family: $primaryFont;
    font-size: $fsStdTitle;
    font-weight: $medium;
    line-height: normal;
    color: $primaryColor;
}

@mixin fs-std-bold-title {
    font-family: $primaryFont;
    font-size: $fsStdTitle;
    font-weight: $bold;
    line-height: normal;
    color: $primaryColor;
}

@mixin fs-nrml-bold-title {
    font-family: $primaryFont;
    font-size: $fsNrmlTitle;
    font-weight: $bold;
    line-height: normal;
    color: $primaryColor;
}

@mixin fs-big-bold-title {
    font-family: $primaryFont;
    font-size: $fsBigTitle;
    font-weight: $bold;
    line-height: normal;
    color: $primaryColor;
}

/** SUBTITLES **/
@mixin fs-sml-thin-subtitle {
    font-family: $primaryFont;
    font-size: $fsSmlSubTitle;
    font-weight: $thin;
    line-height: normal;
    color: $primaryColor;
}

@mixin fs-sml-medium-subtitle {
    font-family: $primaryFont;
    font-size: $fsSmlSubTitle;
    font-weight: $medium;
    line-height: normal;
    color: $primaryColor;
}

@mixin fs-std-thin-subtitle {
    font-family: $primaryFont;
    font-size: $fsStdSubTitle;
    font-weight: $thin;
    line-height: normal;
    color: $primaryColor;
}

@mixin fs-std-light-subtitle {
    font-family: $primaryFont;
    font-size: $fsStdSubTitle;
    font-weight: $light;
    line-height: normal;
    color: $primaryColor;
}

@mixin fs-std-regular-subtitle {
    font-family: $primaryFont;
    font-size: $fsStdSubTitle;
    font-weight: $regular;
    line-height: normal;
}

@mixin fs-std-medium-subtitle {
    font-family: $primaryFont;
    font-size: $fsStdSubTitle;
    font-weight: $medium;
    line-height: normal;
    color: $primaryColor;
}

@mixin fs-std-bold-subtitle {
    font-family: $primaryFont;
    font-size: $fsStdSubTitle;
    font-weight: $bold;
    line-height: normal;
    color: $primaryColor;
}

/** TEXT **/
@mixin fs-sml-thin-text {
    font-family: $primaryFont;
    font-size: $fsSmlText;
    font-weight: $thin;
    line-height: normal;
    color: $primaryColor;
}

@mixin fs-std-thin-text {
    font-family: $primaryFont;
    font-size: $fsStdText;
    font-weight: $thin;
    line-height: normal;
    color: $primaryColor;
}

@mixin fs-std-light-text {
    font-family: $primaryFont;
    font-size: $fsStdText;
    font-weight: $light;
    line-height: normal;
    color: $primaryColor;
}

@mixin fs-std-regular-text {
    font-family: $primaryFont;
    font-size: $fsStdText;
    font-weight: $regular;
    line-height: normal;
    color: $primaryColor;
}

@mixin fs-std-medium-text {
    font-family: $primaryFont;
    font-size: $fsStdText;
    font-weight: $medium;
    line-height: normal;
    color: $primaryColor;
}

/** FORMS / LABELS / CTAs if not created above or specific **/
@mixin fs-sml-bold-label {
    font-family: $primaryFont;
    font-size: $fsSmlLabel;
    font-weight: $bold;
    line-height: normal;
}

@mixin fs-std-thin-label {
    font-family: $primaryFont;
    font-size: $fsStdLabel;
    font-weight: $thin;
    line-height: normal;
}

@mixin fs-std-regular-label {
    font-family: $primaryFont;
    font-size: $fsStdLabel;
    font-weight: $regular;
    line-height: normal;
}

@mixin fs-nrml-thin-label {
    font-family: $primaryFont;
    font-size: $fsNrmlLabel;
    font-weight: $thin;
    line-height: normal;
}
