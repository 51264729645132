//GLOBAL CLOSE POP UP STYLING
.remove,
.close_pop {
    position: absolute;
    top: 18px;
    right: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 1;

    span {
        font-size: 0;
    }

    &.bgSprite.bfr::before,
    &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 11px;
        height: 11px;
        @include maskIconPath('../svg/spritesheet.svg');
        @include maskIconCoord(24, $initialColSize, $initialRowSize);
        background-color: $primaryColor;
    }
}

/* COOKIES LIGHTBOX */

#cookiesLightbox {
    left: 50%;
    transform: translateX(-50%);
    font-family: $primaryFont;
    padding: 12px 0;
    max-width: 1819px;
    width: 95%;


    .cookiesLightbox_container {
        display: flex;
        width: 95%;
        max-width: 1292px;
        margin: auto;
        flex-wrap: wrap;

        .cookiesLightbox_content {
            width: 100%;
            max-width: 100%;
            margin: 0 0 15px;
            padding-right: 200px;
            font-weight: $thin;
            color: $primaryColor;

            span {
                display: block;
                margin: 0 0 10px;
                font-weight: $bold;
            }
        }

        .cookiesLightbox_preferences {
            width: 100%;
            max-width: 100%; //override app
            margin: 0;
            text-decoration: none;

            .cookiesInfos {
                margin: 0;
                padding-right: 180px;
            }

            .h4_style {
                .icon {
                    display: inline!important;
                }
            }
        }

        .w-btn-wrapper {
            width: fit-content;
        }

        .cookiesLightbox_custom_btn {
            height: fit-content;
            margin-left: 0;
            background-color: transparent;
            color: $primaryColor;

            .w-submit-button {
                border: none;
                text-transform: none;
                font-weight: normal;
                font-size: 13px;
                margin-top: 0;
                background-color: $white;
                color: $primaryColor;

                &:hover {
                    background-color: transparent;
                    color: $primaryColor;
                }

                &::after {
                    display: none;
                }

                &.w-loader {
                    display: none;
                }
            }
        }

        .cookiesLightbox_refuse_btn {
            order: 1;
            height: fit-content;
            margin-left: 45px;

            .w-submit-button.w-loader {
                display: none;
            }
        }

        .cookiesLightbox_accept_btn {
            position: absolute;
            bottom: 20px;
            right: clamp(5%, 3.84vw, 70px);
            width: 161px;
            border: 1px solid $primaryColor;

            .w-submit-button {
                width: 100%;
                border: none;

                &.w-loader {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;

                    // display: none;
                    span {
                        position: relative;
                        display: block;
                        width: 100%;
                        height: 100%;
                        font-size: 0;
                        background-color: $secondaryColor;

                        &::after {
                            content: "";
                            position: absolute;
                            display: block;
                            width: 100%;
                            height: 100%;
                            background: $primaryColor ;
                            mask-image: url(../svg/three_dots.svg);
                            mask-repeat: no-repeat;
                            mask-position: center;
                            mask-size: 25%;
                            border: 1px solid $primaryColor;
                        }
                    }
                }
            }
        }
    }

    #jsCookieCustom {
        position: relative;
        height: fit-content;
        width: fit-content;
        text-decoration: none;
        margin-top: 10px;

        span {
            @include secondaryButton(left, 4);
        }
    }

    #js_cookie_refuse {
        display: flex;
        border: none;
        text-transform: none;
        font-weight: 400;
        font-size: 13px;
        background-color: transparent;
        color: $primaryColor;

        &:hover {
            background-color: transparent;
            color: $primaryColor;
        }

        position: relative;
        height: fit-content;
        width: fit-content;
        text-decoration: none;
        margin-top: 10px;

        span {
            @include secondaryButton(left, 4);
        }
    }

    #js_cookie_accept {
        background-color: $primaryColor;
        color: $secondaryColor;

        &:hover {
            background-color: $secondaryColor;
            color: $primaryColor;
        }
    }
}

/* END COOKIES LIGHTBOX */

// LIGHTBOX EN SAVOIR PLUS

.lightbox {
    position: absolute;
    z-index: 13;
    display: none;

    &#popup_savoir_plus {
        top: 20%;
        left: 30%;
        text-align: center;
        width: 40rem;
        padding: 4rem;
        background: $white;

        .title {
            text-transform: initial;
            font-family: $primaryFont;
            font-weight: 700;
            font-size: 1rem;
            letter-spacing: normal;
            color: $primaryColor;
            margin-bottom: 2rem;
        }

        #bottom_savoir_plus {
            font-family: $primaryFont;
            font-weight: 400;
            font-size: 15px;
        }

        .close:after {
            content: '\00d7';
            right: 5%;
            top: 5%;
            font-size: 1.7rem;
            position: absolute;
            cursor: pointer;
            color: $primaryColor;
        }

        #bottom_savoir_plus {
            font-size: 0.875rem;

            br {
                content: '';
                display: block;
                margin-top: 1rem;
            }
        }
    }

    &#mention_legales_pop_up {
        position: fixed;
        z-index: 500;
        display: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $white;
        opacity: 0;
        transition: all .3s ease-in-out;
        width: 450px;
        min-height: 200px;
        padding: 2em;
        box-sizing: border-box;

        &.actif {
            display: block;
            opacity: 1;
            transition: all .3s ease-in-out;
        }
    }

    /* CART SIZE INFO */
    &#cartSizeInfoLightbox {
        width: 658px;
        height: 340px;
        position: fixed;
        z-index: 100;
        left: 0;
        right: 0;
        top: 46%;
        transform: translateY(-50%);
        margin: auto;
        background: $white;
        padding: 2rem 2.5rem;

        /* On change le display en flex pour faciliter l'affichage */
        &.actif {
            display: flex !important;
            align-items: center;
            justify-content: center;
        }

        .content {
            text-align: center;
        }

        p.title {
            @include fs-std-bold-title;
            text-transform: uppercase;
            margin-bottom: 20px;
        }

        p.doubt_size_txt {
            @include fs-std-thin-text;
            text-transform: none;
            margin-bottom: 15px;
            color: black;

            a.address {
                font-style: normal;
                font-weight: 400;
            }
        }

        .form_submit {
            display: flex;
            justify-content: center;
            max-width: 250px;
            width: 90%;
            margin: 20px auto 0;

            .button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 40px;
                background-color: $primaryColor;
                border: 1px solid $primaryColor;
                font-family: $primaryFont;
                font-size: 15px;
                font-weight: 500;
                font-style: normal;
                line-height: 1;
                color: #fff;
                box-sizing: border-box;
                cursor: pointer;
            }

            &:hover {

                .button {
                    background-color: transparent;
                    color: $primaryColor;
                }
            }

            .cartLoader {
                display: none;
            }
        }
    }

    &#deletedCouponStay,
    &#deletedCoupon {
        width: 658px;
        height: 340px;
        position: fixed;
        z-index: 100;
        left: 0;
        right: 0;
        top: 46%;
        transform: translateY(-50%);
        margin: auto;
        background: $white;
        padding: 2rem 2.5rem;

        /* On change le display en flex pour faciliter l'affichage */
        &.actif {
            display: flex !important;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        .text {
            text-align: center;
            margin-bottom: 30px;
        }
    }
}

//  LIGHTBOX EN sAVOIR PLUS

//ERESA

.tablet {
    #e-resaBox {
        width: 50%;
        max-width: 400px;
    }
}

#e-resaBox {
    width: 25%;
    max-width: 350px;
    height: 100vh;
    transform: none;
    right: 0;
    top: 0;
    margin: auto;
    padding: 30px 25px 20px 25px;
    box-sizing: border-box;
    overflow-y: auto;
    background: $white;
    position: fixed;

    &.actif {
        display: block;
    }

    .w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox]),
    .w-nonempty:not(.no-label) input[type=email].w-input-element {
        height: 40px;
    }

    #content_e-resaBox {
        display: flex;
        flex-direction: column;
        width: 100%;

        .popup_header {
            width: 100%;
            max-width: 100%;
        }

        .popup_title {
            display: flex;
            align-items: center;
            justify-content: center;
            @include fs-nrml-bold-title;
            text-align: center;
        }

        .popup_desc {
            display: block !important;
            @include fs-std-thin-text;
            text-align: center;
            margin-top: 7px;
        }

        .steps_num {
            display: flex;
            margin: 25px 0 15px;


            .step_num {
                display: none;
                position: relative;
                @include fs-nrml-bold-title;
                font-size: 16px;

                &.actif {
                    display: flex;
                }
            }
        }

        .step {
            display: none;
            width: 100%;
            margin: 0 auto;

            &.loading {
                background: url('../img/Ellipsis-1.8s-40px.gif') center center no-repeat;

                .step {
                    opacity: 0;
                }
            }

            &.actif {
                display: block;

                .step_content {
                    opacity: 1;
                }
            }

            .step_content {
                opacity: 0;
                transition: opacity 0.2s ease-in;

                .eresa_container_img {
                    display: flex;
                    justify-content: center;
                    width: 90px;
                    min-width: 80px;
                    height: auto;
                    overflow: hidden;

                    img {
                        width: 120%;
                        height: auto;
                    }
                }

                .eresa_pdt_description {
                    margin-left: 2rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .product_name,
                    .product_subname {
                        font-family: $primaryFont;
                        text-transform: uppercase;
                    }

                    .product_name {
                        font-size: 24 / 16 * 1rem;
                        line-height: 1.3;
                        font-weight: 600;
                    }

                    .product_subname {
                        font-size: 14 / 16 * 1rem;
                        line-height: 1.3;
                        margin: 0.25rem 0;
                    }
                }

                .product_info {
                    .size-color-wrap {
                        display: flex;
                        flex-direction: column-reverse;
                    }

                    .ligne_form {
                        width: 100%;
                        margin: 0.25rem 0;

                        .prod_listes {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            width: 100%;

                            .titre_couleur,
                            .titre_taille {
                                font-family: $primaryFont;
                                font-size: 1rem;
                                line-height: 1.3;
                                text-transform: none;
                                margin-right: 10px;
                                font-weight: 300;

                                &:after {
                                    content: ':';
                                }
                            }

                            .choices_list {
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;

                                .form_itm {
                                    label {
                                        font-family: $primaryFont;
                                        font-weight: 600;
                                        font-size: 15px;
                                        line-height: 1;

                                        &:before,
                                        &:after {
                                            display: none;
                                        }
                                    }

                                    [type='radio']:checked+label,
                                    [type='radio']:not(:checked)+label {
                                        margin: 0;
                                    }

                                    [type='radio']:checked+label:after,
                                    [type='radio']:not(:checked)+label:after {
                                        display: none;
                                    }

                                    &.color {
                                        margin-right: 0.5rem;

                                        label {
                                            display: none;
                                        }

                                        &:after {
                                            content: attr(data-color);
                                            text-transform: capitalize;
                                        }
                                    }

                                    &.size {
                                        margin-right: 0.5rem;

                                        label {
                                            color: $primaryColor;
                                            margin-left: 0 0 0 15px;
                                            position: relative;
                                        }

                                        [type='radio']:checked,
                                        [type='radio']:not(:checked) {
                                            &.disabled {
                                                &+label {
                                                    color: $primaryColor;
                                                }
                                            }

                                            &+.no_stock {
                                                color: $primaryColor;
                                            }
                                        }

                                        [type='radio']+label {
                                            position: relative;
                                            border: 1px solid transparent;
                                            padding: 1px;
                                            min-width: 20px;
                                            height: 20px;
                                            box-sizing: border-box;

                                            span {
                                                width: 100%;
                                                text-align: center;
                                            }
                                        }

                                        [type='radio']:not(:checked)+label {
                                            opacity: 0.6;
                                        }

                                        [type='radio']:checked+label {
                                            opacity: 1;
                                            border-color: $primaryColor;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .store_info {
                position: relative;
                color: $primaryColor;

                .distance_store {
                    display: none;
                }

                .name_store {
                    @include fs-std-bold-title;
                }

                .adresse_store {
                    @include fs-std-thin-text;
                }

                .phone_store {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 100%;
                    color: $primaryColor;
                    font-family: $primaryFont;
                    font-size: clamp(12px, 0.729vw, 15px);
                    font-weight: $light;
                    line-height: normal;
                    text-decoration: underline;

                    &::before {
                        content: '';
                        display: block;
                        width: 14px;
                        height: 14px;
                        background-image: url("../svg/phone.svg");
                        background-size: contain;
                        background-repeat: no-repeat;
                        margin: 5px 5px 0 0;
                    }
                }

                .js_have_horaire_store {
                    display: block;
                    @include fs-std-bold-subtitle;
                    font-size: 15px;
                    margin-top: 5px;
                }

                .schedule_store {
                    @include fs-std-light-text;
                    height: 0;
                    overflow: hidden;
                    margin-top: 0.2em;
                    transition: height 0.3s ease-in;

                    &.actif {
                        height: auto;
                    }
                }

                .quantity_store {
                    position: absolute;
                    top: 67px;
                    right: 0;
                    width: fit-content;
                    font-family: $primaryFont;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: $primaryColor;

                    .article_disponible {
                        position: relative;
                        margin-right: 4px;
                        @include fs-std-thin-subtitle;

                        &:before {
                            position: absolute;
                            top: 35%;
                            left: -10px;
                            display: flex;
                            content: " ";
                            width: 7.14px;
                            height: 7.14px;
                            background-color: #04AC6C;
                            border-radius: 50%;
                            margin-right: 4px;
                        }
                    }

                    .article_non_disponible {
                        position: relative;
                        margin-right: 4px;
                        @include fs-std-thin-subtitle;

                        &::before {
                            position: absolute;
                            top: 35%;
                            left: -10px;
                            display: flex;
                            content: " ";
                            width: 7.14px;
                            height: 7.14px;
                            border-radius: 50%;
                            margin-right: 5px;
                            background-color: red;
                        }
                    }
                }

                .opening_until {
                    // Style inline
                    display: flex !important;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;
                    font-family: $primaryFont;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: $primaryColor;
                    white-space: nowrap;
                    margin-top: 9px;
                    line-break: anywhere;
                    word-break: break-all;

                    .mag_open_status {
                        margin-right: 4px;
                        display: flex;
                        align-items: center;
                        @include fs-std-thin-subtitle;
                        color: #04AC6C;

                        &+span {
                            @include fs-std-thin-subtitle;
                            font-size: 11px;
                        }

                        &:before {
                            display: flex;
                            content: " ";
                            width: 7.14px;
                            height: 7.14px;
                            background-color: #04AC6C;
                            border-radius: 50%;
                            margin-right: 4px;
                        }
                    }

                    .txt_store_closed {
                        color: red;
                        display: flex;
                        align-items: center;

                        &::before {
                            display: flex;
                            content: " ";
                            width: 7.14px;
                            height: 7.14px;
                            border-radius: 50%;
                            margin-right: 5px;
                            background-color: red;
                        }
                    }
                }
            }


            .js_store_report {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                padding: 0 15px;
                font-family: $primaryFont;
                font-weight: 400;
                font-size: 1rem;
                text-align: center;
            }

            .form_submit {
                width: 100%;
                margin-top: 10px;

                .button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 40px;
                    background-color: $primaryColor;
                    border: 1px solid $primaryColor;
                    font-family: $primaryFont;
                    font-size: 15px;
                    font-weight: 500;
                    font-style: normal;
                    line-height: 1;
                    color: #fff;
                    box-sizing: border-box;
                    cursor: pointer;
                }

                &:hover {

                    .button {
                        background-color: transparent;
                        color: $primaryColor;
                    }

                }
            }

            &#step1 {
                .step_content {
                    display: flex;
                    max-width: 90%;
                    padding: 2% 10%;
                    margin: 0 auto;
                    background: $primaryColor;
                }

                .infos {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    margin: 2rem 0;

                    .form_submit {
                        width: 100%;
                        max-width: initial;
                    }
                }
            }

            &#step2 {
                .w-input-container.country {
                    display: none;
                }

                .w-input-container.adresse {
                    position: relative;
                    margin: 0;

                    .w-address-input {
                        position: relative;

                        .loc_btn {
                            position: absolute;
                            content: '';
                            background: url('../img/maps/marker.png') no-repeat 50% 50%;
                            background-size: 60% auto;
                            width: 24px;
                            height: 32px;
                            right: 0.5rem;
                            top: 0;
                            bottom: 0;
                            margin: auto 0;

                            /* IE10+ */
                            @media all and (-ms-high-contrast: none),
                            (-ms-high-contrast: active) {
                                background-size: 58% auto;
                            }
                        }
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 5px;
                        width: 19px;
                        height: 20px;
                        @include maskIconPath('../svg/spritesheet.svg');
                        @include maskIconCoord(23, $initialColSize, $initialRowSize);
                        background-color: $primaryColor;
                    }
                }

                .store_locator {
                    height: 62vh;
                    width: 100%;
                    overflow-y: auto;
                    overflow-x: hidden;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 0.3s ease-in;

                    &.loaded {
                        opacity: 1;
                        visibility: visible;
                    }

                    .store {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: 20px;
                        padding-bottom: 20px;
                        border-bottom: 1px solid $primaryColorSlime;

                        &:nth-last-child(0),
                        &:last-child {
                            border: none;
                        }

                        .store_info {
                            width: 100%;
                        }
                    }

                    .form_submit {

                        .button.loader {
                            display: none;
                        }
                    }
                }

                .store_locator.loaded::-webkit-scrollbar {
                    display: none;
                }

                .store_locator.loaded {
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                    overflow: auto;
                }

            }

            &#step3 {
                .w-form-line {
                    flex-wrap: wrap;
                    margin-bottom: 0;

                    .w-input-container {
                        margin: 10px 0;
                    }

                    .w-input-label::before {
                        height: 15px;
                        width: 15px;
                    }

                    .w-input-note {
                        font-size: 0.65rem;
                        margin: 0.0333em 0 0.66667em;
                    }

                    &.optin {
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: 0;

                        >label {
                            margin-bottom: 20px;
                        }

                        .w-radio-group {

                            .w-radio-input .w-input-element+.w-input-label span {
                                @include fs-std-thin-text;
                                padding-bottom: 5px;
                                margin-left: 0;
                            }
                        }
                    }

                    &.utilisation_condition {
                        text-align: center;
                        margin: 25px auto;
                        display: flex;
                        justify-content: flex-start;

                        >span {
                            margin: 6px 0 0 20px;
                        }

                        .w-radio-group {
                            justify-content: center;

                            .w-radio-input {
                                display: flex;
                                align-items: center;
                                position: relative;
                                width: auto;
                                height: 1em;
                                border: none;

                                .w-input-element+.w-input-label::before {
                                    border-radius: 0;
                                }
                            }

                        }

                        .w-input-label span {
                            @include fs-std-thin-text;
                        }

                        span {
                            @include fs-std-thin-text;

                            a {
                                @include fs-std-thin-text;
                                position: relative;
                                left: auto;
                                top: -0.5px;
                            }
                        }
                    }

                    .errormsgadd {
                        display: none;
                    }
                }

                .form-eresa-civility {
                    margin: 10px 0;
                }

                .w-input-container+.w-input-container {
                    margin-left: 0;
                }

                .infos {
                    @include fs-sml-thin-text;
                    margin-top: 30px;
                }

                #error_limit_quota small {
                    text-align: left;
                    color: red;
                }

            }

            &#step4 {
                .confirm {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;

                    .confirm_info {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include fs-std-thin-text;
                        margin-bottom: 35px;
                        width: 100%;
                        text-align: center;
                    }

                    .eresa_recap {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex-direction: column;
                        width: 100%;

                        .eresa_choices {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            width: 100%;
                            max-width: 260px;

                            .eresa_prod {
                                display: flex;
                                align-items: flex-start;
                                justify-content: flex-start;
                                margin-bottom: 25px;

                                img {
                                    width: 100%;
                                    max-width: 85px;
                                    height: auto;
                                    margin-right: 0.2rem;
                                }

                                .eresa_prod_detail {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;
                                    justify-content: flex-start;
                                    width: calc(100% - 79px);

                                    .prod_title {
                                        @include fs-std-medium-subtitle;
                                    }

                                    .titre_quantity {
                                        //style inline
                                        display: block !important;
                                    }

                                    .titre_couleur,
                                    .titre_taille,
                                    .titre_quantity,
                                    strong {
                                        @include fs-sml-thin-subtitle;
                                    }

                                    .titre_couleur {
                                        margin-bottom: 2px;
                                    }

                                    .item_price_wrapper {
                                        margin: 0.25rem 0 4px;

                                        .item_price {
                                            display: block;
                                        }

                                        .pricetag {
                                            @include fs-std-thin-text;
                                        }
                                    }
                                }
                            }

                            #js_eresa_store_choice {
                                .store {
                                    font-size: 0.8em;

                                    .form_submit {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .eresa_procedure {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            width: 100%;
                            border-top: 1px solid $primaryColor;
                            padding-top: 30px;
                            margin-top: 30px;

                            .eresa_procedure_title {
                                @include fs-std-bold-title;
                                text-transform: uppercase;
                                width: 100%;
                            }

                            .eresa_procedure_content {
                                font-family: $primaryFont;
                                font-weight: 100;
                                font-size: 15px;
                                line-height: normal;
                                color: $primaryColor;
                                margin-bottom: 24px;
                                width: 100%;
                                white-space: normal;
                            }

                            .form_submit {
                                margin: 10px 0 0;
                            }
                        }
                    }
                }

                .error {
                    text-align: center;
                    color: red;
                }

                .quantity_store {
                    display: none;
                }

                .form_submit {
                    margin-top: 50px;
                }
            }
        }
    }

    #form_eresa {
        max-width: 675px;
        margin: 0 auto;

        .infos,
        #error_limit_quota small {
            font-family: $primaryFont;
            font-weight: 400;
            font-size: 11px;
            line-height: 14px;
            color: $primaryColor;
            width: 100%;
        }

        .w-form-line {
            &.optin {
                display: flex;
                margin-top: 15px;
                margin-bottom: 8px;

                >label {
                    display: flex;
                    @include fs-std-thin-text;
                    margin: 0 10px 0 0;
                    position: relative;
                }
            }

            .checkbox-group {
                display: flex;
                flex-wrap: wrap;

                .w-form-line {
                    justify-content: space-between;
                    max-width: 190px;
                    width: 100%;

                    >span {
                        @include fs-std-thin-text;
                        margin: 0 1.3rem 0 0;

                        &:after {
                            content: ':';
                        }
                    }

                    &:nth-child(2) {
                        margin-top: 5px;
                    }
                }
            }

            &.w-submit {
                position: absolute;
                bottom: 20px;
                width: 100%;
                max-width: 310px;
                height: 50px;

                button {
                    width: 100%;
                }
            }

            .w-input.w-tel-input {

                .active {
                    display: block;
                }

                .w-input-label {
                    left: 3rem;
                }

                .selected-flag {
                    background-color: $white;
                    border: none;
                    padding-left: 0;
                }

                .country-list {
                    max-height: 140px;
                }
            }
        }

        &:has(.legal-note) {
            .legal-note {
                margin-top: 10px;

                & + .infos {
                    margin-top: 15px!important;
                }
            }

            .optin {
                margin-top: 10px!important;

                & > label {
                    margin-bottom: 10px!important;
                }
            }

            .utilisation_condition {
                margin: 10px auto!important;

                .w-input-label span,
                span,
                span a {
                    font-size: 12px!important;
                }

                & > span {
                    margin: 0 0 0 17px!important;
                }
            }
        }
    }
}

// END ERESA

// PRECO

#lightbox_preco {
    width: 25%;
    max-width: 350px;
    height: 100vh;
    transform: none;
    right: 0;
    top: 0;
    margin: auto;
    padding: 30px 20px 20px 20px;
    box-sizing: border-box;
    overflow-y: auto;
    background: $white;

    &.actif {
        display: block;
    }

    .title_lightbox_preco {
        display: block;
        width: 100%;
        @include fs-nrml-bold-title;
        text-align: center;
    }

    .t_lightbox_preco {
        @include fs-std-thin-text;
        text-align: center;
    }

    .blocPrecoWrapper {
        margin-top: 30px;
    }

    .bloc_lightbox_preco {
        position: relative;

        &:before {
            content: '';
            width: 15px;
            height: 15px;
            border: 1px solid $primaryColor;
            border-radius: 50%;
            background: $primaryColor;
            box-shadow: inset 0 0 0 16px $white;
            position: absolute;
            top: 15%;
            left: 0;
            transform: translateY(-50%);
            cursor: pointer;
        }

        &:hover,
        &.actif {
            border-color: $primaryColor;

            &:before {
                box-shadow: inset 0 0 0 2px $white;
            }
        }
    }

    .once div,
    .several div {
        display: flex;
        flex-direction: column;
        padding: 0 0 10px 40px;
        border-bottom: 1px solid $primaryColor;
        margin-bottom: 10px;
        box-sizing: border-box;
    }

    .once_paragraph,
    .several_title {
        order: 0;
        display: block;
        width: 100%;
        @include fs-std-bold-title;
    }

    .condition_paragraph,
    .several_paragraph {
        order: 2;
        display: block;
        width: 100%;
        @include fs-std-thin-text;
    }

    .date_paragraph {
        order: 1;
        display: block;
        width: 100%;
        @include fs-std-thin-subtitle;
    }

    .precoWarning span {
        display: block;
        width: 100%;
        @include fs-sml-thin-text;
        font-size: 11px;

    }

    .btn_container {
        position: absolute;
        bottom: 20px;
        width: 90%;

        button {
            width: 100%;
        }
    }
}

// END PRECO

// CHANGE STORE TUNNEL

.delivery_lightbox {
    display: block;
    top: 0;
    position: fixed;
    bottom: 0;
    right: -600px;
    background-color: $white;
    font-family: $primaryFont;
    width: 350px;
    z-index: 105;
    transition: right .3s ease-in-out;
    cursor: default;
    padding: 30px 25px 30px 25px;
    box-sizing: border-box;

    &.actif {
        right: 0;
    }

    #map_canvas {
        .gm-style .gm-style-iw-c {
            .gm-style-iw-d {
                // Allow overflow scrolling on informations
                overflow: scroll !important;
            }
        }
    }

    &#delivery_home_choice {
        width: 465px;

        // Exception for when new adrese form is active
        &:has(#adresseForm) {
            width: 590px;

            #adresseForm {
                .w-form-line {
                    margin-bottom: 25px;

                    .w-input-container:has(.errormsgadd) {
                        .w-input-error {
                            margin-top: 11px;
                        }
                    }
                }

                .w-input-group {
                    margin-bottom: 0;
                }

                .w-dropdown {
                    &.w-has-error {
                        border-bottom: 1px solid $redError;
                    }
                }
            }
        }
    }

    &.relay,
    &.store {
        width: 600px;
    }

    .close_pop {
        top: 20px;
        right: 20px;
    }

    .lightbox_delivery_title {
        font-family: $primaryFont;
        color: $primaryColor;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 1;
        margin: 0 auto 30px;
        max-width: 265px;


        &.relay {
            padding-bottom: 10px;
        }

        .relay_type {
            display: block;
        }
    }

    .addr_line {

        &.society,
        &.full_name {
            font-weight: 500;
        }
    }

    .title_choix_relais {
        display: none;
    }

    .wrapper_select_adr {
        width: 100%;
        height: calc(100vh - 90px);
    }

    #adresseForm {
        .w-form-line.w-submit {
            width: 100% !important;
            max-width: 100%;
        }

        .w-submit-button,
        button.w-submit-button {
            height: 40px;
        }
    }

    .map_canvas {
        width: 100%;
        height: 245px;
        margin: 20px 0;
    }

    .list-right {
        height: calc(100vh - 450px);
        margin-top: 20px;
        overflow-y: scroll;
    }

    table.details {
        width: 96%;

        tr:first-child .kp {
            padding: 7px 0 27px;
            border-top: 0;

            .btn_container {
                top: 44%;
            }
        }

        .kp {
            position: relative;
            padding: 18px 0 26px;
            border-top: 1px solid $primaryColor;
        }

        .distance {
            display: none;
        }

        .name {
            margin-bottom: 6px;
        }

        .kpname {
            @include fs-std-bold-title;
            text-transform: uppercase;
        }

        .address {
            @include fs-std-thin-text;
        }

        .phone {
            display: none;
        }

        .current_schedule {
            @include fs-std-thin-text;
            font-size: 13px;

            .mag_open_status:before {
                content: '●';
                font-family: sans-serif;
                font-size: 17px;
                margin-right: 5px;
            }

            &.puce_store_open {
                .mag_open_status {
                    margin-right: 5px;
                    @include fs-std-thin-text;
                    color: #04AC6C;

                    &:before {
                        color: #04AC6C;
                    }
                }
            }

            &.puce_store_closed {
                .mag_open_status {
                    @include fs-std-thin-text;
                    margin-right: 5px;
                    color: red;

                    &:before {
                        color: red;
                    }
                }
            }
        }

        .relayHours {
            display: none;
        }

        .btn_container {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }

        .relayLink {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            height: 40px;
            padding: 0 1.2em;
            border: 1px solid $primaryColor;
            font-family: $primaryFont;
            font-size: 15px;
            color: $primaryColor;
            transition: background-color .3s ease-in-out;

            &:hover {
                background-color: $primaryColor;
                color: $white;
            }
        }
    }
}

// END CHANGE STORE TUNNEL

// CODE PROMO
#modboxpromo {

    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    width: 350px;
    height: 180px;
    padding: 20px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    &.actif {
        display: flex;
    }

    .texte {
        @include fs-std-regular-text;
        text-align: center;
    }
}


// END CODE PROMO

// POP UP NEWSLETTER

.tablet #exit_overlay_OVI {
    width: 97vw;
}

#exit_overlay_OVI {

    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    max-width: 775px;
    width: 100%;
    align-items: center;

    &.actif {
        display: flex;
    }

    .pop_contents {
        display: flex;
        width: 100%;

        .formRow {
            .w-input-error {
                top: -14px;
            }
        }
    }

    .blockpop {
        flex-direction: column;
    }

    .wrapper_img {
        width: 45%;

        img {
            display: flex;
            width: 100%;
            height: auto;
        }
    }

    .wrapper_newspopup_home {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 55%;
        padding: 38px;
        box-sizing: border-box;
    }

    .content_newspopup {
        width: 100%;
    }

    #formu_inscription_news {
        text-align: center;
    }

    .title_popup_inscription_home {
        @include fs-nrml-bold-title;
    }

    .pop_txt_listing {
        @include fs-std-thin-text;
        margin: 15px 0;
    }

    .formRow {
        .lightbox_subtitle {
            display: none;
        }

        .w-form-line {
            margin: 0;

            &:first-child {
                margin-bottom: 30px;
            }
        }
    }

    .w-submit-button,
    .button.dark {

        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: 0 1.2em;
        font-family: $primaryFont;
        font-size: 13px;
        transition: background-color .3s ease-in-out;
        background-color: $secondaryColor;

        &:hover {
            background-color: $secondaryColor;
            border-color: $primaryColor;
        }
    }

    .pop_cgv_listing {
        @include fs-std-bold-subtitle;
        // style inline
        margin: 30px 0 0 0 !important;

        span {
            display: block;
            width: 100%;
            text-align: center;
        }
    }
}

// END POPUP NEWSLETTER

/*  EXPRESS BUY */
#lightbox_achat_express,
#selection-offer {
    width: 400px;
    height: 100vh;
    display: none;
    position: fixed;
    z-index: 100;
    right: 0;
    top: 0;
    background: #fff;
    padding: 25px;
    margin: auto;
    box-sizing: border-box;

    &.actif {
        display: block;
    }

    // OFFERT SUR SELECTION STYLE SPECIFIQUE

    .selection-offer-content {
        display: flex;
        width: 100%;
        max-height: 90vh;
        overflow-y: scroll;
        padding: 0 25px;
        box-sizing: border-box;
        margin: 1rem auto 0;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    div.selection-offer-header p.selection-offer-title {
        color: $primaryColor;
        text-align: center;
        font-family: $primaryFont;
        font-size: 22px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 15px;
    }

    .selection-offer-submit {

        .button.dark {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 350px;
            width: 24.6vw;
            height: 40px;
            background-color: #F0EEE9;
            border: 1px solid #F0EEE9;
            font-family: $primaryFont;
            font-size: 15px;
            font-weight: 500;
            font-style: normal;
            line-height: 1;
            color: $primaryColor;
            box-sizing: border-box;
            cursor: pointer;

            &:hover {
                border: 1px solid $primaryColor;
            }
        }
    }

    .selection-offer-header p:nth-child(2) {
        color: $primaryColor;
        text-align: center;
        font-family: $primaryFont;
        font-size: 15px;
        font-weight: 100;
        line-height: normal;
    }

    #wrap_lion {

        .js-form-selection-offer:hover {
            border-color: $primaryColor;

            &::before {
                background: $primaryColor;
            }
        }

        .wrapperVisuOfferedProd {
            grid-area: 1 / 1 / 4 / 2;
            width: 99px;
            height: 132px;
        }

        p.titleOfferedProd {
            grid-area: 1 / 2 / 2 / 3;
            width: 100%;
            height: fit-content;
            padding-left: 15px;

            span {
                display: none;
            }
        }

        .size_list,
        .color_list {
            width: 100%;
            margin: .5rem 0 0;
            padding: 0;

            .nextSize,
            .prevSize,
            .prevColour,
            .nextColour {
                display: none;
            }
        }

        .size_list {
            grid-area: 2 / 2 / 3 / 3;
            height: fit-content;
            max-width: 80%;
            padding-left: 15px;

            .swiper-wrapper {
                justify-content: space-between;
            }
        }

        .color_list {
            grid-area: 3 / 2 / 4 / 3;
            height: fit-content;
            padding-left: 15px;

            input[type=radio]:checked+label {
                box-sizing: border-box;
            }
        }

        .size_list {

            .check.size {

                input[type=radio]+label {
                    width: 100%;
                    opacity: .5;
                    border: 1px solid transparent;
                    box-sizing: border-box;

                    span {
                        font-size: 11px;
                        font-weight: 300;
                    }

                    &:hover {
                        border: 1px solid $primaryColor;
                        opacity: 1;
                        font-weight: 400;

                        span {
                            &::after {
                                display: none;
                            }
                        }
                    }
                }

                &.selected {
                    input[type=radio]+label {
                        border: 1px solid $primaryColor;
                        opacity: 1;
                    }
                }
            }
        }

        .js-form-selection-offer {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 1fr);
            width: 100%;
            min-height: 132px;
            padding: .5rem .5rem .5rem 1rem;
            grid-template-columns: 1fr 2fr;
            opacity: .8;
            margin: 0 0 1rem;

            &:before {
                top: 48%;
                left: 0;
            }

        }
    }

    // END OFFERT SUR SELECTION STYLE SPECIFIQUE

    .main_ariane {
        display: none;
    }

    .page_produit {

        .top_page_produit {
            display: flex;
        }


        .product_main_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 30%;
            max-width: 99px;

            #img_large {
                width: 100%;
                height: auto;
            }

            .itm_views {
                width: 100%;
            }

            .prod_top_wrapper {
                width: 47.8%;
                padding: 0;

                .product_galery_container {
                    order: 1;

                    .swiper-button-prev,
                    .swiper-button-next {
                        display: none;
                    }

                    .swiper-wrapper {
                        .swiper-slide {
                            display: none;
                            width: auto !important;
                            height: 560px;

                            &:first-child {
                                display: block;
                            }

                            img {
                                width: auto;
                                height: 560px;
                                cursor: unset !important;
                            }
                        }
                    }
                }
            }

            .product_infos_container {
                order: 2;
                height: auto;
                padding: 0;
                overflow: hidden;
                width: 52.2%;

                .reinsuranceFpMarque {
                    display: none;
                }

                .action_list {
                    right: 0%;
                }

                .product_info {
                    #product_selects {
                        margin-bottom: 15px;
                    }

                    .productSizeFieldset {
                        .prod_listes_sizes {
                            .sizesList {
                                top: 40px;
                            }
                        }
                    }

                    .itm_form_group {
                        .flex_block {
                            .link_fp {
                                margin: 30px auto 0;
                                text-align: center;

                                a {
                                    font-size: 12px;
                                    color: $primaryColor;
                                    display: inline-block;
                                    position: relative;
                                    text-decoration: underline;
                                    height: 1rem;
                                }
                            }

                            .bloc_add_alert {
                                #keep_alive_stock {
                                    .bloc_add_alert_form {
                                        .w-form-line {
                                            .w-input-container {
                                                height: 46px;
                                            }

                                            .w-input {
                                                border: 1px solid $primaryColor;

                                                #mail_alerte_stock {
                                                    border-color: transparent;
                                                    width: 298px;
                                                    height: 44px;
                                                }
                                            }

                                            &.w-submit {
                                                height: 45px;
                                                width: 46px;

                                                button {
                                                    height: 44px;
                                                    line-height: 44px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .itm_back_btn,
                .product_feats_wrapper {
                    display: none;
                }
            }
        }

        .product_info_wrapper {
            width: 65%;
            padding-left: 15px;
            box-sizing: border-box;

            .product_info {
                .wrapper_name_price {
                    .item_name {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: normal;
                        text-transform: capitalize;
                    }
                }

                .price_container {

                    margin: 5px 0 7px;

                    .final_price .price {
                        color: $primaryColor;
                        text-align: center;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-weight: 100;
                        line-height: normal;
                    }
                }

                .productSizeFieldset {
                    margin-top: 7px;
                }

                .choices_list {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: fs-vw(10, 1920);
                    width: 100%;

                    .size,
                    .color {
                        transition: border-color .3s;

                        &:hover {
                            border-color: $primaryColor;
                        }

                        input {
                            display: none;
                        }
                    }

                    .size {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 28px;
                        height: 28px;
                        background-color: $secondaryColor;
                        cursor: pointer;
                        border: 1px solid $tertiaryColor;

                        &:has(.disabled) {
                            opacity: .3;
                        }

                        label {
                            @include fs-std-thin-text;

                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                            margin-top: -3px;
                            cursor: pointer;
                        }

                        input {
                            position: absolute;
                            width: 100%;
                            left: 0;
                            top: 0;
                            opacity: 0;
                        }

                        &.size_selected {
                            border: 1px solid $primaryColor;
                        }

                        &.disabled {
                            opacity: .3;
                        }
                    }

                    .color {
                        width: 23px;
                        height: 23px;
                        border-radius: 50%;
                        border: 1px solid $secondaryColor;
                        cursor: pointer;
                        box-sizing: content-box;

                        &:has(input:checked) {
                            border-color: $primaryColor;
                        }

                        label {
                            display: block;
                            width: 17px;
                            height: 17px;
                            background-color: var(data-hex);
                            background-position: center;
                            border-radius: 50%;
                            border: 3px solid $secondaryColor;
                            cursor: pointer;
                            box-sizing: content-box;
                        }
                    }

                    .remaining_colors {
                        position: relative;
                        display: flex;
                        width: 16px;
                        height: 16px;
                        font-size: 0;
                        margin-top: 12px;

                        &::after {
                            content: "";
                            display: block;
                            position: absolute;
                            left: 2px;
                            width: fs-vw(8, 428);
                            height: fs-vw(8, 428);
                            background-image: url("../svg/more_color.svg");
                            background-repeat: no-repeat;
                            background-size: contain;
                        }
                    }
                }
            }
        }

        #bloc_add_basket {
            position: absolute;
            left: -114px;
            top: 0;
            width: 350px;
            height: 40px;
            cursor: pointer;
        }

        #product_addcart {
            position: relative;
            margin-top: 20px;

            #btn_add_cart {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background-color: #f0eee9;
                border: 1px solid #f0eee9;
                font-family: $primaryFont;
                font-size: 15px;
                font-weight: 500;
                font-style: normal;
                line-height: .9;
                color: #3d4557;
                box-sizing: border-box;
                z-index: 2;

                &:hover {
                    border: 1px solid $primaryColor;
                }
            }

            .loader {
                position: absolute;
                max-width: 350px;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                opacity: 0;
            }
        }
    }
}

/*AlertBox on cart page*/
body.cart {
    footer#copyright {
        #abox.lightbox.actif {
            display: flex;

            .header_lightbox {
                display: flex;
            }
        }

        #abox {
            text-align: center;
            display: none;
            position: fixed;
            z-index: 100;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: $white;
            width: 350px;
            height: 180px;
            padding: 20px;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .txt_alert {
                font-family: $primaryFont;
                font-size: 15px;
                font-weight: 400;
                line-height: normal;
                color: $primaryColor;
                text-align: center;
            }
        }
    }
}