$white: white;

$primaryColor: #3D4557;
$primaryColorSlime: #3d45577a;
$secondaryColor: $white;
$tertiaryColor: #F0EEE9;

$bulletsColor: #bdbdbd;

$greyLabel: #9A9996;

$greyBorder: #F0EEE8;

$orangeStatus: #EC6907;

$redError: #ff6d6d;

// Variables Shades
$shadeColor: rgba(0, 0, 0, .15);
$almostNone: #E8F0FE;
