// Define the mixin with two parameters

@mixin slide-video($padding-top-ratio, $width-ratio) {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: calc(#{$padding-top-ratio} / #{$width-ratio} * 100%);

    a.container_video,
    .block_redirect {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 2;
        cursor: pointer;
    }

    iframe {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
    }
}


// Usage of the mixin
// slide-video(height, width)
// .slide-video {
//     @include slide-video(735, 1600);
// }