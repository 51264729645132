/**
 * Global prices styles
 */

@mixin price_style($font-size, $font-weight, $font-color) {
    font-family: $primaryFont;
    font-size: $font-size;
    font-weight: $font-weight;
    color: $font-color;
}

// Simple price

.price,
.price_tag {
    @include price_style(16px, 100, $primaryColor);

    .no_cents {
        display: none;
    }
}

// if price is discounted 
.wrapper_cart_product_price:has(.pricetag + .pricetag),
.wrapper_price:has(.pricetag + .pricetag) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;

    .pricetag+.pricetag {
        margin-right: 6px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            height: 1px;
            width: 100%;
            background-color: $primaryColor;
        }
    }
}

// Account page 

.wrapper_moncompte {

    .wrapper_price,
    .bloc_price {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;

        .price_tag+.price_tag {
            margin-right: 6px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                height: 1px;
                width: 100%;
                background-color: $primaryColor;
            }
        }
    }
}




// Product page price

.price_container {
    display: flex;
    align-items: center;
    margin: 5px 0 12px;

    .final_price .price {
        @include price_style(20px, 100, $primaryColor);
    }

    .initial_price .price {
        position: relative;
        white-space: nowrap;
        @include price_style(20px, 100, #9A9996);
        margin-right: 15px;

        &::after {
            border-top: 1px solid #9a9996;
            position: absolute;
            content: "";
            right: 0;
            top: 50%;
            left: 0;
        }
    }
}

// end Product page price