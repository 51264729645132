.product_page {

    .top_page_produit {
        display: flex;
        justify-content: flex-start;
        width: 98.5%;
        max-width: 1920px;
        margin: auto;
        padding-right: 1%;
        box-sizing: border-box;
        align-items: flex-start;
    }

    .product_main_wrapper {
        width: 66.4%;
        max-width: 1231px;
        margin-right: 72px;

        .mainSlideFp {
            position: relative;

            .eclat2 {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                z-index: 2;

                img {
                    width: auto;
                    max-height: 30px;
                }
            }
        }

        .swiper-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .swiper-slide {
                width: 49.5%;
                margin-bottom: .5vw;
                cursor: pointer;

                &.slide_video {
                    @include slide-video(402, 602);
                    width: 49.5%;
                    padding-top: 65.8%;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .product_main_wrapper {
            width: 57.4%;
            margin-right: 18px;
        }

        .product_info_wrapper {
            width: 40%;

            #btn_alert_stock,
            #btn_resa_shop,
            #bloc_add_basket .to_tunnel {
                font-size: 11px;
            }
        }
    }


    .product_info_wrapper {
        position: sticky;
        top: 90px;
        width: 33%;
        max-width: 550px;
        padding-top: 18px;

        .item_name {
            @include fs-big-bold-title;
            line-height: 1;
            text-transform: capitalize;
        }

        .itm_subtitle {
            display: none;
        }

        .addToWishlistButtonContainer .addToWishlistButton {
            position: absolute;
            top: 18px;
            right: 0;

            &:after {
                content: '';
                display: block;
                width: 33px;
                height: 33px;
                @include maskIconPath('../svg/spritesheet.svg');
                @include maskIconCoord(20, $initialColSize, $initialRowSize);
                background-color: $primaryColor;
                cursor: pointer;
            }
        }

        .addToWishlistButtonContainer .existToWishlistButton {
            &:after {
                @include maskIconCoord(21, $initialColSize, $initialRowSize);
            }
        }

        // PRICE STYLE

        .price_container {
            margin: 17px 0 20px;
        }

        // END PRICE STYLE 

        .eclat1 {
            display: flex;
            margin: 0 15px;

            img {
                width: auto;
                max-height: 30px;
            }
        }

        .itm_form_group {
            margin-top: 15px;
        }

        #product_addcart {
            position: relative;
            display: flex;
            justify-content: space-between;
            max-width: 460px;
            width: 100%;
            margin-top: 10px;

            @media screen and (max-width: 1130px) {
                width: 100%;
            }
        }

        // Radio Button

        .size_select_wrapper {
            display: flex;
            justify-content: space-between;
        }

        .size_select_wrapper .choices_list {
            flex-wrap: wrap;
        }

        .choices_list {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;

            .form_itm.color {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: unset;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin-right: 13px;

                .preview_color {
                    display: block;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    position: relative;
                    cursor: pointer;
                    background-position: center;
                    background-size: cover;
                }

                label[title="BLANC"] {
                    opacity: .5;
                    border: 1px solid #3d455742;
                }

                &.color_selected {
                    border: 1px solid $primaryColor;

                    label[title="BLANC"] {
                        opacity: 1;
                    }
                }

                &:only-of-type {
                    pointer-events: none;
                }
            }
        }

        // END Radio Button

        .productSizeFieldset {
            width: 100%;
        }

        .size_is_not_available {
            display: block;
            font-size: 13px;
            color: red;
            font-weight: 400;
            margin-bottom: 10px;
        }

        .size_qnt {
            position: relative;
            display: flex;
            flex-direction: column;
            padding-bottom: 5px;

            .size_is_not_available {
                font-family: $primaryFont;
                font-size: 13px;
                color: $primaryColor;
                font-weight: 100;
            }

            .w-fitle-widget {
                width: 100%;

                svg {
                    display: none;
                }

                .ftl-widget-text {
                    font-family: $primaryFont;
                    color: $primaryColor;
                    font-weight: 100;
                    margin-left: 0;
                }

                button:empty {
                    display: none;
                }
            }

            #sizeerror {
                position: absolute;
                top: 83%;
                left: 0;
                @include fs-sml-thin-text;

                &.actif {
                    display: block !important;
                }
            }

            .one_size {
                .form_itm.check {
                    border: 1px solid $primaryColor;
                }
            }

            .choices_list {
                .form_itm.check {
                    position: relative;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    flex-wrap: wrap;
                    @include fs-sml-thin-subtitle;
                    font-size: clamp(15px, 0.729vw, 20px);
                    line-height: 0;
                    margin-right: 13px;

                    &.size_selected {
                        border: 1px solid $primaryColor;
                    }

                    input.disabled+label .size_icon {
                        opacity: .3;
                    }

                    &.size_selected.disabled {
                        position: relative;

                        &:after {
                            content: '';
                            position: absolute;
                            top: -6px;
                            right: 15px;
                            display: block;
                            width: 1px;
                            height: 43px;
                            background: $primaryColor;
                            z-index: 1;
                            transform: rotate(45deg);
                        }
                    }

                    label {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    span {
                        display: block;
                        width: 100%;
                        cursor: pointer;
                        text-align: center;
                    }

                    input {
                        display: none;
                    }
                }
            }
        }

        .productQuantityFieldset {
            margin: 20px 0;

            .holder_qte_selector {
                position: relative;
                display: flex;
                align-items: center;
                max-width: 82px;
                width: 100%;
                height: 30px;
                padding: 0 5px;
                border: 1px solid $primaryColor;
                box-sizing: border-box;
            }

            .max_stock {
                position: absolute;
                bottom: 35%;
                left: 110%;
                width: 180px;
                font-size: 11px;
            }

            .qte_selector {
                position: absolute;
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &.qte_minus {
                    left: 0;
                    top: 17%;
                }

                &.qte_plus {
                    right: 0;
                    top: 17%;
                }

                &.selector_disabled {
                    opacity: .3;
                }
            }

            .qte_selected {
                max-width: 76px;
                text-align: center;
                background: transparent;
            }

            .qte_minus::before {
                content: "-";
                display: block;
                @include fs-sml-thin-subtitle;
                font-size: clamp(15px, 0.729vw, 20px);
                margin-bottom: 5px;
            }

            .qte_plus::before {
                content: "+";
                display: block;
                @include fs-sml-thin-subtitle;
                font-size: clamp(15px, 0.729vw, 20px);
                margin-bottom: 5px;
            }

            .qte_error_msg {
                font-size: 11px;
            }
        }

        .btnAddBasketWrapper {
            position: relative;
            display: flex;
            justify-content: space-between;
            max-width: 217px;
            width: 49%;

            &.cache {
                display: none;
            }

            .loader {
                position: absolute;
                width: 100%;
                height: 40px;
                border: 1px solid $primaryColor;
                z-index: -1;
                opacity: 0;

                &.loading {
                    background: $white;
                    z-index: 2;
                    opacity: 1;
                }
            }
        }

        .pdvWrapper {
            max-width: 217px;
            width: 48%;
        }

        #btn_add_cart {
            line-height: 0;
            font-weight: 300;

            &.cache {
                display: none;
            }
        }

        #btn_alert_stock {
            color: white;
            font-weight: 300;
            border: 0;
            background: #9A9996;
            width: 100%;
            line-height: 1;

            &.cache {
                display: none;
            }

            &:hover {
                background: transparent;
                border: 1px solid $primaryColor;
                color: $primaryColor;
            }
        }

        #btn_resa_shop {
            max-width: 217px;
            width: 49%;
            color: $primaryColor;
            font-weight: 300;
            border: 0;
            background: $tertiaryColor;
            line-height: 0;

            &.cache {
                display: none;
            }

            &:hover {
                background: transparent;
                border: 1px solid $primaryColor;
                color: $primaryColor;
            }
        }

        #bloc_add_alert {
            max-width: 230px;
            width: 50%;

            &.cache {
                display: none;
            }

            #bloc_add_alert_form {
                border-bottom: 1px solid $primaryColor;

                &:has(.inputErr) {
                    border-bottom: 1px solid $redError;

                    .w-input-element {
                        color: $redError;
                    }
                }

                .w-input-element {
                    border: none;

                    input {
                        @include fs-std-light-text;
                    }
                }

                button {
                    background: transparent;
                    border: none;
                    cursor: pointer;
                }
            }

            .confirmation_msg {
                position: relative;
                @include fs-sml-thin-subtitle;
                padding-left: 15px;
                box-sizing: border-box;

                &::before {
                    position: absolute;
                    top: 1px;
                    left: 0;
                    content: '';
                    display: block;
                    width: 12px;
                    height: 8px;
                    background-image: url("../svg/valid_icon.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }

            .confirmation_msg_sub {
                @include fs-sml-thin-subtitle;
            }
        }

        .legal_mention {
            font-family: $primaryFont;
            font-weight: 100;
            font-size: 11px;
            line-height: 15.51px;
            color: $primaryColor;

            br {
                display: block!important;
            }

            a {
                text-decoration: underline;
            }
        }
    }

    .paye_3 {
        display: flex;
        align-items: center;
        @include fs-sml-medium-subtitle;
        font-weight: 300;
        margin-top: 15px;
        cursor: pointer;
        width: fit-content;

        .price {
            @include fs-sml-medium-subtitle;
            font-weight: 300;
            margin: 0 3px;
        }

        &:after {
            content: '';
            display: block;
            width: 40px;
            height: 16px;
            background-image: url("../svg/alma_product.svg");
            background-size: contain;
            background-repeat: no-repeat;
            margin: 5px 5px 0 3px;
        }
    }

    /**
 * Product description
 */

    .desc_pannel {
        margin-top: 30px;

        #descrContent.content a {
            color: $primaryColor;
        }
    }

    .reinsurance {
        padding: 30px 0;
    }

    .toggleCarac_container {
        border-bottom: 1px solid $primaryColorSlime;

        &:first-child {
            border-top: 1px solid $primaryColorSlime;
        }
    }

    .toggleCarac {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 50px;
        @include fs-std-bold-title;
        cursor: pointer;

        &:after {
            content: '';
            width: 12px;
            height: 12px;
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(1, $initialColSize, $initialRowSize);
            background-color: $primaryColor;
            position: absolute;
            top: 21px;
            right: 0;
        }

        &.actif {
            &:after {
                transform: rotate(-180deg);
            }
        }
    }

    .content {
        display: none;
        @include fs-std-thin-text;
        padding: 0 0 2em 0;

        &.actif {
            display: block;
        }

        &#dimsCharacContent.actif {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            .caract {
                width: 39%;

                .value {
                    margin-left: 0;
                }
            }

            .libelle {
                display: none;
            }

            .bloc_infos {
                width: 61%;
                max-width: 532px;

                .img_detail {
                    width: 100%;
                    height: auto;
                    vertical-align: top;
                }
            }
        }

        .caract {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: .6em;

            .value {
                margin-left: .5em;
            }
        }

        .libelle {
            white-space: nowrap;
        }

        #date_du,
        #date_au {
            font-weight: 400;
            margin: 0 3px;
        }

        .product_ref {
            margin-top: 20px;
        }
    }

    #descrContent {
        padding: 0 0 1em 0;
    }

    #pdf_download a {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        @include fs-std-light-text;
        text-decoration: underline;

        &:before {
            background-color: $primaryColor;
            width: 2.4em;
            height: 2.4em;
            border-radius: 2px;
            margin-left: 1em;
        }
    }

    #caracContent {
        .caract_icon {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
        }

        a {
            color: $primaryColor;
            text-decoration: underline;
        }
    }

    .item_refext {
        margin-top: 1rem;

        +.item_refext {
            margin-top: .6rem;
        }
    }

    .product_info_wrapper .reinsurance {

        h3,
        h2,
        div {
            @include fs-std-medium-text;
            font-size: 12px;
        }


        span {
            display: none;
        }
    }

    /* ZOOM PRODUIT */
    #zoomBox {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background: rgba(0, 0, 0, 0.7);
        transform: none;

        &.actif {
            display: block;
        }

        .has_video .close_pop.close {
            right: 35%;
            top: 5%;
        }

        .close_pop.close {
            right: 24%;
            top: 5%;

            span {
                background: none;
                font-family: $primaryFont;
                font-weight: 300;
                font-size: 1rem;
                color: $primaryColor;
                width: 1rem;
                height: 1rem;
                transform: scale(1.1, 1);
            }
        }

        #zoom_box_wrapper {
            width: 100%;
            position: absolute;
            text-align: center;
            top: 0;
            left: 50%;
            transform: translateX(-50%);

            .swiper-wrapper {
                height: 100% !important;

                .swiper-slide {
                    width: 100% !important;
                    margin: 0 auto;
                    text-align: center;
                    background-size: contain;

                    img {
                        width: 100%;
                        height: auto;
                    }

                    &.has_video,
                    &.block_video {
                        position: relative;
                        padding-bottom: 50%;
                        height: 0;
                        overflow: hidden;

                        iframe {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }

        .zoomProdPrev,
        .zoomProdNext {
            position: fixed;
            top: 20%;
            transform: translateY(-50%);
            display: block;
            width: 20px;
            height: 20px;
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(1, $initialColSize, $initialRowSize);
            background-color: $primaryColor;
            z-index: 3;
            cursor: pointer;

            &.swiper-button-disabled {
                opacity: 0.5;
            }

        }

        .zoomProdPrev {
            left: 50px;
            transform: rotate(90deg);
        }

        .zoomProdNext {
            right: 50px;
            transform: rotate(-90deg);
        }

        .close_pop {
            position: fixed;
            top: 50px;
            right: 50px;
            transform: translateY(-50%);
            display: block;
            width: 12px;
            height: 12px;
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(24, $initialColSize, $initialRowSize);
            background-color: $primaryColor;
            z-index: 3;
            cursor: pointer;
        }
    }

    #zoomBox::-webkit-scrollbar {
        display: none;
    }

    // SWIPER ASSOCATION PRODUCT PAGE

    .product_association,
    .product_association_theme {
        position: relative;
        width: 100%;
        max-width: 1655px;
        margin: 60px auto 30px;
        padding: 0 25px;
        box-sizing: border-box;
    }

    .assocTitle {
        @include fs-big-bold-title;
        margin-bottom: 10px;
    }

    #assoc_product_slider,
    #assoc_theme_slider {
        overflow: hidden;
        padding-bottom: 75px;

        .item {
            .imgWrapper {
                .wrap_rolloverproduit {
                    form {
                        .btnAddBasketWrapper {
                            position: absolute;
                            bottom: 99px;
                            padding: 5%;
                            width: 100%;
                            z-index: 3;
                            background-color: rgba($tertiaryColor, .95);
                            box-sizing: border-box;
            
                            .bloc_add_alert_form {
                                .w-form-line {
                                    .form_submit {
                                        position: absolute;
                                        right: 0;
                            
                                        button {
                                            @include fs-std-medium-text;
                                            background: unset;
                                            border: none;
                                            padding: 0;
                                            cursor: pointer;
                                            background-color: rgba($tertiaryColor, .95);
                                        }
                                    }
            
                                    .w-input-container {
                                        .w-input.w-text-input {
                                            .w-input-element:not([type=radio]):not([type=checkbox]) {
                                                &:focus {
                                                    background-color: $almostNone;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
            
                            .bloc_add_alert_confirmation {
                                font-family: $primaryFont;
                                font-size: 16px;
                                line-height: normal;
                                color: $primaryColor;
                            }
            
                            .alert_return {
                                position: absolute;
                                top: 10px;
                                right: 10px;
                                width: 12px;
                                height: 12px;
                                @include maskIconPath("../svg/spritesheet.svg");
                                @include maskIconCoord(24, $initialColSize, $initialRowSize);
                                background-color: $primaryColor;
                                cursor: pointer;
                    
                                span {
                                    font-size: 0;
                                }
            
                                @media screen and (max-width:1070px) {
                                    top: 0;
                                }
                            }
            
                            .alert_stock {
                                color: $primaryColor;
                            }
                        }
                    }
                }
            }
        }
    }

    .assoc-button-next,
    .assoc-button-prev,
    .assoc-button-prev_theme,
    .assoc-button-next_theme {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: 15px;
        height: 15px;
        @include maskIconPath('../svg/spritesheet.svg');
        @include maskIconCoord(1, $initialColSize, $initialRowSize);
        background-color: $primaryColor;
        z-index: 3;
        cursor: pointer;

        &.swiper-button-disabled {
            opacity: 0.5;
        }

        &.swiper-button-lock {
            opacity: 0;
        }

    }

    .assoc-button-prev,
    .assoc-button-prev_theme {
        top: 42%;
        left: 0;
        transform: rotate(90deg);
    }

    .assoc-button-next,
    .assoc-button-next_theme {
        top: 42%;
        right: 0;
        transform: rotate(-90deg);
    }

    .swiper-pagination {
        z-index: 1;
    }

    .swiper-pagination-bullet {
        background: $bulletsColor;
    }

    .swiper-pagination-bullet-active {
        background: $white;
        border: 1px solid $primaryColor;
    }

    .rollover_rayonsPrev,
    .rollover_rayonsNext {
        visibility: hidden;
    }

    // END SWIPER ASSOCATION PRODUCT PAGE

    // SWIPER ASSOCATION PRODUCT PAGE

    .product_association {

        .swiper-pagination-bullet {
            background: $bulletsColor;
        }

        .swiper-pagination-bullet-active {
            background: $white;
            border: 1px solid $primaryColor;
        }
    }

    // END SWIPER ASSOCATION PRODUCT PAGE
}

// TABLET EXCEPTION 

.tablet {
    #product_addcart {
        flex-direction: column;
    }

    #bloc_add_basket,
    #bloc_add_alert,
    #btn_resa_shop {
        margin-bottom: 5px;
    }
}

/* KLEEP WIDGET */
.kleep-wrap {
    #showSimilarProductsButton {
        display: none;
    }
}

#kleep-container {
    .kleep-underline {
        font-family: $primaryFont;
        font-size: 15px !important;
        font-style: normal;
        font-weight: 500;
        color: $primaryColor;
        white-space: nowrap;
        min-height: 30px;
        padding-bottom: 0;

        span {
            padding-bottom: 0;
        }

        &:after {
            display: none;
        }
    }
}

.kleep-wrapper[style="display: block;"] {
    .kleep-iframe,
    .kleep-products-iframe {
        display: block !important;
    }
}