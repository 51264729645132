/******* ACCOUNT GLOBAL ********/
.accountBannerTop .txt_banner_top {
    display: none;
}

.menu_compte_head,
.wrapper_moncompte {
    width: 95%;
    max-width: 1280px;
    margin: 0 auto;
}

.menu_compte_head {
    display: flex;
    justify-content: center;
    @include fs-big-bold-title;
    gap: 8px;
    margin-bottom: 30px;
}

.left-container {
    margin: 12px auto 40px;

    .wrapper_menu_compte {
        display: flex;
        justify-content: center;
        gap: 15px;
        height: 28px;

        .menu-itm {
            @include fs-std-regular-subtitle;
            display: flex;
            align-items: center;
            width: fit-content;
            padding: 0 10px;
            background: $greyBorder;
            border: 1px solid $greyBorder;
            text-transform: uppercase;
            cursor: pointer;
            transition: border-color 0.3s;

            &:hover,
            &.actif {
                border-color: $greyLabel;
            }
        }
    }
}

.store_pref {
    display: none; // in case FAVORITE_STORE constant must be true in main
}

/**** ADRESSE ****/

.conteneur_adresse {
    display: flex;
    gap: 13px;

    .adresse {
        position: relative;
        padding: 26px 30px 35px;
        width: 310px;
        border: 1px solid $primaryColor;
        box-sizing: border-box;
    }

    .addr_type {
        @include fs-std-bold-title;
        text-transform: capitalize;
    }

    .infos {
        @include fs-std-thin-text;

        .full_name span {
            display: inline-block;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    .modlinks_wrapper {
        margin-top: 15px;

        .modify {
            position: relative;
            width: fit-content;
            @include fs-std-medium-text;
            cursor: pointer;

            @include secondaryButton(left, 3);
        }

        .delete {
            position: absolute;
            top: 11px;
            right: 11px;
            font-size: 0;
            width: 12px;
            height: 12px;
            @include maskIconPath("../svg/spritesheet.svg");
            @include maskIconCoord(24, $initialColSize ,$initialRowSize);
            background-color: $primaryColor;
        }
    }
}

#lightboxCustomerAddress {
    position: fixed;
    top: 0;
    left: 100%;
    display: block;
    visibility: hidden;
    height: 100vh;
    width: 400px;
    padding: 30px 25px;
    background-color: $secondaryColor;
    box-sizing: border-box;
    transition: visibility 0.3s, left 0.3s;

    #adresseForm {
        width: calc(100% + 17px);
        /* to compensate the 17px padding right for cross-browser compatibility */
        height: 100%;
        max-height: calc(100vh - 50px);
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 17px;
        /* Increase/decrease this value for cross-browser compatibility */
        box-sizing: content-box;
        /* So the width will be 100% + 17px */
        scrollbar-color: $primaryColor $greyBorder;
        scrollbar-width: thin;
    }

    &.actif {
        left: calc(100% - 400px);
        visibility: visible;
    }

    .w-address-input.w-nonempty .noaddr {
        background-color: $secondaryColor;
    }

    .w-form-line {
        flex-wrap: wrap;

        .w-input-container:not(:last-of-type) {
            margin-bottom: 1.5rem;
        }
    }

    .w-input-container + .w-input-container {
        margin-left: 0

    }
}

.delivery_section_title {
    @include fs-nrml-bold-title;
    margin-bottom: 20px;
}

.add_new_address {
    margin: 30px 0 60px;
    width: fit-content;
    padding: 0 50px;

    span {
        display: block;
        color: $secondaryColor;
        width: fit-content;
        text-align: center;
    }

    &:hover span {
        color: $primaryColor;
    }
}

/***** INFORMATIONS *****/
.customer_infos {
    .w-submit-button {
        width: 148px;
    }

    .wrapper_form .w-info-form {
        padding-bottom: 2.5rem;
    }

    .w-input-note {
        margin-top: 0;
    }
}


/***** ORDERS *****/
.orederDateSelect {
    select {
        @include fs-std-regular-label;

        width: 100%;
        margin-bottom: 38px;
        color: $primaryColor;
        cursor: pointer;
    }
}

#orders_by_type {
    margin-bottom: 100px;

    th {
        width: 6%;
    }

    tr:first-of-type {
        background-color: $tertiaryColor;

        th {
            @include fs-std-bold-title;
            padding: 6px 0 8px;
            &:first-of-type {
                padding-left: fs-vw(125, 1920);
            }
        }
    }

    tr:not(:first-of-type) {
        border-bottom: 1px solid $primaryColorSlime;
    }

    td {
        @include fs-std-thin-text;
        width: 6%;
        vertical-align: middle;
        padding: 20px calc((22/1920) * 100%);
        text-align: center;

        &:last-of-type:not(.noOrders) {
            padding-right: 0;
            text-align: right;
            position: relative;
        }

        &.cmdTrackingNum,
        &.cmdDetailLink {
            font-weight: $medium;
        }

        .order_number {
            padding-left: calc(#{fs-vw(125, 1920)} - 81px);
            box-sizing: border-box;
        }

        .noOrders {
            text-align: left;
        }

        .btn_detail {
            position: relative;
            display: block;
            width: 100%;
            white-space: nowrap;
            margin-left: auto;

            @include secondaryButton(right, 3);
        }

        &.cmdStatus span {
            display: block;
        }
    }
}

.cmdNum {
    display: flex;
    align-items: center;

    .top_product_picture {
        position: relative;
        margin-left: 22px;

        img {
            width: 99px;
            height: auto;
        }
    }
}

/****** ORDER DETAIL ******/
.content_commandes{
    opacity: 0;
    transition: opacity 0.9s linear;
}

.detailCmdTop a {

    &::before {
        content: "";
        display: inline-block;
        width: 9px;
        height: 9px;
        @include maskIconPath('../svg/spritesheet.svg');
        @include maskIconCoord(1, $initialColSize, $initialRowSize);
        margin-right: 8px;
        background-color: $primaryColor;
        transform: rotate(90deg);
    }
}

.wrapper_content_cmd_detail {
    display: flex;
    gap: 35px;

    .wrapper_left {
        width: calc(100% - 460px);
        max-width: 820px;
        margin: 26px 0 60px;
    }

    .wrapper_right {
        margin: 26px 0 60px;
        width: 425px;
    }
}

.order_detail_head {
    @include fs-std-thin-title;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 1px solid $primaryColorSlime;
    padding: 19px 30px 23px;
}

.top_wrapper_infos_cmd {
    width: 100%;
    max-width: 230px;
}

.bottom_wrapper_infos_cmd {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 5px;
    width: 100%;
    max-width: 480px;
    padding-top: 12px;
}

.num_suivi {
    display: flex;
    font-weight: $thin;
    color: $primaryColor;
    gap: 4px;

    &.suivi_retour a {
        @include secondaryButton(center, 3);
        font-weight: $regular;
    }

    &.shipping_number a {
        text-decoration: underline;
    }
}

.cmd_track_btn span {
    @include secondaryButton(center, 3);
    display: block;
    font-weight: $regular;
}

.cmd_title {
    display: flex;
    width: 100%;
    font-weight: $bold;

    .cmd_number {
        margin-left: 4px;
    }
}

.cmd_date {
    margin-top: 13px;
}

.cmd_status {
    margin-top: 5px;
}

.cmd_date,
.cmd_status {
    @include fs-std-thin-text;
    line-height: 23px;
}

.cmd_status span {
    color: $orangeStatus;
}

.cmd_items_title {
    @include fs-std-bold-title;
    width: 100%;
    margin-top: 30px;
}

.cmd_items {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    .product-link {
        width: calc(50% - 15px);
        min-width: 230px;
    }
}

.wrapper_delivery_addr {
    @include fs-std-thin-text;
    margin-left: 20px;
    margin-bottom: 30px;

    .conveyor_name,
    .addr_title {
        font-weight: $medium;
    }
}

.wrap_total_commande {
    background: $tertiaryColor;
    padding: 16px 20px 30px;

    .titre {
        @include fs-std-bold-title;
        text-align: center;
        margin-bottom: 19px;
    }

    .clearfix {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 8px;

        @include fs-std-thin-text;

        &.total {
            font-weight: $regular;
            text-transform: uppercase;
            margin-top: 19px;
        }

        .shipping_offer {
            text-transform: uppercase;
        }
    }
}

.popup_numero_retour {
    position: fixed;
    top: 0;
    left: 100%;
    display: block;
    height: 100vh;
    width: 400px;
    padding: 30px 25px;
    background-color: $secondaryColor;
    box-sizing: border-box;

    &.actif {
        left: calc(100% - 400px);
    }

    &:has(.return_popup_title) {
        width: 350px;

        &.actif {
            left: calc(100% - 350px);
        }

        .divBtnPrintPdf {
            display: none;
        }

        .recommandation {
            @include fs-std-thin-text;
            text-align: center;
            padding: 0;
            margin: 12px 0 40px;
        }

        .print-return {
            gap: 15px;
        }
    }

    .recommandation {
        @include fs-std-thin-text;
        text-align: center;
        padding: 0 25px;
        margin: 12px 0 40px;
    }

    .return_products_form {
        width: calc(100% + 17px);
        /* to compensate the 17px padding right for cross-browser compatibility */
        height: 100%;
        max-height: calc(100vh - 250px);
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 17px;
        /* Increase/decrease this value for cross-browser compatibility */
        box-sizing: content-box;
        /* So the width will be 100% + 17px */
        scrollbar-color: $primaryColor $greyBorder;
        scrollbar-width: thin;
    }

    .btn_printable_return_coupon,
    .btn_return_coupon {
        @include fs-std-medium-text;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 38px;
        background-color: $tertiaryColor;
        margin-top: 3px;
        border: 1px solid $tertiaryColor;
        cursor: pointer;

        &:hover {
            background-color: $secondaryColor;
            border-color: $primaryColor;
            color: $primaryColor;
        }

        span {
            margin-top: -2px;
        }
    }
}

.return_popup_title,
.popup_retour_title {
    @include fs-nrml-bold-title;
    text-align: center;
}

.print-return {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#comment_retourner_cmd {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
        display: block;
    }
}

#createNumRetour {
    @include fs-std-thin-text;

    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 15px 0;

    .intro {
        &.rms_return {
            font-weight: 400;
        }
    }

    .links_comment_retourner_cmd {
        @include fs-sml-thin-text;
        text-decoration: underline;
        margin-bottom: 15px;
    }

    span {
        margin-top: 15px;
    }

    .btn_container {
        a {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                @include secondaryButton(center, 3);
            }
        }

        span {
            position: relative;
            width: fit-content;
        }
    }
}

#liste_motif_retour,
#motif_retour_etape {
    font-weight: $medium;
}

#printPdf {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.selectWrapper,
.productReturnListWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.divBtnPrintPdf {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    width: 350px;

    .button {
        width: 100%;
    }

    .loader {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: -1;
    }

    &.disabled {
        opacity: 0.2;
        pointer-events: none;
    }
}

.down_bill {
    margin-top: 15px;
}

.see_remb,
.down_bill {
    display: flex;
    justify-content: center;

    span {
        @include fs-std-medium-text;
        position: relative;

        @include secondaryButton(center, 3);
    }
}

.return_products_list {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 20px;

    .item_container {
        width: 100%;

        &:has(.not_eligible_return) {
            opacity: 0.5;
        }
    }

    .not_eligible_return {
        @include fs-std-thin-text;
        margin-bottom: 10px;
    }

    .imgWrapper {
        width: 99px;
        height: 132px;
    }

    .formWrapper {
        width: 235px;
    }

    .return_quantity {
        width: 38px;
    }

    .date_select.motif {
        width: 300px;
    }

    .return_choice {
        width: 100%;
    }
}

/****** NEWSLETTER PREFERENCES ******/

.w-newspreferences-form {
    margin-bottom: 60px;

    button.w-submit-button,
    .w-form-line.w-submit {
        width: 148px;
    }

    .w-checkbox-input .w-input-element {
        +.w-input-label {
            align-items: center;

            &:before {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 26px;
                height: 15px;
                border: 0;
                border-radius: 72px;
                box-shadow: none;
                background-color: $greyLabel;
            }

            &:after {
                content: '';
                position: absolute;
                width: 13px;
                height: 13px;
                border-radius: 50%;
                background-color: $secondaryColor;
                top: 50%;
                transform: translateY(-50%);
                left: 1px;
                transition: left 0.3s;
            }
        }

        &:checked+.w-input-label {
            &:before {
                background-color: $primaryColor;
                box-shadow: unset;
            }

            &:after {
                left: 12px;
            }
        }
    }

    .w-checkbox-input .w-input-element + .w-input-label span.multiline {
        margin-left: 50px;
    }

    .w-checkbox-input .w-input-element + .w-input-label,
    .w-radio-input .w-input-element + .w-input-label {
        @include fs-std-thin-title;

        strong {
            font-weight: $bold;
        }
    }
}

.w-form-line.optin-select-line {
    margin-bottom: 25px;

    .w-input.w-checkbox-input {
        height: fit-content;
    }
}

.newsletter .wrapper_form {
    position: relative;
}

.customer_infos {
    position: relative;

    .errormsgadd {
        display: none;
    }
}

.update_success {
    position: absolute;
    top: 105%;
    left: 0;
}

/*********** ALERTE STOCK **********/

.wrapper_content_stock_alert .wrapper_form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    min-height: 200px;
    padding-bottom: 50px;

    .cart_product {
        display: flex;
        flex-direction: column;

        .sub_taille {
            order: 1;
        }
    }

    .cart_detail_box {
        max-width: 205px;
    }

    .product-link {
        width: 305px;
    }
}

/********** WISHLIST **********/

.wish_liste_product {
    display: flex;
    flex-wrap: wrap;
    gap: 20px fs-vw(70, 1920);
    min-height: 327px;
    padding-bottom: 60px;
}

.product_ctn {
    position: relative;
    width: 100%;
    max-width: 380px;
    margin-bottom: 50px;

    .remove {
        top: 0;
        right: 0;
    }

    form {
        .btnAddBasketWrapper.expressAlertStock {
            background-color: rgba(240,238,233,.95);
            display: none;
            width: 100%;
            padding: 20px 0 15px;
            margin-top: 3px;

            .alert_stock {
                font-family: $primaryFont;
                font-size: 16px;
                font-weight: 700;
                line-height: normal;
                color: $primaryColor;
                text-align: center;
                margin-bottom: 12px;
            }

            .bloc_add_alert_form {
                .w-form-line {
                    width: 95%;
                    max-width: 350px;
                    margin: 0 auto;
                }
            }

            .bloc_add_alert_erreur {
                display: none;
            }

            .alert_return {
                position: absolute;
                right: 10px;
                top: .6rem;
                cursor: pointer;
                width: 11px;
                height: 11px;
                -webkit-mask-image: url(../svg/spritesheet.svg);
                mask-image: url(../svg/spritesheet.svg);
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-size: 600% auto;
                mask-size: 600% auto;
                -webkit-mask-position: 100% 60%;
                mask-position: 100% 60%;
                background-color: $primaryColor;

                span {
                    display: none;
                }
            }
        }

        .bloc_add_alert_form {
            .form_submit {
                position: absolute;
                right: 0;

                button {
                    font-family: $primaryFont;
                    font-size: 15px;
                    font-weight: 500;
                    line-height: normal;
                    color: #3d4557;
                    background: unset;
                    border: none;
                    padding: 0;
                    cursor: pointer;
                }
            }
        }

        .warn_availability {
            @include fs-std-medium-text;
            display: flex !important;
            justify-content: center;
            align-items: center;
            width: 100%;
            background-color: $tertiaryColor;
            margin-top: 3px;
            border: 1px solid $tertiaryColor;
            cursor: pointer;
            transition: background-color .3s, border-color .3s;
            z-index: unset;

            &:hover {
                background-color: $secondaryColor;
                border-color: $primaryColor;
                color: $primaryColor;
            }
        }

        .warn_availability.none-important {
            display: none !important;
        }

        .bloc_add_alert_confirmation {
            font-family: $primaryFont;
            font-size: 12px;
            font-weight: 100;
            line-height: normal;
            color: $primaryColor;
            text-align: center;
            margin: 0 10px;
        }

        .noSizeAvailable {
            display: flex !important;
            @include fs-std-medium-text;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 38px;
            background-color: $tertiaryColor;
            margin-top: 3px;
            border: 1px solid $tertiaryColor;
            cursor: not-allowed;
            transition: background-color .3s, border-color .3s;

            .alert_return {
                display: none;
            }
        }
    }

    .wrapper_product {
        .product_desc {
            .wrap_txt {
                .title {
                    width: 90%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}

.btnAddBasketWrapper {
    position: relative;
}

.wishToBasket {
    @include fs-std-medium-text;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 38px;
    color: $white;
    background-color: $primaryColor;
    margin-top: 3px;
    border: 1px solid $primaryColor;
    cursor: pointer;
    transition: background-color .3s, border-color .3s;
    position: absolute;

    &:hover {
        background-color: $secondaryColor;
        border-color: $primaryColor;
        color: $primaryColor;
    }

    span {
        margin-top: -2px;
    }

    & + .loader {
        position: absolute;
        width: 100%;
        height: 41px;
        border: 1px solid #3d4557;
        z-index: -1;
        opacity: 0;

        span {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            font-size: 0;

            &::after {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background: $primaryColor ;
                mask-image: url(../svg/three_dots.svg);
                mask-repeat: no-repeat;
                mask-position: center;
                mask-size: 25%;
                border: 1px solid $primaryColor;
            }
        }

        &.loading {
            background: #fff;
            z-index: 2;
            opacity: 1;
        }
    }
}

.wishlist .empty_cart,
.wishlist_empty_note {
    @include fs-std-thin-text;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 0 50px;
}