/*** BUTTONS GLOBAL STYLE ***/

.w-contact-form .file-input-row label:not(.w-loader):focus,
.w-contact-form .file-input-row label:not(.w-loader):hover,
.w-submit-button:not(.w-loader):focus,
.w-submit-button:not(.w-loader):hover,
button.primary:not(.w-loader):focus,
.btnAddBasket:not(.w-loader):focus,
.button.primary:not(.loader):hover,
.button.primary:not(.loader):focus,
button.primary:not(.w-loader):hover,
button.w-submit-button:not(.w-loader):focus,
button.w-submit-button:not(.w-loader):hover {
    background-color: $secondaryColor;
    color: $primaryColor;
}

.divBtnPrintPdf .button,
.w-contact-form .file-input-row label,
.w-submit-button,
button.primary,
.btnAddBasket,
.button.to_tunnel,
.add_new_address,
#btn_resa_shop,
#btn_cmd_valid,
.button.primary,
button.w-submit-button,
.content_e-resaBox .store button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 217px;
    height: 40px;
    background-color: $primaryColor;
    border: 1px solid $primaryColor;
    font-family: $primaryFont;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    line-height: 1;
    color: $secondaryColor;
    box-sizing: border-box;
    cursor: pointer;
    transition: background-color .3s;

    &:hover {
        color: $primaryColor;
        background-color: $secondaryColor;
        border-color: $primaryColor;
    }

    span {
        display: inline-block;
        width: fit-content;
        text-transform: lowercase;
        margin-top: -3px;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &:has(+ button.loading) {
        display: none;
    }

    &.w-loader,
    +.loader,
    .cartLoader {
        background: unset;

        span {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            font-size: 0;

            &::after {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background: $primaryColor ;
                mask-image: url(../svg/three_dots.svg);
                mask-repeat: no-repeat;
                mask-position: center;
                mask-size: 25%;
                border: 1px solid $primaryColor;
            }
        }
    }

    .cache {
        display: none;
    }
}

.button.secondary {
    font-family: $primaryFont;
    background: unset;
    border: none;
    padding: 0;
    cursor: pointer;

    &+.loader {
        display: none;
        font-size: 0;
    }
}