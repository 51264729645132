.title_rayon {
    display: flex;
    justify-content: center;
    // width: 100%;
    margin: 0 auto 30px;
    @include fs-big-bold-title;
}

/***** FILTRES STYLE *****/

.menu_filter_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    max-width: 705px;
    width: 100%;
    margin: 0 auto 50px;
}

.menu_filter_name {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    background: $greyBorder;
    border: 1px solid $greyBorder;
    transition: border-color 0.3s;
    white-space: nowrap;
    cursor: pointer;

    &.current,
    &:hover {
        border-color: $primaryColor;
    }

    a {
        width: 100%;
        height: 100%;
        padding: 4px 9px;

    }

    .item {
        display: flex
    }

    .label_name {
        @include fs-std-light-subtitle;
    }
}

#rayon_sticky_block {
    position: relative;
    width: 64px;
    margin: -34px 13px 5px auto;
    z-index: 5;

    @media screen and(min-width: 1921px) {
        margin: -34px 13px 5px calc(100vw - (100vw - 1765px)/ 2);
    }

    &:has(.opened) {
        z-index: 7;

        #trigger_filtre {
            opacity: 0;
        }
    }

    &.sticky,
    &:has(.opened) {
        position: fixed;
        top: 150px;
        right: calc((100vw - 1920px) / 2);
    }
}

#filter_sticky {
    position: fixed;
    top: 0;
    left: calc(100% + 15px);
    height: 100vh;
    width: 400px;
    background: $secondaryColor;
    padding: 27px 25px;
    box-sizing: border-box;
    transition: left 0.3s linear;

    @media screen and(min-width: 1921px) {
        width: calc(385px + ((100vw - 1920px) / 2));
    }

    #nbrFilteredProd,
    #selectedSortFilter,
    #selectedSortFilter+#pic {
        display: none;
    }
}

#trigger_filtre {
    .filtre_elem {
        display: flex;
        background: $primaryColor;
        color: $secondaryColor;
        @include fs-std-regular-subtitle;
        text-transform: uppercase;
        padding: 5px 8px;
        cursor: pointer;

        &::before,
        &#couleur {
            display: none;
        }
    }
}

.filter_container_scroll {
    width: 100%;
    max-width: 385px;
    height: 100%;
}

.filter_container {
    width: calc(100% + 17px);
    /* to compensate the 17px padding right for cross-browser compatibility */
    height: calc(100vh - 185px);
    overflow-y: scroll;
    padding-right: 17px;
    /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box;
    /* So the width will be 100% + 17px */
}

.filter_title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 385px;
    height: 22px;
    @include fs-nrml-bold-title;

    .close_pop {
        position: absolute;
        top: 0;
        right: 0;
        width: 11px;
        height: 11px;
        cursor: pointer;

        &::before {
            width: 11px;
            height: 11px;
        }
    }
}

#choix_recherche.opened #filter_sticky {
    left: calc(100% - 400px);

    @media screen and(min-width: 1921px) {
        left: calc(100% - calc(385px + (100vw - 1920px) / 2));
    }
}

.filter_wrapper {
    position: relative;
    padding: 22px 0;
    border-bottom: 1px solid $primaryColor;

    &::before {
        content: "";
        position: absolute;
        top: 25px;
        right: 0;
        background: $primaryColor;
        width: 12px;
        height: 12px;
        @include maskIconPath('../svg/spritesheet.svg');
        @include maskIconCoord(1, $initialColSize, $initialRowSize);
        z-index: -1;
    }

    &.collapsible::before {
        transform: rotate(180deg);
    }

    &:not(.collapsible) {
        .filter_title {
            border-bottom: 1px solid $primaryColor;
        }
    }

    &#filter_price {
        border-bottom: none;
    }
}

.toggleFilter {
    @include fs-std-bold-title;
    cursor: pointer;
}

.filter_wrapper input,
.form_itm.check input {
    display: none;
}

#filter_trier {
    .form_itm {
        display: flex;
        align-items: center;
        gap: 12px;
        padding-top: 20px;
        @include fs-std-thin-text;

        .tri_price {
            position: relative;
            display: flex;
            align-items: center;
            gap: 11px;

            &::before {
                content: "";
                display: block;
                width: 11px;
                height: 11px;
                border: 1px solid $greyBorder;
                background-color: $greyBorder;
            }

            &::after {
                content: "";
                position: absolute;
                left: 0;
                display: block;
                width: 11px;
                height: 11px;
                border: 1px solid $primaryColor;
                background-color: transparent;
            }
        }

        &:has(:checked),
        &:has(.actif) {
            @include fs-std-regular-text;

            .tri_price::before {

                background-color: $primaryColor;
                box-shadow: inset 0 0 0 1px $white;
            }
        }
    }
}

#filter_sizes {
    .selected_options {
        display: flex;
        flex-wrap: wrap;
        gap: 20px 5px;
        padding: 8px 0;

        label {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 28px;
            height: 28px;
            border: 1px solid $secondaryColor;
            background: $secondaryColor;
            @include fs-std-thin-text;
            padding-bottom: 3px;
            box-sizing: border-box;
            transition: border-color 0.3s, background-color 0.3s;
            cursor: pointer;

            &:first-letter {
                text-transform: uppercase;
            }

            &:hover {
                border-color: $primaryColor;
            }
        }
    }

    .form_itm {
        input {
            display: none;
        }

        &:has(input:checked) label {
            border-color: $primaryColor;
            background-color: $greyBorder;
        }
    }
}

#filter_color {
    input {
        display: none;
    }

    .color {
        width: fit-content;
        border: 1px solid $secondaryColor;
        border-radius: 50%;
        transition: border-color 0.3s;
        cursor: pointer;

        &:hover,
        &:has(:checked) {
            border-color: $primaryColor;
        }
    }

    label {
        display: block;
        width: 17px;
        height: 17px;
        border: 3px solid $secondaryColor;
        border-radius: 50%;
        cursor: pointer;

        &.white_picto {
            border-color: $greyBorder;
        }

        p {
            font-size: 0;
        }
    }

    .selected_options {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 13px;
    }
}

// Rayon Price range slider (search style in search file)

#filter_price {
    input {
        display: none;
    }

    .wrapper_filtre {
        position: relative;
    }
}

.wrapper_filtre {
    position: relative;
}

.ui-slider {
    position: relative;
    text-align: left;
    z-index: 0;

    .ui-slider-handle {
        z-index: 2;
        position: absolute;
        top: -3px;
        transform: translateX(-3px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        outline: none;
        cursor: pointer;

        &::before {
            content: '';
            display: block;
            width: 7px;
            height: 7px;
            background-color: $primaryColor;
            border-radius: 50%;
        }
    }

    .ui-slider-range {
        z-index: 1;
        position: absolute;
        display: block;
        border: 0;
    }

    .ui-slider-handle {
        .handler_price {
            left: .2em;
        }

        +.ui-slider-handle .handler_price {
            left: auto;
            right: .3em;
        }
    }

    .handler_price {
        position: absolute;
        top: 140%;
        @include fs-std-thin-text;

        &.min {
            left: .2em;
        }

        &.max {
            right: .3em;
        }
    }
}

.ui-slider-horizontal {
    width: calc(100% - 20px);
    height: 2px;
    background-color: $greyBorder;
    margin: 25px auto 30px;

    .ui-slider-range {
        top: 0;
        height: 2px;
        margin: 0;
    }

    .ui-slider-range-min {
        left: 0;
    }
}

.ui-widget-header {
    background-color: $primaryColor;
}

#filter_eclats,
#filter_promo {
    .wrapper_filtre {
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
    }

    input {
        display: none;
    }

    .form_itm {
        display: flex;
        height: 30px;
        width: fit-content;
        border: 1px solid $secondaryColor;
        align-items: center;
        padding: 0 4px;
        cursor: pointer;

        label {
            @include fs-std-light-text;
            margin-top: -2px;
            cursor: pointer;
        }
    }

    .form_itm:has(input:checked) {
        background: $greyBorder;
        border-color: $primaryColor;
    }

    .selected_options {
        margin-top: 17px;
    }
}

.filters_dropdown {

    input#amount1,
    input#amount2 {
        position: absolute;
        top: 140%;
        font-size: 14px;
        letter-spacing: .05em;
        margin: 0;
        padding: 0;
        border: 0;
    }

    input#amount1 {
        left: 0;
        text-align: left;
    }

    input#amount2 {
        right: .3em;
        text-align: right;
    }
}

.filters_cta {
    position: absolute;
    bottom: 30px;
    left: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
    width: calc(100% - 40px);
    max-width: 350px;

    .primary {
        width: 100%;
    }

    .secondary {
        position: relative;
        width: fit-content;
        @include fs-std-medium-text;
        cursor: pointer;

        @include secondaryButton(center, 4);
    }
}

.pagerNav {
    position: relative;

    .loader {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: -1;
    }

}

.loader_scroll {
    position: relative;
    width: 210px;
    height: 40px;

    &.loading {
        .loader {
            opacity: 1;
            z-index: 1;
            background: #fff;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                mask-image: url(../svg/three_dots.svg);
                mask-repeat: no-repeat;
                mask-position: center;
                mask-size: 25%;
                background-color: $primaryColor;
            }
        }
    }

    .button.w-submit-button:not(.w-loader):focus {
        background-color: $primaryColor;
        color: $secondaryColor;
    }
}

.wrap_flitre_pager {
    width: fit-content;
    margin: 50px auto 60px;

    .button.primary:not(.loader):focus,
    .button.primary:not(.w-loader):focus {
        background-color: $primaryColor;
        color: $secondaryColor;
    }
}

.seo_div {
    width: 97.5%;
    max-width: 1114px;
    margin: 0 auto 60px;
    @include fs-std-thin-text;
    text-align: center;

    .txt:not(.cache) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .read_more,
    .read_less {
        position: relative;
        margin-top: 15px;
        font-weight: $medium;
        cursor: pointer;

        @include secondaryButton(center, 4);
    }
}