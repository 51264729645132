.shad {
    z-index: 6;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $shadeColor;
    overflow-y: auto;

    &.shad_menu {
        animation: shade .4s ease-out alternate forwards;
    }

    &.actif {
        display: block;
    }
}

#shad_menu {
    display: none;
    z-index: -1;
    opacity: 0;

    &.actif {
     display: block;
     z-index: 5;
     opacity: 1;
    }
}

#shad_link {
    z-index: 9;
}