// PAGE CGV

.wrapper_content_sat {
    width: 95%;
    max-width: 1295px;
    margin: auto;

    .title .title_container {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $primaryFont;
        font-size: 30px;
        font-weight: 700;
        line-height: 34px;
        color: $primaryColor;
        width: 100%;
        margin-bottom: 40px;
    }

    .content_title h2 {
        font-family: $primaryFont;
        font-size: 16px;
        font-weight: 700;
        color: $primaryColor;
        margin-bottom: 12px;
    }

    .content_inside {
        font-family: $primaryFont;
        font-weight: 100;
        line-height: normal;
        color: $primaryColor;
        margin-bottom: 30px;
    }
}

// FIN PAGE CGV

// PAGE PLAN DU SITE

.footer_sitemap {
    .page_satellite {
        max-width: 1280px;
        width: 95%;
        display: flex;
        flex-direction: column;
        margin: auto;

        .sitemap_title {
            color: $primaryColor;
            font-family: $primaryFont;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 25px;
            text-align: center;
            margin-bottom: 40px;
        }

        .sitemap_section {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 40px;

            h3 {
                display: block;
                width: 100%;
                margin-bottom: 20px;
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }

            h4 {
                color: $primaryColor;
                font-family: $primaryFont;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: 1;
                text-transform: uppercase;
                margin-bottom: 20px;
            }

            .site_map_ul {
                display: flex;
                flex-direction: column;

                a.plan_titre_objet {
                    text-decoration: none;

                    span {
                        color: $primaryColor;
                        font-family: $primaryFont;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 100;
                        line-height: 25px;
                    }
                }
            }
        }
    }
}

// END PAGE PLAN DU SITE

// PAGE ERESA

.satellite_ereservation {
    .main_wrapper {
        .eresvation {
            .satellite_content {
                margin: 0 auto;
                display: flex;
                justify-content: center;

                .column:first-child {
                    margin-right: 3%;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .column:nth-of-type(2) {
                    max-width: 671px;
                }

                .column {
                    align-self: center;

                    h1 {
                        font-family: $primaryFont;
                        color: $primaryColor;
                        font-size: 1.75rem;
                        letter-spacing: 0.5px;
                        font-weight: 100;
                        margin-bottom: 10px;

                        .subtitle {
                            font-family: $primaryFont;
                            color: $primaryColor;
                            font-size: 1.75rem;
                            font-weight: 500;
                            padding-top: 0.5rem;
                            padding-bottom: 0.75rem;
                            display: block;
                        }
                    }

                    #ereserve_sub_texte {
                        font-family: $primaryFont;
                        color: $primaryColor;
                        font-size: 15px;
                        font-weight: 400;
                    }

                    h2 {
                        font-family: $primaryFont;
                        color: $primaryColor;
                        font-size: 1.25rem;
                        font-weight: 100;
                        margin-bottom: 10px;
                    }

                    h4#ereserve_sub_texte {
                        padding-bottom: 2.5rem;
                        font-size: 0.95rem;
                    }

                    article {
                        display: flex;
                        padding: 1.25rem 0;

                        &#article1,
                        &#article2,
                        &#article3,
                        &#article4 {
                            display: flex;
                            flex-direction: column;
                            border-bottom: 1px solid $primaryColorSlime;
                        }

                        div {
                            span {
                                font-family: $primaryFont;
                                color: $primaryColor;
                                font-size: 2.75rem;
                                font-weight: 300;
                                margin-right: 1.25rem;
                            }
                        }

                        .article {
                            h2 {
                                font-size: 0.95rem;
                                font-weight: 400;
                                color: $primaryColor;
                            }

                            p {
                                font-weight: 100;
                                font-size: 0.8rem;
                                padding: 0.5rem 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

// END PAGE ERESA

// PAGE COOKIES

.misc_cookiesinfo {
    #cookiesLightbox {
        &.displayCookies {
            display: block!important;
        }

        &.hideCookies {
            display: none!important;
        }
    }
}

// END PAGE COOKIES