/******** IF NEEDED FOR REPETITIVE ANIMATION STYLING*********/

@mixin secondaryButton($param, $borderSpacing) { //Must be applied to the last text container of the button with its width fitting the content
    position: relative;
    display: block;
    width: fit-content;
    
    &:hover::after {
        width: 100%;
    }

    &::after {
        transition: width 0.2s ease-in;
        content: "";
        position: absolute;
        bottom: calc(#{$borderSpacing} * -1px);
        display: block;
        width: 0;
        height: 1px;
        background-color: $primaryColor;
    }

    @if $param == left {

        &::after {
            left: 0;
        }

    } @else if $param == right {

        &::after {
            right: 0;
        }

    } @else {

        &::after {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}