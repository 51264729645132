/**
 * Global vars
 */
@import "var/fonts"; // Project font faces
@import "var/colors"; // Project colors
@import "components/product_tooltip"; // Vars for the tooltip "choose a size/color" on FP to unblock add to cart BTN
@import "components/animations"; // animations mixins management to be moved to var and renamed to mixin

/**
 * _app imports
 */
@import "../../../_app/_src/scss/desktop/app";
@import "../../../_app/_src/scss/desktop/components/add_cart_product_tooltip";
@import "../../../_app/_src/scss/desktop/pages/bank/bank_thanks";

/**
 * Project plugins
 */
@import "plugins/swiper-bundle"; // Swiper js
@import "plugins/OverlayScrollbars"; // OverlayScrollbars

/**
 * Main components
 */
@import "components/reset"; // CSS reset
@import "var/sprites"; // Project sprites
@import "var/prices"; // Project prices
@import "components/global"; // Global styles
@import "components/w-forms"; // w-forms _app overrides
@import "components/breadcrumb"; // Breadcrumb
@import "components/header"; // Header
@import "components/footer"; // Footer
@import "components/menu"; // Main menu
@import "components/lightbox"; // Lightbox
@import "components/product_item"; // Product item => TG / Ray / Associations...
@import "components/home_modules"; // Home modules
@import "components/buttons"; // Home modules
@import "components/shade"; // shades management
@import "components/videos"; // videos management

/**
 * Project pages
 */
@import "pages/home"; // Homepage
@import "pages/rayon"; // Rayon
@import "pages/search"; // Search
@import "pages/product"; // Product page
@import "pages/tunnel"; // Tunnel
@import "pages/checkout"; // Checkout
@import "pages/login"; // Login
@import "pages/account"; // Account
@import "pages/store_locator"; // Store locator
@import "pages/text"; // CGV - Legal mentions etc...
@import "pages/cms"; // cms
@import "pages/faq"; // faq page
@import "pages/satellite"; // satellite page
@import "pages/contact"; // contact page