/**
MAIN SPRITESHEET USING A SINGLE CLASS
exemple :
<div class="bgSprite bfr search"></div> will display the search icon in a :before content
 */

$initialRowSize : 6;
$initialColSize : 6;

.bgSprite {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &.bfr {
        &::before {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
        }
    }

    &.aft {
        &::after {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
        }
    }

    @include spriter('../svg/spritesheet.svg', $initialColSize, $initialRowSize, (1: 'arrow',
            2: 'search',
            3: 'searchHover',
            4: 'storeloc',
            5: 'storelocHover',
            6: 'storelocFill',
            7: 'account_head',
            8: 'accountHover',
            9: 'accountFill',
            10: 'cart',
            11: 'cartHover',
            12: 'cartFill',
            13: 'sav',
            14: 'distributors',
            15: 'return',
            16: 'securedPayment',
            17: 'rsFacebook',
            18: 'rsInstagram',
            19: 'rsPinterest',
            20: 'heart',
            21: 'heartFill',
            22: 'phone',
            23: 'localisation',
            24: 'cross',
            25: 'arrowFlag',
            26: 'house',
        ));
}